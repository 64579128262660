import { Container, ContentCard } from './style';

export function Register() {
  return (
    <Container>
      <h1 className='h3 text-gray-800 font-weight-bold text-uppercase' data-testid='dashboard-title'>
        Entradas e Saídas complementares
      </h1>

      <ContentCard>
        <h2 className='h4 text-gray-800 font-weight-bold'>Registrar Entrada</h2>
        <p className='text-gray-600'>Registre uma nova entrada de dinheiro.</p>

        <table className='table table-borderless'>
          <thead>
            <tr>
              <th scope='col'>
                <input type='checkbox' />
              </th>
              <th scope='col'>Descrição</th>
              <th scope='col'>Valor</th>
              <th scope='col'>Data</th>
              <th scope='col'>Tipo</th>
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((_, index) => (
              <tr key={index}>
                <td>
                  <input type='checkbox' />
                </td>
                <td>Salário</td>
                <td>R$ 1.500,00</td>
                <td>10/10/2021</td>
                <td>Entrada</td>
                <td>
                  <button className='btn btn-sm btn-primary mr-3'>
                    <i className='fas fa-edit'></i>
                  </button>

                  <button className='btn btn-sm btn-danger'>
                    <i className='fas fa-trash'></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <nav aria-label='Page navigation example' className='mx-auto'>
          <ul className='pagination'>
            <li className='page-item'>
              <a className='page-link' href='#'>
                <i className='fas fa-angle-left'></i>
              </a>
            </li>
            <li className='page-item'>
              <a className='page-link' href='#'>
                1
              </a>
            </li>
            <li className='page-item'>
              <a className='page-link' href='#'>
                2
              </a>
            </li>
            <li className='page-item'>
              <a className='page-link' href='#'>
                3
              </a>
            </li>
            <li className='page-item'>
              <a className='page-link' href='#'>
                <i className='fas fa-angle-right'></i>
              </a>
            </li>
          </ul>
        </nav>
      </ContentCard>
    </Container>
  );
}
