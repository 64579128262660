import { Tooltip } from 'react-tooltip';
import { Container, RoundedCorners } from './styles';
import { IconsCatalog } from '../IconsCatalog';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

type ClothingIconViewerType = {
  icon: string;
  label?: string;
  quantity?: number;
  labelAsTooltip?: boolean;
  marginLeft?: boolean;
  useInlineIcon?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
};

export default function ClothingIconViewer({
  icon,
  label = '',
  quantity,
  labelAsTooltip = false,
  marginLeft = false,
  useInlineIcon = false,
  disabled = false,
  handleClick
}: ClothingIconViewerType) {
  const tooltipIconViewerUUID: string = useMemo(() => `tooltip-model-name-${uuidv4()}`, []);

  return (
    <React.Fragment>
      <Tooltip id={tooltipIconViewerUUID} opacity={1}>
        {label}
      </Tooltip>

      <Container className={marginLeft ? 'ml-2' : ''} useInlineIcon={useInlineIcon}
        data-tooltip-id={tooltipIconViewerUUID}
        data-tooltip-delay-hide={250}
        data-tooltip-variant='dark'>

        <RoundedCorners
          disabled={disabled}
          onClick={!disabled ? handleClick : () => { }} renderAsButton={!!handleClick}
        >

          {icon ? <img src={icon} width={25} height={25} /> : <span className={`${IconsCatalog.image} fa-sm`} />}
          {quantity && <span className='ml-1'>{quantity}</span>}
        </RoundedCorners>

        {label && !labelAsTooltip && <span className='ml-2'>{label}</span>}
      </Container>
    </React.Fragment>
  );
}
