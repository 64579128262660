import { ThemeProvider } from './ThemeContext';
import { ClothingIconsProvider } from './ClothingIconsContext';
import { ClothingModelsProvider } from './ClothingModelsContext';
import { AuthProvider } from './AuthContext';
import { HttpRequestProvider } from './HttpRequestContext';
import { ClothingPresetProvider } from './ClothingPresetContext';
import { ProjectsProvider } from './ProjectsContext';
import { SettingsProvider } from './SettingsContext';
import { HeaderBackButtonProvider } from './HeaderBackButtonContext';
import { UserProvider } from './UserContext';
import { TranslationProvider } from './TranslationContext';
import { SidebarCollapseProvider } from './SidebarCollapseContext';
import { ProjectsDeliveryCalendarProvider } from './ProjectsDeliveryCalendar';
import { ClothingFinishingOptionsProvider } from './ClothingFinishingOptionsContext';
import { FooterProvider } from './FooterContext';

type ContextProvidersProps = {
  children: JSX.Element;
};

export default function ContextProviders({ children }: ContextProvidersProps) {
  return (
    <TranslationProvider>
      <HttpRequestProvider>
        <UserProvider>
          <AuthProvider>
            <ThemeProvider>
              <SidebarCollapseProvider>
                <HeaderBackButtonProvider>
                  <ClothingModelsProvider>
                    <ClothingIconsProvider>
                      <ClothingPresetProvider>
                        <ProjectsDeliveryCalendarProvider>
                          <ClothingFinishingOptionsProvider>
                            <ProjectsProvider>
                              <SettingsProvider>
                                <FooterProvider>{children}</FooterProvider>
                              </SettingsProvider>
                            </ProjectsProvider>
                          </ClothingFinishingOptionsProvider>
                        </ProjectsDeliveryCalendarProvider>
                      </ClothingPresetProvider>
                    </ClothingIconsProvider>
                  </ClothingModelsProvider>
                </HeaderBackButtonProvider>
              </SidebarCollapseProvider>
            </ThemeProvider>
          </AuthProvider>
        </UserProvider>
      </HttpRequestProvider>
    </TranslationProvider>
  );
}
