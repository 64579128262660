import React, { useCallback, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import ImagePickerBase64 from '../../ImagePickerBase64';
import { toast } from 'react-hot-toast-promise';
import { type SublistType } from '../../../types/SublistTypes';
import { useProjects } from '../../../contexts/ProjectsContext';
import { type SublistLayoutType } from '../../../types/SublistLayoutType';
import SplitButton from '../../Buttons/SplitButton';
import { IconsCatalog } from '../../IconsCatalog';
import SpinnerWithMessage from '../../SpinnerWithMessage';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';

type SublistLayoutModalType = Pick<ModalBaseType, 'handleClose'> & {
  sublist: SublistType;
  onLayoutCreated: (createdLayout: SublistLayoutType) => void;
  onLayoutRemoved: () => void;
};

export default function SublistLayoutModal({
  sublist,
  handleClose,
  onLayoutCreated,
  onLayoutRemoved
}: SublistLayoutModalType) {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [croppedImage, setCroppedImage] = useState('');

  const { addLayoutToSublist, removeLayoutFromSublist } = useProjects();
  const { Translate } = useAppTranslation();

  const handleConfirm = useCallback(async () => {
    try {
      if (sublist.id === undefined) return;
      setIsUploading(true);

      const createdLayout = await addLayoutToSublist(sublist.id, croppedImage);
      onLayoutCreated(createdLayout);
      handleClose();
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIsUploading(false);
    }
  }, [croppedImage]);

  const handleDeleteLayout = useCallback(async () => {
    try {
      if (!sublist.layout || !sublist.id) return;
      setIsDeleting(true);
      await removeLayoutFromSublist(sublist.id);
      onLayoutRemoved();
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIsDeleting(false);
    }
  }, []);

  if (!sublist) return <></>;

  return (
    <ModalBase
      isLarge
      disableClickOutsideToClose
      visible={!!sublist}
      style='primary'
      title={`Layout - ${sublist.title}`}
      message={
        !sublist.layout
          ? Translate('description.layout-empty-description')
          : Translate('description.layout-filled-description')
      }
      confirmationButtonDisabled={!croppedImage}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      hideFooter={isUploading}
    >
      <>
        {!!sublist.layout && (
          <div className='row'>
            <div className='col'>
              <img src={sublist.layout.file} className='img-fluid' />
            </div>

            <div className='col col-auto d-flex justify-content-center align-items-center'>
              {!isDeleting && (
                <SplitButton
                  color='danger'
                  icon={IconsCatalog.trash}
                  title={Translate('actions.remove')}
                  handleClick={handleDeleteLayout}
                />
              )}

              {isDeleting && <SpinnerWithMessage message={Translate('progress.removing')} />}
            </div>
          </div>
        )}

        {!isUploading && !sublist.layout && (
          <ImagePickerBase64
            resizeSettings={{ height: 600 }}
            maxImageHeightInEditor={400}
            label={Translate('description.layout-cropper-description')}
            onSelect={base64Image => {
              setCroppedImage(base64Image);
            }}
          />
        )}

        {isUploading && <SpinnerWithMessage message={Translate('progress.sending')} />}
      </>
    </ModalBase>
  );
}
