import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast-promise';
import BasicCard from '../../../../components/Cards/BasicCard';
import SublistList, { type SublistChangedType } from './components/SublistList';
import SublistForm from './components/SublistForm';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../components/ContextMenuViewer';
import { IconsCatalog } from '../../../../components/IconsCatalog';
import { ContextMenuProvider } from '../../../../contexts/ContextMenuContext';
import { generateInitialOrder } from './helper/orderHelper';
import { createSublist, deleteSublist, editSublist, fixSublistsWithMissingClothesInOrders, handleUpdateSublistsTokens } from '../services/sublistService';
import { type Order, type SortableOrderField } from '../../../../types/OrderTypes';
import { type SublistType } from '../../../../types/SublistTypes';

import { generateClothingProductionDataFromModels } from './services/orderFormService';

import { runSortOrder } from './services/orderSortingService';

import { useBlocker, useLocation, useNavigate } from 'react-router';
import { useClothingModels } from '../../../../contexts/ClothingModelsContext';
import { useClothingPreset } from '../../../../contexts/ClothingPresetContext';
import { useProjects } from '../../../../contexts/ProjectsContext';
import { areObjectsEquals } from './services/objectComparation';
import { useSettings } from '../../../../contexts/SettingsContext';
import { useHeaderBackButton } from '../../../../contexts/HeaderBackButtonContext';

import Spinner from '../../../../components/Spinner';
import TextInputModal from '../../../../components/Modals/TextInputModal';
import ProjectHeaderInfo from './components/ProjectHeaderInfo';
import Tabs from '../../../../components/Tabs';
import MainFormEditor from './modules/MainFormEditor';
import PastedListEditor from './modules/PastedListEditor';
import FastCreationEditor from './modules/FastCreationEditor';
import ScreenshotSettingsModal from '../../../../components/Modals/ScreenshotSettingsModal';

import type ClothingPresetType from '../../../../types/ClothingPresetType';
import { type ClothingModelType } from '../../../../types/ClothingModelType';
import { type ProjectType } from '../../../../types/ProjectType';
import { type ProjectUpdateType } from '../../../../types/ProjectUpdateType';
import { type SortByClothingSizeParams } from '../../../../types/SortByClothingSizeParams';
import { takeScreenshot } from '../services/screenshotService';
import { downloadBase64Image } from '../../../../components/ImagePickerBase64/services/imageManipulation';
import { useUser } from '../../../../contexts/UserContext';
import { useTheme } from '../../../../contexts/ThemeContext';
import SequentialList from './modules/SequentialList';
import DropdownButton from '../../../../components/Buttons/DropdownButton';
import { type ProjectReportType } from '../../../../types/ProjectReportType';
import { generateProjectReport } from './services/projectReportService';
import TableOrderManager from './components/TableOrderManager';
import ProjectSharedLinkControlPanel from '../../../../components/Modals/ProjectSharedLinkControlPanel';
import { isProjectAuthor } from '../../../../contexts/services/userService';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import { type SublistCollapseControlType } from '../../../../types/SublistCollapseControlType';
import ConfirmationTwoOptionsModal from '../../../../components/Modals/ConfirmationTwoOptionsModal';
import { useSearchParams } from 'react-router-dom';
import PaymentRegistriesView from './components/PaymentReceipts';
import { type ProjectPaymentRegistryType } from '../../../../types/ProjectPaymentRegistry';
import { updateCachedPaymentRegistries } from './components/PaymentReceipts/services/paymentRegistryService';
import SyncStatusBar from '../../../../components/SyncStatusBar';
import { updateCachedProjectWithSharedLinkOptions } from './services/projectService';
import SplitButton from '../../../../components/Buttons/SplitButton';
import { generateSublistlinksBatch } from './api/generateSublistLinksBatch';
import { useHttpRequest } from '../../../../contexts/HttpRequestContext';
import TextAreaInputModal from '../../../../components/Modals/TextAreaInputModal';
import { generateContentToCopyToClipboard } from './services/sublistBatchLinkService';
import { copyToClipboard, getServerErrorMessageFromResponse } from '../../../../utils/helper';
import { SublistExpandableContainer, SublistsFooterCompactToggleButton } from './styles';
import MainFormEditorModal from './components/MainFormEditorModal';

export default function ViewProject() {
  const sublistsContainerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();
  const { user } = useUser();
  const { getPresetById } = useClothingPreset();
  const { getModelsById } = useClothingModels();
  const { projects, setProjects, getProjectData, updateProject, downloadProjectAsZip } = useProjects();
  const { preferences, backendResourcesReady } = useSettings();
  const { showBackButton, hideBackButton } = useHeaderBackButton();
  const { adaptToScreenshot, removeScreenshotAdaptations } = useTheme();

  const [project, setProject] = useState<ProjectType | null>(null);
  const [currentPreset, setCurrentPreset] = useState<ClothingPresetType>({} as ClothingPresetType);
  const [sublists, setSublists] = useState<SublistType[]>([]);
  const [sublistCollapseControl, setSublistCollapseControl] = useState<SublistCollapseControlType[]>([]);
  const [sublistBatchLinksClipboard, setSublistBatchLinksClipboard] = useState('');

  const [isPendingAutoSync, setIsPendingAutoSync] = useState(false);
  const [isSynched, setIsSynched] = useState(true);
  const [lastSynchedContent, setLastSynchedContent] = useState<ProjectType | null>(null);
  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);

  const blocker = useBlocker(!isSynched);

  const [order, setOrder] = useState<Order>(generateInitialOrder());

  const [importedModels, setImportedModels] = useState<ClothingModelType[]>([]);

  const [screenshotMode, setScreenshotMode] = useState(false);
  const [screenshotModalOpen, setScreenshotModalOpen] = useState(false);
  const [showScreenshotCollapseSublistModal, setShowScreenshotCollapseSublistModal] = useState(false);

  const [showEditProjectNameModal, setShowEditProjectNameModal] = useState(false);
  const [sharedLinkControlPanelModalOpen, setSharedLinkControlPanelModalOpen] = useState(false);

  const [showPaymentRegistriesModal, setShowPaymentRegistriesModal] = useState(false);

  const [targetSublistIndex, setTargetSublistIndex] = useState(0);

  const [sublistToEdit, setSublistToEdit] = useState<SublistType | null>(null);
  const [sublistToDelete, setSublistToDelete] = useState<SublistType | null>(null);

  const [projectReport, setProjectReport] = useState<ProjectReportType>({} as ProjectReportType);

  const [isSublistCompactView, setIsSublistCompactView] = useState(false);

  const handleCreateSublist = useCallback(
    (name: string) => {
      if (!name) return;
      const updatedSublists = createSublist(name, sublists);
      setSublists(updatedSublists);
    },
    [sublists]
  );

  const handleEditSublist = useCallback(
    (name: string, id: string) => {
      const updatedSublists = editSublist(name, id, sublists);
      setSublists(updatedSublists);
      setSublistToEdit(null);
    },
    [sublists]
  );

  /**
   * The given sublist will be located in the `sublist` state by ID and will be updated with the given data.
   * When changing layouts, it will be already synched because it send the image to the server and keep it up to date.
   * When changing texts locally, it will not be synched, and should show NotSynchedStatusBar
   */
  const handleChangeSublist = useCallback(
    ({ updatedSublist, isSynched, forceAutoSync }: SublistChangedType) => {
      if (!project) return;

      const updatedSublists = sublists.map(sublist => {
        if (sublist.id === updatedSublist.id) return updatedSublist;
        return sublist;
      });

      setSublists(updatedSublists);

      if (isSynched) {
        setLastSynchedContent({ ...lastSynchedContent!, sublists: updatedSublists });
        setProject({ ...project, sublists: updatedSublists });
        setIsSynched(true);
      }

      setIsPendingAutoSync(forceAutoSync);
    },
    [sublists, lastSynchedContent, project]
  );

  const handleDeleteSublist = useCallback(() => {
    if (sublistToDelete === null) return;
    const updatedSublists = deleteSublist(sublists, sublistToDelete);

    setSublists(updatedSublists);
    setSublistToDelete(null);
  }, [sublists, sublistToDelete]);

  const handleGenerateSublistLinksBatch = useCallback(() => {
    if (!project || !project.id) return;
    toast.promise(generateSublistlinksBatch(project.id, httpConnection), {
      loading: Translate('progress.generating-batch-links'),
      success: (generatedSublistLinks) => {
        const contentToCopyToClipboard = generateContentToCopyToClipboard(generatedSublistLinks);

        copyToClipboard(contentToCopyToClipboard);
        setSublistBatchLinksClipboard(contentToCopyToClipboard);

        const updatedCachedSublistsTokens = handleUpdateSublistsTokens(sublists, generatedSublistLinks);
        setSublists(updatedCachedSublistsTokens);

        return Translate('toast.links-were-copied-to-clipboard');
      },
      error: getServerErrorMessageFromResponse
    });
  }, [Translate, httpConnection, project, sublists]);

  const isEditingOrder = useMemo(() => !!order.id, [order]);

  const contextMenuOptionsForSublists = useMemo(
    (): ContextMenuItemType[] => [
      {
        name: Translate('actions.edit'),
        icon: IconsCatalog.pen,
        handleClick: selectedOption => {
          setSublistToEdit(selectedOption as SublistType);
        }
      },
      {
        name: Translate('actions.delete'),
        icon: IconsCatalog.trash,
        handleClick: selectedOption => {
          if (sublists.length === 1) {
            toast.error(Translate('error.must-keep-one-sublist'));
            return;
          }

          setSublistToDelete(selectedOption as SublistType);
        }
      }
    ],
    [sublists, Translate]
  );

  const handleTakeScreenshot = useCallback(async () => {
    if (sublistsContainerRef.current === null) return;

    adaptToScreenshot();
    setScreenshotMode(true);

    const screenshotProcessor = takeScreenshot({
      targetDivElement: sublistsContainerRef.current,
      delayInMilliseconds: 1500
    });

    await toast.promise(screenshotProcessor, {
      loading: Translate('progress.generating-image'),
      success: screenshotData => {
        downloadBase64Image(screenshotData, `${Translate('labels.orderlist')}.png`);
        return <span>Pronto!</span>;
      },
      error: getServerErrorMessageFromResponse
    });

    removeScreenshotAdaptations();
    setScreenshotMode(false);
  }, [Translate, adaptToScreenshot, removeScreenshotAdaptations]);

  const handleUpdateSublistCollapseControl = useCallback((collapseEvent: SublistCollapseControlType) => {
    const { id, title, collapsed } = collapseEvent;
    setSublistCollapseControl(prevCollapseControl => {
      const item = prevCollapseControl.find(item => item.id === id);

      if (!item) {
        const newItemControl = { id, title, collapsed };
        return [...prevCollapseControl, newItemControl];
      }

      const updatedSublistCollapseControl = prevCollapseControl.map<SublistCollapseControlType>(item => {
        if (item.id === id) return { ...item, collapsed };

        return item;
      });

      return updatedSublistCollapseControl;
    });
  }, []);

  const handleProjectSync = useCallback(async () => {
    try {
      if (project === null) return;

      const projectUpdateData: ProjectUpdateType = {
        id: project.id!,
        name: project.name,
        preset_id: project.preset_id,
        sublists
      };

      const projectUpdateResponse = await updateProject(projectUpdateData);

      setProject(projectUpdateResponse);
      setSublists(projectUpdateResponse.sublists);
      setLastSynchedContent(projectUpdateResponse);
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIsSynched(true);
    }
  }, [project, sublists, updateProject]);

  const runProjectSyncWithToastPromise = useCallback(async (): Promise<void> => {
    await toast.promise(handleProjectSync(), {
      loading: Translate('progress.saving-last-project-changes'),
      success: Translate('toast.project-updated'),
      error: getServerErrorMessageFromResponse
    });
  }, [handleProjectSync, Translate]);

  const handleDownloadProject = useCallback(async () => {
    if (!project) return;

    const syncAndDownload = new Promise<void>((resolve, reject) => {
      const _syncAndDownload = async () => {
        try {
          if (!isSynched) await runProjectSyncWithToastPromise();
          await downloadProjectAsZip(project);
          resolve();
        } catch (err) {
          reject(err);
        }
      };

      _syncAndDownload();
    });

    toast.promise(syncAndDownload, {
      loading: Translate('progress.generating-file'),
      success: Translate('toast.download-completed'),
      error: getServerErrorMessageFromResponse
    });
  }, [project, Translate, isSynched, runProjectSyncWithToastPromise, downloadProjectAsZip]);

  const handleAddPaymentRegistry = useCallback(
    (createdPaymentRegistry: ProjectPaymentRegistryType) => {
      if (!project) return;
      const updatedProjectData: ProjectType = { ...project, payments: [...project.payments, createdPaymentRegistry] };

      setProject(updatedProjectData);
      setLastSynchedContent(updatedProjectData);
      setIsSynched(true);
    },
    [project]
  );

  const handleUpdateCachedPaymentRegistry = useCallback(
    (updatedPayment: ProjectPaymentRegistryType) => {
      if (!project) return;
      const updatedPaymentRegistries = updateCachedPaymentRegistries(project.payments, updatedPayment);
      const updatedProjectContent = { ...project, payments: updatedPaymentRegistries };

      setProject(updatedProjectContent);
      setLastSynchedContent(updatedProjectContent);
      setIsSynched(true);
    },
    [project]
  );

  const loadProjectDependencies = useCallback(
    (project: ProjectType) => {
      try {
        setProject(project);
        const preset = getPresetById(project.preset_id);
        setCurrentPreset(preset);

        const models = getModelsById(preset.imported_clothes_id);
        setImportedModels(models);

        const repairedSublists = fixSublistsWithMissingClothesInOrders(project.sublists, models);

        setSublists(repairedSublists);
        setIsSublistCompactView(repairedSublists.length > 5);
        setLastSynchedContent(project);

        document.title = project.name;

        setOrder({
          ...order,
          clothes: generateClothingProductionDataFromModels(models)
        });

        const projectReport = generateProjectReport(repairedSublists, models, Translate);
        setProjectReport(projectReport);
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
      }
    },
    [getPresetById, getModelsById, order, Translate]
  );

  const countSublistsCollapsed = useMemo((): number => {
    return sublists.reduce((accumulator, currentSublist) => {
      const targetSublist = sublistCollapseControl.find(collapseControl => collapseControl.id === currentSublist.id);

      if (!!targetSublist && targetSublist.collapsed) return accumulator + 1;
      return accumulator;
    }, 0);
  }, [sublists, sublistCollapseControl]);

  const dropdownMenuToolsOptions = useMemo(
    () => [
      {
        name: Translate('actions.generate-report'),
        icon: IconsCatalog.forms,
        handleClick: () => {
          navigate('report', {
            state: { sublists, project, projectReport, importedModels }
          });
        }
      },
      {
        name: Translate('actions.generate-invoice'),
        icon: IconsCatalog.invoiceDollar,
        handleClick: () => {
          window.open(`/order/invoice/${project?.uuid}`);
        }
      },
      {
        name: Translate('labels.screenshot'),
        icon: IconsCatalog.camera,
        handleClick: () => {
          if (countSublistsCollapsed > 0) {
            setShowScreenshotCollapseSublistModal(true);
            return;
          }

          setScreenshotModalOpen(true);
        }
      },
      {
        name: Translate('actions.download-project'),
        icon: IconsCatalog.download,
        handleClick: () => {
          handleDownloadProject();
        }
      }
    ],
    [Translate, navigate, sublists, project, projectReport, importedModels, countSublistsCollapsed, handleDownloadProject]
  );

  useEffect(() => {
    setProject((prevProject: ProjectType | null) => {
      if (prevProject === null) return prevProject;
      return { ...prevProject, sublists };
    });
  }, [sublists]);

  useEffect(() => {
    if (isPendingAutoSync) {
      if (!isSynched) {
        handleProjectSync();
      }
      setIsPendingAutoSync(false);
    }
  }, [sublists, isPendingAutoSync, isSynched, handleProjectSync]);

  /** RECALCULATE REPORT WHEN SUBLISTS CHANGES */
  useEffect(() => {
    if (!projectReport.ready) return;
    const recalculatedProjectReport = generateProjectReport(sublists, importedModels, Translate);
    const reportChanged = !areObjectsEquals(projectReport, recalculatedProjectReport);

    if (!reportChanged) return;
    setProjectReport(recalculatedProjectReport);
  }, [sublists, importedModels, projectReport, Translate]);

  useEffect(() => {
    const equals = areObjectsEquals(project, lastSynchedContent);

    if (equals) {
      setIsSynched(true);
      return;
    }

    setIsSynched(false);
    handleProjectSync();
  }, [project, lastSynchedContent, handleProjectSync]);

  useEffect(() => {
    if (!backendResourcesReady) return;

    try {
      if (location?.state?.isNew) {
        const project: ProjectType = location.state.projectData;
        loadProjectDependencies(project);
        return;
      }

      const existingProjectId = location?.state?.projectId;
      const queryParamProjectId = parseInt(searchParams.get('id') ?? '');

      if (!existingProjectId && !queryParamProjectId) {
        navigate('/orderlist/projects');
        return;
      }

      getProjectData(existingProjectId ?? queryParamProjectId)
        .then(fullProjectData => {
          loadProjectDependencies(fullProjectData);
        })
        .catch(err => {
          toast.error(getServerErrorMessageFromResponse(err));
          navigate(-1);
        });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendResourcesReady, location.state, navigate, getProjectData]);

  useEffect(() => {
    const showPendingChangesModal = blocker.state === 'blocked';
    setShowPendingChangesModal(showPendingChangesModal);
  }, [blocker.state]);

  useEffect(() => {
    showBackButton();

    return () => {
      hideBackButton();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (project === null || user === null || !projectReport.ready) {
    return (
      <div className='d-flex align-items-center'>
        <Spinner />
        <h4 className='m-0 ml-2'>{Translate('progress.download-informations')}</h4>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.confirm-delete-sublist')}
        style='danger'
        visible={sublistToDelete !== null}
        handleClose={() => {
          setSublistToDelete(null);
        }}
        handleConfirm={handleDeleteSublist}
      />

      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.screenshot-with-collapsed-sublists-confirmation')}
        style='primary'
        visible={showScreenshotCollapseSublistModal}
        handleClose={() => {
          setShowScreenshotCollapseSublistModal(false);
        }}
        handleConfirm={() => {
          setScreenshotModalOpen(true);
          setShowScreenshotCollapseSublistModal(false);
        }}
      />

      <ConfirmationTwoOptionsModal
        title={Translate('labels.confirmation')}
        message={Translate('description.projec-has-unsaved-changes')}
        visible={showPendingChangesModal}
        buttonConfirmLabel={Translate('actions.save-and-continue')}
        buttonDenyLabel={Translate('actions.dont-save')}
        handleClose={() => {
          if (blocker.state === 'blocked') blocker.reset();
        }}
        handleConfirm={() => {
          runProjectSyncWithToastPromise();
          if (blocker.state === 'blocked') blocker.proceed();
        }}
        handleDeny={() => {
          if (blocker.state === 'blocked') blocker.proceed();
        }}
      />

      <TextInputModal
        title={Translate('modal.rename-project')}
        message={Translate('description.rename-project')}
        label={`${Translate('labels.project-name')}:`}
        currentValue={project.name}
        style='primary'
        visible={showEditProjectNameModal}
        handleClose={() => {
          setShowEditProjectNameModal(false);
        }}
        handleConfirm={async editedName => {
          setProject({ ...project, name: editedName });
        }}
      />

      <TextAreaInputModal
        visible={sublistBatchLinksClipboard.length > 0}
        currentValue={sublistBatchLinksClipboard}
        handleClose={() => { }}
        handleConfirm={() => {
          setSublistBatchLinksClipboard('');
        }}
        label={Translate('description.content-already-copied-to-clipboard')}
        message={Translate('description.links-below-generated-to-all-sublists')}
        style='primary'
        title={Translate('labels.batch-links')}
        okOnly={true}
      />

      <ScreenshotSettingsModal
        visible={screenshotModalOpen}
        handleConfirm={handleTakeScreenshot}
        handleClose={() => {
          setScreenshotModalOpen(false);
        }}
      />

      <ProjectSharedLinkControlPanel
        project={project}
        visible={sharedLinkControlPanelModalOpen}
        handleClose={() => {
          setSharedLinkControlPanelModalOpen(false);
        }}
        handleConfirm={sharedLinkOptions => {
          const updatedProjectData = { ...project, ...sharedLinkOptions };
          setProject(updatedProjectData);
          setLastSynchedContent(updatedProjectData);

          const updatedCachedProjectsList = updateCachedProjectWithSharedLinkOptions(
            projects,
            project.id!,
            sharedLinkOptions
          );
          setProjects(updatedCachedProjectsList);
        }}
      />

      <PaymentRegistriesView
        projectId={project.id!}
        payments={project.payments}
        preferences={preferences}
        visible={showPaymentRegistriesModal}
        onCreatePaymentRegistry={handleAddPaymentRegistry}
        onUpdatePaymentRegistry={handleUpdateCachedPaymentRegistry}
        handleClose={() => {
          setShowPaymentRegistriesModal(false);
        }}
        handleConfirm={() => {
          setShowPaymentRegistriesModal(false);
        }}
      />

      <MainFormEditorModal
        order={order}
        setOrder={setOrder}
        sublists={sublists}
        setSublists={setSublists}
        importedModels={importedModels}
        targetSublistIndex={targetSublistIndex}
        setTargetSublistIndex={setTargetSublistIndex}
        isCompanyEditor
        visible={isEditingOrder}
      />

      {!project.preset_id && <Spinner alignLeft />}

      {!!project.preset_id && (
        <React.Fragment>
          <ProjectHeaderInfo
            project={project}
            preset={currentPreset.name}
            presetCount={currentPreset.imported_clothes_id.length}
            hideButtons={!isProjectAuthor(project, user)}
            currencySymbol={preferences.currency}
            projectReport={projectReport}
            onClickEditButton={() => {
              setShowEditProjectNameModal(true);
            }}
            onClickSharedLinkButton={() => {
              if (isSynched) setSharedLinkControlPanelModalOpen(true);
              else toast.error(Translate('error.sync-pending-changes'));
            }}
            onClickPaymentReceiptsButton={() => {
              setShowPaymentRegistriesModal(true);
            }}
            toolsDropdownButton={
              <DropdownButton
                id='project-menu'
                label={Translate('labels.tools')}
                size='sm'
                color='success'
                marginLeft
                noMarginBottom
                options={dropdownMenuToolsOptions}
              />
            }
          />

          <div className='row'>
            <div className='col-8' style={{ marginTop: '10px' }}>
              <Tabs
                data={[
                  {
                    id: 'form',
                    label: !isEditingOrder ? Translate('actions.add-item') : Translate('actions.edit-item'),
                    active: true,
                    content: (
                      <MainFormEditor
                        order={order}
                        setOrder={setOrder}
                        sublists={sublists}
                        setSublists={setSublists}
                        importedModels={importedModels}
                        targetSublistIndex={targetSublistIndex}
                        setTargetSublistIndex={setTargetSublistIndex}
                        isCompanyEditor
                      />
                    )
                  },
                  {
                    id: 'paste-content',
                    label: Translate('actions.paste-list'),
                    content: (
                      <PastedListEditor
                        sublists={sublists}
                        setSublists={setSublists}
                        importedModels={importedModels}
                        handleSortByClothingSize={({ clotheIndex, sublist, forceSortingType }: SortByClothingSizeParams) => {
                          const clotheSortingKey = `clothe-${clotheIndex}` as SortableOrderField['field'];
                          const updatedSublists = runSortOrder({
                            field: clotheSortingKey,
                            sublists,
                            sublist,
                            importedModels,
                            forceSortingType,
                            Translate
                          });

                          setSublists(updatedSublists);
                        }}
                      />
                    )
                  },
                  {
                    id: 'multi-sizes',
                    label: Translate('actions.multi-sizes'),
                    content: (
                      <FastCreationEditor sublists={sublists} setSublists={setSublists} importedModels={importedModels} />
                    )
                  },
                  {
                    id: 'sequential-list',
                    label: Translate('actions.sequential-list'),
                    content: <SequentialList sublists={sublists} importedModels={importedModels} setSublists={setSublists} />
                  }
                ]}
              />
            </div>

            <div className='col-4'>
              {project.card ? (
                <BasicCard title='Projeto com card vinculado' useBlueHeader>
                  <button
                    type='button'
                    className='btn btn-warning w-100'
                    onClick={() => {
                      navigate('/kanban/workspaces/view', {
                        state: {
                          workspace: project?.card?.columns?.workspaces,
                          card: project?.card
                        }
                      });
                    }}
                  >
                    {project.card.title}
                  </button>
                </BasicCard>
              ) : null}

              <BasicCard title={Translate('labels.sublists')} useBlueHeader>
                <div className='d-flex justify-content-end'>
                  <SplitButton
                    icon={IconsCatalog.box}
                    color='primary'
                    size='sm'
                    handleClick={handleGenerateSublistLinksBatch}
                    title={Translate('actions.generate-sublist-links-batch')} />
                </div>

                <SublistForm
                  disabled={!isSynched}
                  sublistToEdit={sublistToEdit}
                  handleEditSublist={handleEditSublist}
                  handleCreateSublist={handleCreateSublist}
                  onEditCanceled={() => {
                    setSublistToEdit(null);
                  }}
                />

                <SublistExpandableContainer useCompactView={isSublistCompactView}>
                  <ContextMenuProvider>
                    <ContextMenuViewer options={contextMenuOptionsForSublists} disabled={!!sublistToEdit} />
                    <SublistList items={sublists} onSublistChange={handleChangeSublist} importedModels={importedModels} />
                  </ContextMenuProvider>
                </SublistExpandableContainer>

                {sublists.length > 5 && (
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <SublistsFooterCompactToggleButton className='btn btn-secondary' onClick={() => {
                        setIsSublistCompactView(!isSublistCompactView);
                      }}>
                        <i className={isSublistCompactView ? IconsCatalog.caretDown : IconsCatalog.caretUp} />
                      </SublistsFooterCompactToggleButton>
                    </div>
                  </div>
                )}
              </BasicCard>
            </div>
          </div>

          <div className={`row align-items-center ${!isProjectAuthor(project, user) ? 'mt-3' : ''}`}>
            <div className='col'>
              <SyncStatusBar isSynching={!isSynched} />
            </div>
          </div>

          <TableOrderManager
            isSynching={!isSynched}
            user={user}
            isCompanyEditor
            screenshotMode={screenshotMode}
            setOrder={setOrder}
            importedModels={importedModels}
            isEditingOrder={isEditingOrder}
            sublists={sublists}
            setSublists={setSublists}
            sublistsContainerRef={sublistsContainerRef}
            projectReport={projectReport}
            preferences={preferences}
            onSublistCollapseChange={(collapseEvent: SublistCollapseControlType) => {
              handleUpdateSublistCollapseControl(collapseEvent);
            }}
            card={project?.card}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
