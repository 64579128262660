import React, { useEffect, useRef, useState } from 'react';
import { useSettings } from '../../../contexts/SettingsContext';
import { useLocation, useNavigate } from 'react-router';
import { type SystemInitialLoad } from '../../../types/SystemInitialLoad';
import { useHttpRequest } from '../../../contexts/HttpRequestContext';
import { useClothingIcons } from '../../../contexts/ClothingIconsContext';
import { useClothingModels } from '../../../contexts/ClothingModelsContext';
import { useClothingPreset } from '../../../contexts/ClothingPresetContext';
import { useProjects } from '../../../contexts/ProjectsContext';
import { OfflineError } from '../../../Errors/OfflineError';
import Spinner from '../../../components/Spinner';
import toast from 'react-hot-toast-promise';
import { useUser } from '../../../contexts/UserContext';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { useProjectsDeliveryCalendar } from '../../../contexts/ProjectsDeliveryCalendar';
import { getServerErrorMessageFromResponse, sleep } from '../../../utils/helper';
import { useClothingFinishingOptions } from '../../../contexts/ClothingFinishingOptionsContext';

export default function Loading() {
  const [offline, setOffline] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { Translate } = useAppTranslation();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { httpConnection } = useHttpRequest();
  const { setClothingIcons } = useClothingIcons();
  const { setModels } = useClothingModels();
  const { setPresets } = useClothingPreset();
  const { processProjectsListResponse } = useProjects();
  const { initSettings } = useSettings();
  const { setUser } = useUser();
  const { setUpdatedDeliveryCalendar } = useProjectsDeliveryCalendar();
  const { setFinishingOptions } = useClothingFinishingOptions();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await httpConnection.get<SystemInitialLoad>('list/load-system');
        const { user, icons, models, presets, projects, preferences, dashboard, sublist_finishings } = response.data;

        setUser(user);
        setClothingIcons(icons);
        setModels(models);
        setPresets(presets);
        processProjectsListResponse(projects);
        initSettings(preferences);
        setUpdatedDeliveryCalendar(dashboard);
        setFinishingOptions(sublist_finishings);

        await sleep(1000);
        navigate(location.state?.destinationRoute || '/dashboard');
      } catch (err) {
        if (err instanceof OfflineError) {
          setOffline(true);
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
        }

        toast.error(getServerErrorMessageFromResponse(err));
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      navigate(location.state?.destinationRoute || '/dashboard');
    }, 10000);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [location.state?.destinationRoute, navigate]);

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col d-flex flex-column justify-content-center align-items-center mt-4'>
          {!offline && (
            <React.Fragment>
              <Spinner />
              <h4 className='m-0 mt-3'>{Translate('progress.starting-system')}</h4>
            </React.Fragment>
          )}
        </div>
      </div>

      {offline && (
        <div className='row'>
          <div className='col'>
            <div className='text-center'>
              <div className='error mx-auto' data-text='ops!'>
                ops!
              </div>
              <p className='lead text-gray-800 mb-5'>{Translate('status.disconnected')}</p>
              <p className='text-gray-500 mb-0'>{Translate('description.failed-connect-to-server')}</p>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
