import React, { type ChangeEvent, useCallback, useState, useMemo } from 'react';
import ReactCrop, { type Crop } from 'react-image-crop';
import toast from 'react-hot-toast-promise';

import {
  adjustCropCoordsToScale,
  calculateFinalImageDimensions,
  calculateImageHeightInAspectRatio,
  cropImage,
  getImageDimensions,
  getImageScale,
  resizeImage
} from './services/imageManipulation';
import { type ImageScale } from '../../types/ImageScale';
import { type ImageDimensions } from '../../types/ImageDimensions';
import { useAppTranslation } from '../../contexts/TranslationContext';

export type ResizeParams = {
  width?: number;
  height?: number;
};

type ImagePickerBase64Type = {
  label: string;
  maxImageHeightInEditor: number;
  useSquareAspectRatioCrop?: boolean;
  useLandscapeAspectRatioCrop?: boolean;
  resizeSettings?: ResizeParams;
  onSelect: (response: string) => void;
};

const MAX_WIDTH = 2000;
const MAX_HEIGHT = 2000;

export default function ImagePickerBase64({
  label,
  useSquareAspectRatioCrop,
  useLandscapeAspectRatioCrop,
  resizeSettings,
  maxImageHeightInEditor,
  onSelect
}: ImagePickerBase64Type) {
  const [crop, setCrop] = useState<Crop | undefined>(undefined);
  const [selectedImage, setSelectedImage] = useState('');

  const [imageScale, setImageScale] = useState<ImageScale>({} as ImageScale);

  const { Translate } = useAppTranslation();

  const handleImageSelect = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length === 1) {
        const file = event.target.files[0];
        const imageUrl = URL.createObjectURL(file);

        const { width, height } = await getImageDimensions(imageUrl);

        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          toast.error(Translate('toast.image-too-large-max-is'));
          event.preventDefault();
          event.stopPropagation();
          setSelectedImage('');
          event.target.value = '';
          return;
        }

        setCrop(undefined);
        setSelectedImage(imageUrl);
        onSelect('');
      }
    },
    [Translate, onSelect]
  );

  const calculateInitialCrop = useCallback((width: number, height: number): ImageDimensions => {
    if (useSquareAspectRatioCrop) {
      const smallDimension = Math.min(width, height);
      return { width: smallDimension, height: smallDimension };
    }

    if (useLandscapeAspectRatioCrop) {
      const cropHeight = calculateImageHeightInAspectRatio({ width, aspectRatioWidth: 16, aspectRatioHeight: 9 });
      return { width, height: cropHeight };
    }

    // No Changes
    return { width, height };
  }, [useLandscapeAspectRatioCrop, useSquareAspectRatioCrop]);

  const loadInitialCrop = useCallback(async (editorDimensions: ImageDimensions) => {
    const initialCropDimensions = calculateInitialCrop(editorDimensions.width, editorDimensions.height);

    setCrop({
      width: initialCropDimensions.width,
      height: initialCropDimensions.height,
      x: 0,
      y: 0,
      unit: 'px'
    });
  }, [calculateInitialCrop]);

  const handleLoadEditorImageDimensions = useCallback(
    async ({ target }: React.SyntheticEvent<HTMLImageElement>) => {
      const editorImage = target as HTMLImageElement;

      const editorDimensions: ImageDimensions = {
        width: editorImage.width,
        height: editorImage.height
      };

      const originalDimensions = await getImageDimensions(selectedImage);
      const imageScale = getImageScale(originalDimensions, editorDimensions);

      setImageScale(imageScale);
      loadInitialCrop(editorDimensions);
    },
    [loadInitialCrop, selectedImage]
  );

  const processImage = useCallback(async () => {
    if (crop === undefined) return;

    const selectedCoordinatesOnOriginalImage = adjustCropCoordsToScale(crop, imageScale);

    const { x, y, width, height } = selectedCoordinatesOnOriginalImage;

    const croppedImage = await cropImage({ x, y, width, height, imageData: selectedImage });

    const outputDimensions = calculateFinalImageDimensions({ resizeSettings, cropWidth: width, cropHeight: height });

    const finalImage = await resizeImage(croppedImage, outputDimensions.width, outputDimensions.height);

    onSelect(finalImage);
  }, [crop, imageScale, selectedImage, resizeSettings, onSelect]);

  const aspectRatio = useMemo((): number | undefined => {
    if (useSquareAspectRatioCrop) return 1;
    if (useLandscapeAspectRatioCrop) return 16 / 9;
    return undefined;
  }, [useSquareAspectRatioCrop, useLandscapeAspectRatioCrop]);

  return (
    <React.Fragment>
      <input type='file' accept='image/*' onChange={handleImageSelect} className='d-block mb-1' />
      <small className='form-text text-muted mb-3'>{label}</small>

      <React.Fragment>
        <div className='d-flex mb-2'>
          <ReactCrop
            keepSelection
            crop={crop}
            aspect={aspectRatio}
            minWidth={30}
            minHeight={30}
            onChange={c => {
              setCrop(c);
            }}
            onComplete={processImage}
          >
            {selectedImage !== '' && (
              <img
                src={selectedImage}
                style={{ maxHeight: `${maxImageHeightInEditor}px` }}
                onLoad={handleLoadEditorImageDimensions}
              />
            )}
          </ReactCrop>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
}
