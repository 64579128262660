import React, { useCallback, useMemo, useRef, useState } from 'react';
import BasicCard from '../../../../components/Cards/BasicCard';
import TextInput from '../../../../components/Forms/TextInput';
import SplitButton from '../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../components/IconsCatalog';
import ClothingIconViewer from '../../../../components/ClothingIconViewer';
import ComboBox from '../../../../components/Forms/ComboBox';
import {
  type ClothingModelCreationType,
  type ClothingModelType
} from '../../../../types/ClothingModelType';
import BadgeToggle from '../../../../components/Buttons/BadgeToggle';
import {
  generateDefaultModelData,
  isValidClothingModelData,
  convertModelToStoreUpdateRequest,
  adjustClothingInformationsQuantity
} from './services/clothingModelsService';
import BasicButton from '../../../../components/Buttons/BasicButton';
import { useClothingModels } from '../../../../contexts/ClothingModelsContext';
import IconPickerModal from '../../../../components/Modals/IconPickerModal';
import toast from 'react-hot-toast-promise';
import { EmptyNameException } from './CustomExceptions/EmptyNameException';
import { NullIconException } from './CustomExceptions/NullIconException';
import Spinner from '../../../../components/Spinner';
import { ContextMenuProvider } from '../../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../components/ContextMenuViewer';
import ColorableRow from '../../../../components/Tables/ColorableRow';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';
import StickyMenuBar from '../../../../components/Menu/StickyMenuBar';
import { type BulkDeleteReportType } from '../../../../types/BulkDeleteReportType';
import { type BulkDeleteModelReportType } from '../../../../types/BulkDeleteModelReportType';
import BulkDeleteMiniReportModal from '../../../../components/Modals/BulkDeleteMiniReportModal';
import { useUser } from '../../../../contexts/UserContext';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import ModelPricingEditor from '../../../../components/ModelPricingEditor';
import { getServerErrorMessageFromResponse } from '../../../../utils/helper';

export default function MyClothingModels() {
  const refModelNameInput = useRef<HTMLInputElement>(null);

  const { models, addModel, editModel, deleteModels } = useClothingModels();
  const { isCompanyAccount } = useUser();
  const { Translate } = useAppTranslation();

  const initialModelData = useMemo(() => generateDefaultModelData(), []);

  const [model, setModel] = useState<ClothingModelCreationType>(initialModelData);
  const [selectedModels, setSelectedModels] = useState<ClothingModelType[]>([]);

  const [bulkDeleteReport, setBulkDeleteReport] = useState<BulkDeleteReportType<BulkDeleteModelReportType[]> | null>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showIconPickerModal, setShowIconPickerModal] = useState(false);
  const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);

  const handleAddModel = useCallback(async () => {
    try {
      isValidClothingModelData(model, Translate);
      const modelStoreRequestData = convertModelToStoreUpdateRequest(model);

      setIsAddingOrEditing(true);
      await addModel(modelStoreRequestData);

      setModel(initialModelData);
      setIsAddingOrEditing(false);
    } catch (err) {
      if (err instanceof EmptyNameException) refModelNameInput.current?.focus();
      else if (err instanceof NullIconException) setShowIconPickerModal(true);

      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, addModel, initialModelData, model]);

  const handleEditModel = useCallback(async () => {
    try {
      isValidClothingModelData(model, Translate);
      const modelUpdateRequestData = convertModelToStoreUpdateRequest(model);

      setIsAddingOrEditing(true);
      await editModel(modelUpdateRequestData, model.id);

      setModel(initialModelData);
      setIsAddingOrEditing(false);
    } catch (err) {
      if (err instanceof EmptyNameException) refModelNameInput.current?.focus();
      else if (err instanceof NullIconException) setShowIconPickerModal(true);

      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, editModel, initialModelData, model]);

  const handleExitSelectionMode = useCallback(() => {
    setSelectionMode(false);
    setSelectedModels([]);
  }, []);

  const handleDelete = useCallback(async () => {
    setShowDeleteConfirmationModal(false);

    toast.promise(deleteModels(selectedModels), {
      loading: Translate('progress.deleting'),
      success: () => {
        handleExitSelectionMode();
        return Translate('toast.models-deleted');
      },
      error: err => {
        handleExitSelectionMode();

        // rejected with report informations
        if ('success' in err) {
          const bulkDeleteReport = err as BulkDeleteReportType<BulkDeleteModelReportType[]>;
          setBulkDeleteReport(bulkDeleteReport);
          return bulkDeleteReport.message;
        }

        return <span>{getServerErrorMessageFromResponse(err)}</span>;
      }
    });
  }, [Translate, deleteModels, handleExitSelectionMode, selectedModels]);

  const isEditingModel = useMemo(() => model.id > 0, [model]);

  const handleFormSubmit = useCallback(() => {
    if (isEditingModel) handleEditModel();
    else handleAddModel();
  }, [handleAddModel, handleEditModel, isEditingModel]);

  const handleCheckModel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, model: ClothingModelType) => {
      const { checked } = event.target;

      if (checked) {
        setSelectedModels([...selectedModels, model]);
        return;
      }

      const updatedSelectedModels = selectedModels.filter(currentOrder => currentOrder.id !== model.id);
      setSelectedModels(updatedSelectedModels);
    },
    [selectedModels]
  );

  const handleCheckAllModels = useCallback((event: React.ChangeEvent<HTMLInputElement>, models: ClothingModelType[]) => {
    const { checked } = event.target;

    if (checked) setSelectedModels([...models]);
    else setSelectedModels([]);
  }, []);

  const handleCloseBulkDeleteReportModal = useCallback(() => {
    setBulkDeleteReport(null);
  }, []);

  const contextMenuModelOptions = useMemo<ContextMenuItemType[]>(
    () => [
      {
        name: Translate('actions.edit'),
        icon: IconsCatalog.pen,
        handleClick: selectedOption => {
          const selectedModelOption = adjustClothingInformationsQuantity(selectedOption as ClothingModelType);
          setModel(selectedModelOption);
          refModelNameInput.current?.scrollIntoView({ behavior: 'smooth' });
        }
      },
      {
        name: Translate('actions.delete'),
        icon: IconsCatalog.trash,
        handleClick: selectedOption => {
          setSelectedModels([selectedOption as ClothingModelType]);
          setShowDeleteConfirmationModal(true);
        }
      },
      {
        name: Translate('actions.selection-mode'),
        icon: IconsCatalog.checkSquare,
        handleClick: () => {
          setSelectionMode(true);
        }
      }
    ],
    [Translate]
  );

  return (
    <React.Fragment>
      <div>
        <IconPickerModal
          title={Translate('labels.icon')}
          message={Translate('description.pick-a-icon')}
          visible={showIconPickerModal}
          handleClose={() => {
            setShowIconPickerModal(false);
          }}
          handleConfirm={icon => {
            setModel({ ...model, icon });
          }}
        />

        <ConfirmationModal
          title={Translate('modal.delete-model-confirm')}
          message={Translate('description.confirm-delete-selected-models')}
          style='danger'
          visible={showDeleteConfirmationModal}
          handleClose={() => {
            setSelectedModels([]);
            setShowDeleteConfirmationModal(false);
          }}
          handleConfirm={handleDelete}
        />

        {!!bulkDeleteReport && (
          <BulkDeleteMiniReportModal
            message={Translate('description.selected-models-report-failed-delete')}
            handleClose={handleCloseBulkDeleteReportModal}
            report={bulkDeleteReport.report}
            reportItemKey='model_name'
            reportSubItemsKey='preset_names'
            reportSubItemsLabel={Translate('labels.presets-using-model')}
          />
        )}

        <h1 className='h3 mb-2 text-gray-800'>{Translate('labels.clothing-models')}</h1>
        <p className='mb-4'>{Translate('description.clothing-models-header-description')}</p>

        {isCompanyAccount && (
          <BasicCard title={Translate('labels.register')}>
            <div className='row'>
              <div className='col-3'>
                <TextInput
                  inputRef={refModelNameInput}
                  label={Translate('labels.model-name')}
                  value={model.name}
                  id='txtModelName'
                  tabIndex={1}
                  autofocus
                  onChange={({ target }) => {
                    setModel({ ...model, name: target.value });
                  }}
                />
              </div>

              <div className='col-2'>
                <BadgeToggle
                  formControl
                  checked={model.available}
                  label={`${Translate('labels.available')}?`}
                  textOff={Translate('status.no')}
                  textOn={Translate('status.yes')}
                  onClick={() => {
                    setModel({ ...model, available: !model.available });
                  }}
                />
              </div>

              <div className='col-2'>
                <ComboBox
                  id='code'
                  value={model.SBCode}
                  header={Translate('labels.code')}
                  tabIndex={2}
                  handleChange={({ target }) => {
                    setModel({ ...model, SBCode: target.value as ClothingModelType['SBCode'] });
                  }}
                  data={[
                    { label: 'CAM1', value: 'CAM1' },
                    { label: 'CAM2', value: 'CAM2' },
                    { label: 'SHO1', value: 'SHO1' },
                    { label: 'CAL1', value: 'CAL1' },
                    { label: 'REG1', value: 'REG1' },
                    { label: 'COL1', value: 'COL1' }
                  ]}
                />
              </div>

              <div className='col-3'>
                <div className='form-group'>
                  <label>{Translate('labels.icon')}</label>
                  <div className='d-flex'>
                    {!!model.icon && <ClothingIconViewer icon={model.icon.url} />}
                    <BasicButton
                      marginLeft={!!model.icon}
                      tabIndex={3}
                      title={Translate('actions.choose')}
                      color='primary'
                      handleClick={() => {
                        setShowIconPickerModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <ModelPricingEditor
                  modelPricingInformations={model.informations}
                  maleTabId='male'
                  femaleTabId='female'
                  childishTabId='childish'
                  onModelBudgetChange={(updatedPricing) => {
                    setModel({ ...model, informations: updatedPricing });
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col justify-content-end'>
                {isAddingOrEditing && <Spinner alignLeft />}

                {!isAddingOrEditing && (
                  <React.Fragment>
                    <SplitButton
                      color='success'
                      icon={IconsCatalog.save}
                      title={isEditingModel ? Translate('actions.save-model') : Translate('actions.add-model')}
                      tabIndex={4}
                      handleClick={handleFormSubmit}
                    />

                    <SplitButton
                      marginLeft
                      color='secondary'
                      icon={IconsCatalog.save}
                      title={isEditingModel ? Translate('actions.cancel') : Translate('actions.clear')}
                      tabIndex={4}
                      handleClick={() => {
                        if (isEditingModel) toast.error(Translate('toast.editing-canceled'));
                        else toast.error(Translate('toast.form-canceled'));
                        setModel(generateDefaultModelData());
                      }}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </BasicCard>
        )}

        {selectionMode && models.length > 0 && (
          <StickyMenuBar countSelectedItems={selectedModels.length}>
            <SplitButton
              size='sm'
              icon={IconsCatalog.times}
              title={Translate('actions.cancel')}
              color='light'
              handleClick={handleExitSelectionMode}
            />

            <SplitButton
              size='sm'
              icon={IconsCatalog.trash}
              title={Translate('actions.delete-selected')}
              color='danger'
              marginLeft
              handleClick={() => {
                setShowDeleteConfirmationModal(true);
              }}
            />
          </StickyMenuBar>
        )}

        <BasicCard title={Translate('labels.models-control')} description={Translate('description.current-clothing-models')}>
          <ContextMenuProvider>
            <ContextMenuViewer
              options={contextMenuModelOptions}
              disabled={selectionMode || isEditingModel || !isCompanyAccount}
            />

            <table className='table table-bordered' id='dataTable' width='100%'>
              <thead>
                <tr>
                  {selectionMode ? (
                    <th className='text-center'>
                      <input
                        type='checkbox'
                        defaultChecked={false}
                        onChange={event => {
                          handleCheckAllModels(event, models);
                        }}
                      />
                    </th>
                  ) : null}
                  <th>{Translate('labels.available')}</th>
                  <th className='text-center'>{Translate('labels.code')}</th>
                  <th className='text-center'>{Translate('labels.icon')}</th>
                  <th>{Translate('labels.name')}</th>
                </tr>
              </thead>
              <tbody>
                {models.map((model, index) => (
                  <ColorableRow key={index} data={model} isSelected={selectedModels.includes(model)}>
                    {selectionMode ? (
                      <td width={50} className='text-center align-middle'>
                        <input
                          type='checkbox'
                          name={model.id.toString()}
                          checked={selectedModels.includes(model)}
                          onChange={event => {
                            handleCheckModel(event, model);
                          }}
                        />
                      </td>
                    ) : null}

                    <td width={90} className='text-center align-middle'>
                      <BadgeToggle
                        textOn={Translate('status.yes')}
                        textOff={Translate('status.no')}
                        checked={model.available}
                        disabled
                      />
                    </td>
                    <td width={90} className='text-center align-middle'>
                      <span className='badge badge-primary'>{model.SBCode}</span>
                    </td>
                    <td width={90} className='text-center'>
                      <ClothingIconViewer icon={model.icon?.url} />
                    </td>
                    <td className='align-middle'>{model.name}</td>
                  </ColorableRow>
                ))}

                {models.length === 0 && (
                  <tr className='text-center'>
                    <td colSpan={4}>{Translate('status.no-models-registered')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </ContextMenuProvider>
        </BasicCard>
      </div>
    </React.Fragment>
  );
}
