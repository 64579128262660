import React, { useEffect, useMemo, useState } from 'react';
import { PrintOverlay } from './styles';
import ProjectReportHeader from './components/ProjectReportHeader';
import LayoutGallery from './components/LayoutGallery';
import { useHeaderBackButton } from '../../../../contexts/HeaderBackButtonContext';
import ProjectReportSublistHeader from './components/ProjectReportSublistHeader';
import ProjectReportSublistContent from './components/ProjectReportSublistContent';
import { useLocation, useNavigate } from 'react-router';
import { type SublistType } from '../../../../types/SublistTypes';
import { type ProjectReportType } from '../../../../types/ProjectReportType';
import { type ClothingModelType } from '../../../../types/ClothingModelType';
import DetailedClothingReport from './components/DetailedClothingReport';
import ProjectReportSettings from './components/ProjectReportSettings';
import { type ProjectType } from '../../../../types/ProjectType';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import { displayAsCurrency, displayFriendlyWeight } from '../../../../utils/helper';
import { type ProjectReportSettingsType } from '../../../../types/Report/ProjectReportSettingsType';
import { useSettings } from '../../../../contexts/SettingsContext';
import BasicCard from '../../../../components/Cards/BasicCard';
import { calculateTotalAmmountPaymentsReceived } from '../ViewProject/components/PaymentReceipts/services/paymentCalculatorService';
import { getElapsedTimeStatus } from '../../../../helpers/dateTime';

export default function ProjectReport() {
  const location = useLocation();
  const navigate = useNavigate();

  const { preferences } = useSettings();
  const { showBackButton, hideBackButton } = useHeaderBackButton();
  const { Translate } = useAppTranslation();

  const [reportSettings, setReportSettings] = useState<ProjectReportSettingsType>(() => {
    const { report_hide_order_number, report_hide_prices, report_show_gender_colors, report_show_layout_below_sublist, report_show_one_gender_per_line } = preferences;

    return {
      report_hide_order_number,
      report_hide_prices,
      report_show_gender_colors,
      report_show_layout_below_sublist,
      report_show_one_gender_per_line
    };
  });

  const [sublists, setSublists] = useState<SublistType[]>([]);
  const [project, setProject] = useState<ProjectType>({} as ProjectType);
  const [projectReport, setProjectReport] = useState<ProjectReportType>({} as ProjectReportType);
  const [importedModels, setImportedModels] = useState<ClothingModelType[]>([]);

  const totalAmmountPaymentsReceived = useMemo(() => {
    if (!project.payments) return 0;
    return calculateTotalAmmountPaymentsReceived(project.payments);
  }, [project.payments]);

  const projectHasAnyLayout = useMemo(() => {
    const layoutCount = sublists.reduce((accumulator, currentSublist) => {
      const sumValue = currentSublist.layout ? 1 : 0;
      return accumulator + sumValue;
    }, 0);

    return layoutCount > 0;
  }, [sublists]);

  const projectHasDiscont = useMemo(() => {
    return projectReport.totalProjectPriceWithDiscount === projectReport.totalProjectPrice;
  }, [projectReport]);

  const estimatedProductionTime = useMemo(() => {
    return getElapsedTimeStatus(projectReport.totalProjectProductionTimeInSeconds);
  }, [projectReport.totalProjectProductionTimeInSeconds]);

  useEffect(() => {
    showBackButton();

    if (
      !location.state?.sublists ||
      !location.state?.project ||
      !location.state?.projectReport ||
      !location.state?.importedModels
    ) {
      navigate(-1);
      return;
    }

    setSublists(location.state.sublists);
    setProject(location.state.project);
    setProjectReport(location.state.projectReport);
    setImportedModels(location.state.importedModels);

    return () => {
      hideBackButton();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!projectReport?.ready) return <></>;

  return (
    <React.Fragment>
      <div className='row d-print-none'>
        <div className='col-6 offset-3'>
          <BasicCard title={Translate('labels.settings')}>
            <ProjectReportSettings
              currentSettings={reportSettings}
              onChange={(updatedSettings) => {
                setReportSettings(updatedSettings);
              }} />
          </BasicCard>
        </div>
      </div>

      <PrintOverlay>
        <div className='container'>
          <ProjectReportHeader project={project} hideOrderNumber={reportSettings.report_hide_order_number} />

          {!reportSettings.report_hide_prices && (
            <div className="row justify-content-end">
              <div className="col-auto">
                <h5>{Translate('labels.received')}: <strong>{displayAsCurrency(totalAmmountPaymentsReceived, preferences.currency)}</strong></h5>
              </div>
              <div className="col-auto pr-0">
                <h5>{Translate('labels.to-receive')}: <strong>{displayAsCurrency(projectReport.totalProjectPriceWithDiscount - totalAmmountPaymentsReceived, preferences.currency)}</strong></h5>
              </div>
            </div>
          )}

          {sublists
            .map((sublist, sublistIndex) => {
              return sublist.orders.length > 0 && (
                <div className='row' key={sublistIndex}>
                  <div className='col p-0 mt-2'>
                    <ProjectReportSublistHeader
                      sublist={sublist}
                      sublistReport={projectReport.sublists[sublistIndex]}
                      importedModels={importedModels}
                      useSeparatedLinesToShowDetailedReport={reportSettings.report_show_one_gender_per_line}
                    />

                    <ProjectReportSublistContent
                      sublist={sublist}
                      sublistReport={projectReport.sublists[sublistIndex]}
                      importedModels={importedModels}
                      showGenderColors={reportSettings.report_show_gender_colors}
                      hidePricesInReport={reportSettings.report_hide_prices}
                      showLayoutImagesBelowLists={reportSettings.report_show_layout_below_sublist}
                      preferences={preferences}
                    />
                  </div>
                </div>
              );
            })}

          <div className='row'>
            <div className='col mt-2 p-0'>
              <table className='table table-sm table-bordered'>
                <tbody>
                  <tr>
                    <td>
                      <strong>{Translate('labels.full-count')}</strong>
                      <span className='border-left mx-3' />
                      {Translate('labels.clothes-count').replace('{count}', projectReport.totalProjectClothes.toString())}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <DetailedClothingReport
                        importedModels={importedModels}
                        quantitiesDetailedReport={projectReport.projectClothingQuantitiesDetailed}
                        useSeparatedLinesToShowDetailedReport={reportSettings.report_show_one_gender_per_line}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='row'>
            <div className='col p-0'>
              <table className='table table-sm table-bordered'>
                <tbody>
                  <tr>
                    <td className={`${reportSettings.report_hide_prices ? 'd-none' : ''}`}>
                      {Translate('labels.final-price')}: <strong>{displayAsCurrency(projectReport.totalProjectPrice, preferences.currency)}</strong>
                    </td>
                    <td className={`${reportSettings.report_hide_prices || projectHasDiscont ? 'd-none' : ''}`}>
                      {Translate('labels.total-price-with-discount')}: <strong>{displayAsCurrency(projectReport.totalProjectPriceWithDiscount, preferences.currency)}</strong>
                    </td>
                    <td>
                      {Translate('labels.final-estimated-weight')}: <strong>{displayFriendlyWeight(projectReport.totalProjectWeight)}</strong>
                    </td>
                  </tr>

                  {
                    !!estimatedProductionTime && (
                      <tr>
                        <td colSpan={999}>{Translate('labels.estimated-production-time')}: <strong>{estimatedProductionTime}</strong></td>
                      </tr>
                    )
                  }

                  {
                    projectReport.totalProjectMaterialLengthInMeters > 0 && (
                      <tr>
                        <td colSpan={999}>{Translate('labels.estimated-consumption-papel-fabric')}: <strong>{projectReport.totalProjectMaterialLengthInMeters} {Translate('labels.meters')}.</strong></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>

          {projectHasAnyLayout && !reportSettings.report_show_layout_below_sublist && <LayoutGallery sublists={sublists} importedModels={importedModels} />}
        </div>
      </PrintOverlay>
    </React.Fragment>
  );
}
