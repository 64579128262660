import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useMutation, useQueryClient } from 'react-query';
import Zoom from 'react-medium-image-zoom';
import _ from 'lodash';

import { type SatisfactionSurveyType } from '../../../../../../types/FinalClientEditor/ProjectDataFinalClientEditor';
import { deleteSurvey } from '../../service';
import ModalBase from '../../../../../../components/Modals/ModalBase';
import { generateSatisfactionSurveyImagePath } from '../../../../../../helpers/general';
import { Card, Container } from './style';

type ContentProps = {
  data?: SatisfactionSurveyType[];
};

export function Content({ data }: ContentProps) {
  const [modalVisible, setModalVisible] = useState<number | null>(null);
  const [showSurvey, setShowSurvey] = useState<SatisfactionSurveyType | null>(null);
  const query = useQueryClient();

  const deleteMutation = useMutation(
    async () => {
      if (!modalVisible) return;

      await deleteSurvey.execute(modalVisible);
    },
    {
      onSuccess: () => {
        query.invalidateQueries('survey');
      }
    }
  );

  const contentTable = useMemo(() => {
    return (
      <table className='table table-sm table-bordeless'>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Projeto</th>
            <th>Avaliação</th>
            <th>Data</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {data?.map(survey => (
            <tr key={survey.id}>
              <td>
                <span>{survey.project.client_name}</span>
              </td>
              <td>
                <span>{survey.project.name}</span>
              </td>
              <td>
                <span>{survey.note}</span>
              </td>
              <td>
                <span>{format(new Date(survey.created_at), 'dd/MM/yyyy')}</span>
              </td>
              <td className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-primary mr-3'
                  onClick={() => {
                    setShowSurvey(survey);
                  }}
                >
                  <i className='fas fa-eye'></i>
                </button>

                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => {
                    setModalVisible(survey.id);
                  }}
                >
                  <i className='fas fa-trash'></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [data]);

  if (_.isEmpty(data)) {
    return (
      <Card>
        <h4 className='text-center my-5'>Nenhuma avaliação encontrada</h4>
      </Card>
    );
  }

  return (
    <Container>
      <ModalBase
        style='danger'
        visible={!!modalVisible}
        title='Deletar pesquisa'
        handleClose={() => {
          setModalVisible(null);
        }}
        handleConfirm={() => {
          deleteMutation.mutate();
        }}
        confirmationButtonDisabled={deleteMutation.isLoading}
      >
        <div>
          <h5>Remover pesquisa</h5>
          <p>Tem certeza que deseja remover a pesquisa?</p>
        </div>
      </ModalBase>
      <ModalBase
        style='primary'
        visible={!!showSurvey}
        title={`Avaliação de: ${showSurvey?.project.client_name}`}
        handleClose={() => {
          setShowSurvey(null);
        }}
        handleConfirm={() => {}}
        hideFooter
      >
        <div className='d-flex flex-column nowrap'>
          <div className='d-flex flex-column'>
            <span>Projeto: {showSurvey?.project.name}</span>
            <span>Data: {showSurvey?.created_at ? format(new Date(showSurvey?.created_at), 'dd/MM/yyyy') : null}</span>
          </div>

          <div className='d-flex flex-column'>
            <span>Nota: {showSurvey?.note}</span>
            <span>Comentário: {showSurvey?.comment}</span>
          </div>

          <span>Imagens:</span>
          <div className='d-flex'>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '1rem',
                width: '100%'
              }}
            >
              {showSurvey?.images?.map(image => (
                <Zoom key={image.id}>
                  <img
                    src={generateSatisfactionSurveyImagePath({ filename: image.file_name })}
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '220px',
                      objectFit: 'cover'
                    }}
                  />
                </Zoom>
              ))}
            </div>
          </div>
        </div>
      </ModalBase>
      <Card>{contentTable}</Card>

      <Card></Card>
    </Container>
  );
}
