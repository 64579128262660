import React from 'react';
import SplitButton from '../../../../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../../../../components/IconsCatalog';

type StepperFooterButtonsType = {
  stepsCount: number;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export default function StepperFooterButtons({ currentStep, setCurrentStep, stepsCount }: StepperFooterButtonsType) {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-6 border-top pt-3 mt-3">
        <div className='d-flex justify-content-between'>
          <SplitButton color='primary' icon={IconsCatalog.arrowLeft} handleClick={() => { setCurrentStep(currentStep - 1); }} disabled={currentStep === 0} />
          <SplitButton color='primary' icon={IconsCatalog.arrowRight} handleClick={() => { setCurrentStep(currentStep + 1); }} disabled={currentStep === stepsCount - 1} />
        </div>
      </div>
    </div>
  );
};
