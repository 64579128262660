import React, { useCallback, useState } from 'react';
import SwitchInput from '../../../../../../../../components/Forms/SwitchInput';
import { type TableOrderManagerType } from '../..';
import ModelPricingEditor from '../../../../../../../../components/ModelPricingEditor';
import Tabs from '../../../../../../../../components/Tabs';
import { type ClothingSizeType, type ClothingGenderType, type ClothingModelType } from '../../../../../../../../types/ClothingModelType';
import { updateBasicPricingInformation } from './services/modelService';
import SplitButton from '../../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../../components/IconsCatalog';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';

/**
 * in case the exclusiveModelsPricingInformations are null
 * we will take the pricing from importedModels to generate default values
 * and user can change according to their needs
 */
type ExclusivePricingSublistEditorType = Pick<TableOrderManagerType, 'importedModels'> & {
  isLockedToUser?: boolean;
  sublistUUID: string;
  usingExclusiveModelPricing: boolean;
  exclusiveModelsPricingInformations: ModelPricingInformation[];
  onSaveModelsPricingInformations: (updatedModelPricingInformations: ModelPricingInformation[], useExclusiveModelPricing: boolean) => void;
};

export type ModelPricingInformation = Pick<ClothingModelType, 'id' | 'informations'>;

export type HandleUpdateModelPricingInformationsParams = {
  basicPricingInformations: ModelPricingInformation[];
  targetBasicModelPricingId: number;
  updatedPricingInformations: ClothingGenderType;
}

export default function ExclusivePricingSublistEditor({ sublistUUID, isLockedToUser, importedModels, onSaveModelsPricingInformations, usingExclusiveModelPricing, exclusiveModelsPricingInformations }: ExclusivePricingSublistEditorType) {
  const { Translate } = useAppTranslation();

  const [useExclusiveModelPricing, setUseExclusiveModelPricing] = useState<boolean>(usingExclusiveModelPricing);

  const [basicPricingInformations, setBasicPricingInformation] = useState<ModelPricingInformation[]>(() => {
    /**
      if using exclusive prices, we must sync the sizes wth importedModels
      because if user edit any size in importedModels
      it will not reflect in exclusive model pricings
      and the stored index for the selected size will mismatch the size
      that's why the sync is needed
     */

    if (exclusiveModelsPricingInformations.length > 0) {
      const genders: Array<keyof ClothingGenderType> = ['male', 'female', 'childish'];

      genders.forEach(gender => {
        importedModels.forEach(importedModel => {
          const currentModelSizesToSync = importedModel.informations[gender].map(({ size }) => size);

          const modelIndexInsideExclusivePricing = exclusiveModelsPricingInformations.findIndex(modelPricing => modelPricing.id === importedModel.id);

          const syncedSizesInCurrentGender = exclusiveModelsPricingInformations[modelIndexInsideExclusivePricing].informations[gender].map<ClothingSizeType>((exclusiveBudget, index) => {
            return { ...exclusiveBudget, size: currentModelSizesToSync[index] };
          });

          exclusiveModelsPricingInformations[modelIndexInsideExclusivePricing].informations[gender] = syncedSizesInCurrentGender;
        });
      });

      return exclusiveModelsPricingInformations;
    }

    // return default initial values based in imported models prices
    return importedModels.map(({ id, informations }) => ({ id, informations }));
  });

  const handleUpdateModelPricingInformations = useCallback(({ basicPricingInformations, targetBasicModelPricingId, updatedPricingInformations }: HandleUpdateModelPricingInformationsParams) => {
    const updatedBasicPricingInformations = updateBasicPricingInformation({ basicPricingInformations, targetBasicModelPricingId, updatedPricingInformations });
    setBasicPricingInformation(updatedBasicPricingInformations);
  }, []);

  const handleSaveModelPricingInformations = useCallback(async () => {
    onSaveModelsPricingInformations(basicPricingInformations, useExclusiveModelPricing);
  }, [basicPricingInformations, onSaveModelsPricingInformations, useExclusiveModelPricing]);

  const renderImportedModelsOnTabs = useCallback(() => {
    return basicPricingInformations.map((basicModelPricing, index) => (
      {
        id: `model-tab-${index}-${sublistUUID}`,
        active: index === 0,
        label: importedModels[index].name,
        content: (
          <ModelPricingEditor
            key={index}
            disabled={!useExclusiveModelPricing}
            disableSizeEditing={true}
            modelPricingInformations={basicPricingInformations[index].informations}
            maleTabId={`male-${basicModelPricing.id}-${sublistUUID}`}
            femaleTabId={`female-${basicModelPricing.id}-${sublistUUID}`}
            childishTabId={`childish-${basicModelPricing.id}-${sublistUUID}`}
            onModelBudgetChange={(updatedPricingInformations) => {
              handleUpdateModelPricingInformations({
                basicPricingInformations,
                targetBasicModelPricingId: basicModelPricing.id,
                updatedPricingInformations
              });
            }}
          />
        )
      }
    ));
  }, [basicPricingInformations, handleUpdateModelPricingInformations, importedModels, useExclusiveModelPricing]);

  return (
    <React.Fragment>
      {isLockedToUser && (
        <div className="row mx-0 py-3">
          <div className="col">
            <p>{Translate('description.exclusive-prices-description')}</p>
            <i className='badge badge-danger'>{Translate('status.unavailable-to-this-account')}</i>
          </div>
        </div>
      )}

      {!isLockedToUser && (
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col">
              <p>{Translate('description.exclusive-prices-description')}</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <SwitchInput
                id={`checkbox-use-exclusive-model-${sublistUUID}-pricing`}
                label={useExclusiveModelPricing ? Translate('status.activated') : Translate('status.deactivated')}
                checked={useExclusiveModelPricing}
                handleChange={(checked => {
                  setUseExclusiveModelPricing(checked);
                })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Tabs data={renderImportedModelsOnTabs()} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SplitButton
                icon={IconsCatalog.save}
                color='success'
                handleClick={handleSaveModelPricingInformations}
                title={Translate('actions.save-prices-to-sublist')} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
