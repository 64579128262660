import React, { useCallback, useMemo, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../../../components/Modals/ModalBase';
import { useClothingFinishingOptions } from '../../../../../../../../contexts/ClothingFinishingOptionsContext';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import { type ClothingModelType } from '../../../../../../../../types/ClothingModelType';
import Tabs, { type TabParam } from '../../../../../../../../components/Tabs';
import FinishingOptionsPicker, { type AddFinishinOptionToSublistParams } from './components/FinishingOptionsPicker';
import SplitButton from '../../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../../components/IconsCatalog';
import { useSettings } from '../../../../../../../../contexts/SettingsContext';
import { type SublistFinishingOption } from '../../../../../../../../types/ClothingFinishingOptions/SublistFinishingOption';
import { addSublistFinishingOption } from './api/addSublistFinishingOption';
import { toast } from 'react-hot-toast-promise';
import { useHttpRequest } from '../../../../../../../../contexts/HttpRequestContext';
import { isDuplicatedFinishingOptionInModel } from './services/finishingOptionsServices';
import { removeSublistFinishingOption, type RemoveSublistFinishingOptionParams } from './api/removeSublistFinishingOption';
import { getServerErrorMessageFromResponse } from '../../../../../../../../utils/helper';

type FinishingOptionsManagerType = Pick<ModalBaseType, 'visible' | 'style' | 'title' | 'message' | 'handleClose'> & {
  sublist_id: number | undefined;
  importedModels: ClothingModelType[];
  sublistFinishingOptions: SublistFinishingOption[];
  handleConfirm: () => void;
  onAddSublistFinishingOption: (data: SublistFinishingOption) => void;
  onRemoveSublistFinishingOption: (removedPickedOptionId: number) => void;
}

export default function FinishingOptionsManagerForSublist({ importedModels, sublist_id, sublistFinishingOptions, onAddSublistFinishingOption, onRemoveSublistFinishingOption, ...props }: FinishingOptionsManagerType) {
  const { Translate } = useAppTranslation();
  const { finishingOptions } = useClothingFinishingOptions();
  const { preferences } = useSettings();
  const { httpConnection } = useHttpRequest();

  const [isRequesting, setIsRequesting] = useState(false);

  const handleAddFinishingOptionToSublist = useCallback(({ model_id, selectedFinishingOption, selectedFinishingOptionItem }: AddFinishinOptionToSublistParams) => {
    const isDuplicated = isDuplicatedFinishingOptionInModel({ sublistFinishingOptions, modelId: model_id, selectedFinishingOptionId: selectedFinishingOption.id });

    if (isDuplicated) {
      toast.error(Translate('error.finishing-option-already-picked'));
      return;
    }

    const task = addSublistFinishingOption({
      sublist_id: sublist_id!,
      model_id,
      finishing_option_id: selectedFinishingOption.id,
      finishing_option_item_id: selectedFinishingOptionItem.id
    }, httpConnection);

    setIsRequesting(true);

    toast.promise(task, {
      loading: 'Adicionando acabamento...',
      success: (data) => {
        onAddSublistFinishingOption(data);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsRequesting(false);
      }
    });
  }, [Translate, httpConnection, onAddSublistFinishingOption, sublistFinishingOptions, sublist_id]);

  const handleRemoveFinishingOptionToSublist = useCallback(({ sublist_id, picked_finishing_option_id }: RemoveSublistFinishingOptionParams) => {
    const task = removeSublistFinishingOption({ sublist_id, picked_finishing_option_id }, httpConnection);

    setIsRequesting(true);

    toast.promise(task, {
      loading: Translate('progress.removing-finishing-option'),
      success: () => {
        onRemoveSublistFinishingOption(picked_finishing_option_id);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsRequesting(false);
      }
    });
  }, [Translate, httpConnection, onRemoveSublistFinishingOption]);

  const filterFinishingOptionByModelId = useCallback((model_id: number) => {
    return sublistFinishingOptions.filter(sublistFinishingOption => sublistFinishingOption.model_id === model_id);
  }, [sublistFinishingOptions]);

  const tabsSettings = useMemo(() => {
    const tabs: TabParam[] = importedModels.map<TabParam>((model, modelIndex) => ({
      id: `tab-model-${model.id}`,
      label: model.name,
      active: modelIndex === 0,
      content: (
        <React.Fragment>
          <h5 className='mt-3'>Incluídos</h5>

          <table className='table table-bordered table-sm table-hover'>
            <thead>
              <tr>
                <th>{Translate('labels.type')}</th>
                <th>{Translate('labels.option')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterFinishingOptionByModelId(model.id).map(({ id, option, option_picked }, index) => (
                <tr key={index}>
                  <td className='align-middle'>{option.title}</td>
                  <td className='align-middle'>{option_picked.item}</td>
                  <td className='table-column-fit'>
                    <SplitButton
                      disabled={isRequesting}
                      icon={IconsCatalog.trash}
                      color='danger'
                      size='sm'
                      handleClick={() => {
                        handleRemoveFinishingOptionToSublist({ sublist_id: sublist_id!, picked_finishing_option_id: id });
                      }} />
                  </td>
                </tr>
              ))}

              {
                sublistFinishingOptions.length === 0 && (
                  <tr>
                    <td colSpan={999}>{Translate('status.no-finishing-options')}</td>
                  </tr>
                )
              }
            </tbody>
          </table>

          <FinishingOptionsPicker
            disabled={isRequesting}
            model_id={model.id}
            finishingOptions={finishingOptions}
            preferences={preferences}
            onAddFinishinOptionToSublist={({ selectedFinishingOption, selectedFinishingOptionItem, model_id }) => {
              handleAddFinishingOptionToSublist({ selectedFinishingOption, selectedFinishingOptionItem, model_id });
            }}
          />
        </React.Fragment>
      )
    }));

    return tabs;
  }, [filterFinishingOptionByModelId, finishingOptions, handleAddFinishingOptionToSublist, handleRemoveFinishingOptionToSublist, importedModels, isRequesting, preferences, sublistFinishingOptions, sublist_id, Translate]);

  if (!sublist_id || !sublistFinishingOptions) return <></>;

  return (
    <ModalBase {...props} confirmationButtonDisabled={isRequesting} disableEscToClose={isRequesting} disableClickOutsideToClose={isRequesting}>
      <div className="row">
        <div className="col">
          <Tabs data={tabsSettings} />
        </div>
      </div>
    </ModalBase>
  );
};
