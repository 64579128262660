import { type AxiosInstance } from 'axios';
import { type SublistFinishingOption } from '../../../../../../../../../types/ClothingFinishingOptions/SublistFinishingOption';

type AddSublistFinishingOptionParams = {
  sublist_id: number;
  model_id: number;
  finishing_option_id: number;
  finishing_option_item_id: number;
}

export const addSublistFinishingOption = async (params: AddSublistFinishingOptionParams, httpConnection: AxiosInstance): Promise<SublistFinishingOption> => {
  return await new Promise((resolve, reject) => {
    httpConnection.post<SublistFinishingOption>('/list/clothing-projects/sublist/add-finishing-option', params)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
