import { v4 as uuidv4 } from 'uuid';
import { type ClothingGenderType, type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type ComboBoxDataType } from '../../../../../types/ComboBoxDataType';
import { type ClothingProductionData, type Order } from '../../../../../types/OrderTypes';
import { type SublistType } from '../../../../../types/SublistTypes';
import { type TFunction } from 'i18next';
import { convertMMTimeToSeconds } from '../../../../../components/Forms/TimeInput/services/timeInputService';

export const generateComboBoxOptionsForClothingInput = (model: ClothingModelType, gender: keyof ClothingGenderType): ComboBoxDataType[] => {
  const comboBoxOptions = model.informations[gender].filter(budget => !!budget.size).map<ComboBoxDataType>((budget, index) => ({
    label: budget.size,
    value: index
  }));

  return [{ label: '-', value: -1 }, ...comboBoxOptions];
};

export const generateBloodTypeOptions = (emptyLabel: string): ComboBoxDataType[] => {
  const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
  const options = bloodTypes.map<ComboBoxDataType>(blood => ({ label: blood, value: blood }));

  options.unshift({ label: emptyLabel, value: '' });

  return options;
};

export const generateSublistOptions = (sublists: SublistType[]): ComboBoxDataType[] => {
  return sublists.map<ComboBoxDataType>((item, index) => ({
    label: item.title, value: index.toString()
  }));
};

export const generateClothingProductionDataFromModels = (models: ClothingModelType[]): ClothingProductionData[] => {
  return models.map<ClothingProductionData>(currentModel => ({
    modelId: currentModel.id,
    sizeIndex: -1,
    quantity: 0
  }));
};

export const emptySublistData = (title: string): SublistType[] => {
  return [{
    uuid: uuidv4(),
    orders: [],
    title,
    details: null,
    sorting: null,
    layout: null,
    token: null,
    exclusive_model_pricing: [],
    use_exclusive_model_pricing: false,
    selected_finishing_options: []
  }];
};

type ChangeGenderAndResetClotheSizesType = {
  order: Order;
  gender: Order['gender'];
}

/**
 * When the user changes the gender after fill some clothing data in the form,
 * the previews selected size will be reseted in UI but not reflected on state.
 * This function reset sizes for all clothes to keep UI in sync with state.
 * @param param0 parameters to generate updated order.
 * @returns Order object to update the state.
 */
export const changeGenderAndResetClotheSizes = ({ order, gender }: ChangeGenderAndResetClotheSizesType): Order => {
  const clothingProductionData = order.clothes.map<ClothingProductionData>(item => ({
    ...item,
    size: '',
    quantity: 0
  }));

  const updatedOrder: Order = {
    ...order,
    gender,
    clothes: clothingProductionData
  };

  return updatedOrder;
};

type DeleteMultipleOrdersType = {
  sublists: SublistType[];
  selectedOrders: Order[];
}

export const deleteMultipleOrders = ({ sublists, selectedOrders }: DeleteMultipleOrdersType): SublistType[] => {
  return sublists.map(sublist => {
    const filteredOrders = sublist.orders.filter(order => !selectedOrders.includes(order));

    return {
      ...sublist,
      orders: filteredOrders
    };
  });
};

type CalculateOrderPriceParams = {
  sublist: SublistType;
  order: Order;
  importedModels: ClothingModelType[];
  Translate: TFunction;
}

const calculateTotalFinishingOptionItemsPriceForModel = (sublist: SublistType, modelId: number) => {
  const selectedFinishingOptionsForModel = sublist.selected_finishing_options.filter(finishingOption => finishingOption.model_id === modelId);

  const totalAmmount = selectedFinishingOptionsForModel.reduce((accumulator, finishingOption) => {
    return accumulator + finishingOption.option_picked.price;
  }, 0);

  return totalAmmount;
};

export const calculateOrderPrice = ({ sublist, order, importedModels, Translate }: CalculateOrderPriceParams): number => {
  const totalOrderPrice = order.clothes.reduce((accumulator, currentClothe) => {
    const model = importedModels.find(model => model.id === currentClothe.modelId);
    if (!model) throw new Error(Translate('error.model-needed-not-found'));

    const modelIndexInExclusivePricing = sublist.exclusive_model_pricing.findIndex(currentModel => currentModel.id === model.id); // find the same model inside exclusive pricins within sublist
    const shouldUseExclusiveModelPricing = sublist.use_exclusive_model_pricing && modelIndexInExclusivePricing > -1;

    const genderSizePrices = shouldUseExclusiveModelPricing
      ? sublist.exclusive_model_pricing[modelIndexInExclusivePricing].informations[order.gender]
      : model.informations[order.gender];

    const targetSizeIndex = currentClothe.sizeIndex;

    if (targetSizeIndex === -1) return accumulator;

    const selectedClothingPrice = genderSizePrices[targetSizeIndex].price;
    const totalFinishingOptionItemsPriceForModel = calculateTotalFinishingOptionItemsPriceForModel(sublist, model.id);
    const totalFinishingOptionItemsPriceForModelQuantity = totalFinishingOptionItemsPriceForModel * currentClothe.quantity;
    const totalPrice = currentClothe.quantity * selectedClothingPrice + totalFinishingOptionItemsPriceForModelQuantity;

    return accumulator + totalPrice;
  }, 0);

  return totalOrderPrice;
};

export const calculateOrderWeight = (order: Order, importedModels: ClothingModelType[], Translate: TFunction): number => {
  const totalOrderWeight = order.clothes.reduce((accumulator, currentClothe) => {
    const model = importedModels.find(model => model.id === currentClothe.modelId);
    if (!model) throw new Error(Translate('error.model-needed-not-found'));

    const genderSizeInfos = model.informations[order.gender];
    const targetSizeIndex = currentClothe.sizeIndex;

    if (targetSizeIndex === -1) return accumulator;

    const clothingWeight = genderSizeInfos[targetSizeIndex].weight;
    const totalWeight = currentClothe.quantity * clothingWeight;

    return accumulator + totalWeight;
  }, 0);

  return totalOrderWeight;
};

export const calculateOrderProductionTimeInSeconds = (order: Order, importedModels: ClothingModelType[], Translate: TFunction): number => {
  const totalOrderProductionTimeInSeconds = order.clothes.reduce((accumulator, currentClothe) => {
    const model = importedModels.find(model => model.id === currentClothe.modelId);
    if (!model) throw new Error(Translate('error.model-needed-not-found'));

    const genderSizeInfos = model.informations[order.gender];
    const targetSizeIndex = currentClothe.sizeIndex;
    const clothingInformations = genderSizeInfos[targetSizeIndex];

    if (targetSizeIndex === -1) return accumulator;

    const clothingProductionTimeInMinutes = clothingInformations.productionTimeInMinutes ?? '';
    const timeInSeconds = convertMMTimeToSeconds(clothingProductionTimeInMinutes);
    const totalTimeInSeconds = currentClothe.quantity * timeInSeconds;

    return accumulator + totalTimeInSeconds;
  }, 0);

  return totalOrderProductionTimeInSeconds;
};

export const calculateOrderLengthInMeters = (order: Order, importedModels: ClothingModelType[], Translate: TFunction): number => {
  const totalOrderProductionTimeInSeconds = order.clothes.reduce((accumulator, currentClothe) => {
    const model = importedModels.find(model => model.id === currentClothe.modelId);
    if (!model) throw new Error(Translate('error.model-needed-not-found'));

    const genderSizeInfos = model.informations[order.gender];
    const targetSizeIndex = currentClothe.sizeIndex;
    const clothingInformations = genderSizeInfos[targetSizeIndex];

    if (targetSizeIndex === -1) return accumulator;

    const clothingLengthInMeters = clothingInformations.lengthInMeters ?? 0;
    const totalLengthInMeters = currentClothe.quantity * clothingLengthInMeters;

    return accumulator + totalLengthInMeters;
  }, 0);

  return totalOrderProductionTimeInSeconds;
};

type MoveSelectedOrdersToSublistType = {
  sublistUUID: string;
  sublists: SublistType[];
  selectedOrders: Order[];
}

export const moveSelectedOrdersToSublist = ({ selectedOrders, sublistUUID, sublists }: MoveSelectedOrdersToSublistType): SublistType[] => {
  const updatedOrdersWithNewSublistId = selectedOrders.map<Order>(selectedOrder => ({ ...selectedOrder, sublistUUID }));

  /** Move selected orders to desired sublist */
  const movedOrders = sublists.map<SublistType>(sublist => {
    if (sublist.uuid === sublistUUID) {
      return {
        ...sublist,
        orders: [
          ...sublist.orders,
          ...updatedOrdersWithNewSublistId
        ]
      };
    }

    return sublist;
  });

  /** Remove selected orders from its original sublists */
  const updatedSublists = movedOrders.map<SublistType>(sublist => {
    const updatedOrders = sublist.orders.filter(order => !selectedOrders.includes(order));
    return { ...sublist, orders: updatedOrders };
  });

  return updatedSublists;
};
