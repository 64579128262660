import { type ClothingProductionData, type Order } from '../../../../types/OrderTypes';
import { type SublistType } from '../../../../types/SublistTypes';
import { type TFunction } from 'i18next';

export type ChangeClothingDataType = {
  value: number;
  field: keyof ClothingProductionData;
  clothingIndex: number;
}

export const changeClothingData = ({ value, field, clothingIndex }: ChangeClothingDataType, order: Order): Order => {
  const updatedClothingData = order.clothes.map<ClothingProductionData>((productionData, index) => {
    if (index === clothingIndex) {
      return {
        ...productionData,
        quantity: productionData.quantity === 0 ? 1 : productionData.quantity,
        [field]: value
      };
    }

    return productionData;
  });

  const changedOrder = {
    ...order,
    clothes: updatedClothingData
  };

  return changedOrder;
};

export const clearClothingData = (clothingIndex: number, order: Order): Order => {
  const updatedClothingData = order.clothes.map<ClothingProductionData>((productionData, index) => {
    if (index === clothingIndex) {
      return {
        ...productionData,
        sizeIndex: -1,
        quantity: 0
      };
    }

    return productionData;
  });

  const changedOrder = {
    ...order,
    clothes: updatedClothingData
  };

  return changedOrder;
};

const validateClothingQuantities = (order: Order, Translate: TFunction) => {
  const clothingAmmout = order.clothes.reduce((accumulator, productionData) => {
    return accumulator + productionData.quantity;
  }, 0);

  if (clothingAmmout === 0) throw new Error(Translate('error.order-missing-clothing-quantity'));
};

const validateClothingSizesContainingValidQuantity = (order: Order, Translate: TFunction) => {
  let clothesMissingSelectSize = 0;

  for (let i = 0; i < order.clothes.length; i++) {
    if (order.clothes[i].sizeIndex === -1 && order.clothes[i].quantity > 0) {
      clothesMissingSelectSize++;
    }
  }

  if (clothesMissingSelectSize > 0) throw new Error(Translate('error.order-missing-clothing-sizes'));
};

export const validateOrderBeforeAdd = (order: Order, Translate: TFunction): void => {
  validateClothingSizesContainingValidQuantity(order, Translate);
  validateClothingQuantities(order, Translate);
};

export const addOrder = (sublists: SublistType[], targetSublistIndex: number, order: Order): SublistType[] => {
  return sublists.map((sublist, index) => {
    if (index === targetSublistIndex) {
      return {
        ...sublist,
        orders: [
          ...sublist.orders,
          order
        ]
      };
    }

    return sublist;
  });
};

export const addOrders = (sublists: SublistType[], targetSublistIndex: number, orders: Order[]): SublistType[] => {
  return sublists.map((sublist, index) => {
    if (index === targetSublistIndex) {
      return {
        ...sublist,
        orders: [
          ...sublist.orders,
          ...orders
        ]
      };
    }

    return sublist;
  });
};

type OrderWithParentSublistType = {
  order: Order;
  sublists: SublistType[];
}

export const editOrder = ({ order, sublists }: OrderWithParentSublistType): SublistType[] => {
  const sublistIndex = sublists.findIndex(sublist => sublist.uuid === order.sublistUUID);

  const updatedOrders = sublists[sublistIndex].orders.map(currentOrder => {
    if (currentOrder.id === order.id) return order;
    return currentOrder;
  });

  const updatedSublists = sublists.map<SublistType>(sublist => {
    if (sublist.uuid === order.sublistUUID) {
      return {
        ...sublist,
        orders: updatedOrders
      };
    }

    return sublist;
  });

  return updatedSublists;
};

export const deleteOrder = ({ order, sublists }: OrderWithParentSublistType): SublistType[] => {
  const sublistIndex = sublists.findIndex(sublist => sublist.uuid === order.sublistUUID);
  const updatedOrders = sublists[sublistIndex].orders.filter(currentOrder => currentOrder.id !== order.id);

  const updatedSublists = sublists.map(sublist => {
    if (sublist.uuid === order.sublistUUID) {
      return {
        ...sublist,
        orders: updatedOrders
      };
    }

    return sublist;
  });

  return updatedSublists;
};
