import { Link } from 'react-router-dom';
import useRouteHelpers from '../../../utils/hooks/useRouteHelpers';
import { Container } from './style';

type BasicMenuProps = {
  title: string;
  route: string;
  icon: string;
};

type BasicMenuOptionsType = {
  options: BasicMenuProps[];
}

export default function BasicMenu({ options }: BasicMenuOptionsType) {
  const routeHelpers = useRouteHelpers();

  return (
    <Container>
      {
        options.map(({ route, title, icon }, index) => (
          <li className={`nav-item ${routeHelpers.isActiveFirstRouteSection(route)}`} key={index}>
            <Link className='nav-link' to={route}>
              <i className={`fas fa-fw ${icon}`}></i>
              <span>{title}</span>
            </Link>

            <hr />
          </li>
        ))
      }
    </Container>
  );
}
