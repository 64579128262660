import React, { useCallback, useMemo } from 'react';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { type ProjectType } from '../../../../../../types/ProjectType';
import { toast } from 'react-hot-toast-promise';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import ProjectSharedLinkInfo from './ProjectSharedLinkInfo';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import MiniCard from '../../../../../../components/Cards/MiniCard';
import { displayAsCurrency } from '../../../../../../utils/helper';
import { type ProjectReportType } from '../../../../../../types/ProjectReportType';
import { calculateTotalAmmountPaymentsReceived } from '../PaymentReceipts/services/paymentCalculatorService';

type ProjectHeaderInfoType = {
  project: ProjectType;
  preset: string;
  presetCount: number;
  hideButtons: boolean;
  toolsDropdownButton: JSX.Element;
  projectReport: ProjectReportType;
  currencySymbol: string;
  onClickEditButton: () => void;
  onClickSharedLinkButton: () => void;
  onClickPaymentReceiptsButton: () => void;
};

export default function ProjectHeaderInfo({
  project,
  preset,
  presetCount,
  hideButtons,
  toolsDropdownButton,
  projectReport,
  currencySymbol,
  onClickEditButton,
  onClickSharedLinkButton,
  onClickPaymentReceiptsButton
}: ProjectHeaderInfoType) {
  const { Translate } = useAppTranslation();

  const startWhatsAppChat = useCallback(() => {
    if (project.client_whatsapp) window.open(`https://wa.me/${project.client_whatsapp}`);
    else toast.error(Translate('error.project-missing-client-whatsapp'));
  }, [Translate, project.client_whatsapp]);

  const totalAmmountPaymentsReceived = useMemo(() => {
    return calculateTotalAmmountPaymentsReceived(project.payments);
  }, [project.payments]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xs-12 col-md-6">

          <div className="row">
            <div className="col">
              <h1 className='h3 mb-2 text-gray-800 mr-2' data-testid='project-header-main-title'>
                {Translate('labels.production-order-abbrev')} {project.order_number} ● {Translate('labels.project')}: {project.name}
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col d-flex">
              {!hideButtons && (
                <section>
                  <SplitButton color='primary' icon={IconsCatalog.pen} handleClick={onClickEditButton} size='sm' testId='project-edit-name-header-button' />

                  <SplitButton
                    testId='project-header-button-shared-link'
                    color='primary'
                    icon={IconsCatalog.link}
                    handleClick={onClickSharedLinkButton}
                    size='sm'
                    marginLeft
                  />

                  {project.client_whatsapp && (
                    <SplitButton
                      testId='project-header-button-open-whatsapp'
                      color='success'
                      icon={IconsCatalog.whatsapp}
                      handleClick={startWhatsAppChat}
                      size='sm'
                      marginLeft
                    />
                  )}
                </section>
              )}

              <section>
                <SplitButton
                  color='primary'
                  icon={IconsCatalog.dollar}
                  handleClick={onClickPaymentReceiptsButton}
                  size='sm'
                  marginLeft
                />
              </section>

              {toolsDropdownButton}
            </div>
          </div>

          <p className='mb-0'>
            <span>
              {Translate('labels.preset-used-is')} <b>{preset}</b> {Translate('labels.and-contains')} {presetCount}&nbsp;
            </span>
            <span className='text-lowercase'>{Translate('labels.models')}</span>.
          </p>

          <ProjectSharedLinkInfo project={project} />
        </div>

        <div className="col-xs-12 col-md-6">
          <div className="row justify-content-end">
            <MiniCard icon={IconsCatalog.dollar} color='success' title={Translate('labels.received')} text={displayAsCurrency(totalAmmountPaymentsReceived, currencySymbol)} />
            <MiniCard icon={IconsCatalog.dollar} color='warning' title={Translate('labels.to-receive')} text={displayAsCurrency(projectReport.totalProjectPriceWithDiscount - totalAmmountPaymentsReceived, currencySymbol)} />
            <MiniCard icon={IconsCatalog.dollar} color='primary' title={Translate('labels.full-total-pieces-count')} text={projectReport.totalProjectClothes.toString()} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
