import React, { useMemo } from 'react';
import { ContextMenuContainer } from './styles';
import { useContextMenu } from '../../contexts/ContextMenuContext';
import { useAppTranslation } from '../../contexts/TranslationContext';

export type CoordsType = {
  x: number;
  y: number;
};

export type ContextMenuItemType = {
  name: string;
  icon?: string;
  handleClick: (selectedOption: object) => void;
};

type ContextMenuViewerType = {
  options: ContextMenuItemType[];
  disabled?: boolean;
};

export default function ContextMenuViewer({ options, disabled = false }: ContextMenuViewerType) {
  const { isVisible, coords, selectedItem } = useContextMenu();
  const isVisibleClassName = useMemo(() => (isVisible ? 'show' : ''), [isVisible]);

  const { Translate } = useAppTranslation();

  return (
    <React.Fragment>
      <ContextMenuContainer x={coords.x} y={coords.y} id='context-menu-container'>
        <div className={`dropdown-menu shadow animated--grow-in ${isVisibleClassName}`}>
          {!disabled &&
            options.map((option, index) => (
              <button
                key={index}
                className='dropdown-item'
                onClick={() => {
                  option.handleClick(selectedItem);
                }}
              >
                {option.icon && <i className={`${option.icon} fa-sm fa-fw mr-2 text-gray-400`} />}
                {option.name}
              </button>
            ))}

          {disabled && <span className='dropdown-item disabled'>{Translate('status.unavailable-at-now')}</span>}
        </div>
      </ContextMenuContainer>
    </React.Fragment>
  );
}
