import { type TFunction } from 'i18next';
import { type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type HandleSortOrderParams } from '../../../../../types/HandleSortOrderParams';
import { type SortableOrderField } from '../../../../../types/OrderTypes';
import { type SublistType } from '../../../../../types/SublistTypes';
import { calculateOrderPrice } from './orderFormService';

/**
 * Takes an array of sublists and find the element with same id passed in `updatedSublistItem`, them return updated data to it.
 * @param sublists Array with sublists
 * @param updatedSublistItem The sublist item with updated data to find inside sublists.
 * @returns Updated sublists array to send to state.
 */
export const refreshSublistsWithItem = (sublists: SublistType[], updatedSublistItem: SublistType): SublistType[] => {
  const updatedSublists = sublists.map(sublist => {
    if (sublist.uuid === updatedSublistItem.uuid) return updatedSublistItem;
    return sublist;
  });

  return updatedSublists;
};

export type SortingParamsType = {
  sublist: SublistType;
  sortType: SortableOrderField['lastSortingType'];
}

export const runSortOrder = ({ field, sublists, sublist, importedModels, forceSortingType, Translate }: HandleSortOrderParams): SublistType[] => {
  const { lastSortingType } = sublist.sorting ?? { field, lastSortingType: forceSortingType ?? 'asc' };
  const lastSortedField = sublist.sorting?.field;

  let sortingParams: SortingParamsType = { sortType: lastSortingType, sublist };

  // If the sorting column has changed, reset type to asc
  if (field !== lastSortedField) {
    sortingParams = { ...sortingParams, sortType: 'asc' };
  }

  if (field === 'name') {
    const sortedSublist = sortOrdersByName(sortingParams);
    const updatedSublists = refreshSublistsWithItem(sublists, sortedSublist);
    return updatedSublists;
  }

  if (field === 'nickname') {
    const sortedSublist = sortOrdersByNickname(sortingParams);
    const updatedSublists = refreshSublistsWithItem(sublists, sortedSublist);
    return updatedSublists;
  }

  if (field === 'number') {
    const sortedSublist = sortOrdersByNumber(sortingParams);
    const updatedSublists = refreshSublistsWithItem(sublists, sortedSublist);
    return updatedSublists;
  }

  if (field === 'totalValue') {
    const sortedSublist = sortOrdersByTotalValue(sortingParams, importedModels, Translate);
    const updatedSublists = refreshSublistsWithItem(sublists, sortedSublist);
    return updatedSublists;
  }

  // check if the field follows the pattern to sort by clothe (clothe-0, clothe-1, clothe-2 etc)
  const shouldSortByClothe = /^clothe-[0-9]$/.test(field.toString());

  if (shouldSortByClothe) {
    const customSortingParams = { ...sortingParams, sortType: forceSortingType ?? sortingParams.sortType };
    const extractedIndex = parseInt(field.toString().split('-')[1]);
    const sortedSublist = sortOrdersByClothingSize(customSortingParams, extractedIndex);
    const updatedSublists = refreshSublistsWithItem(sublists, sortedSublist);
    return updatedSublists;
  }

  return sublists;
};

export const sortOrdersByName = ({ sublist, sortType }: SortingParamsType): SublistType => {
  const sortedOrders = sublist.orders.sort((orderA, orderB) => {
    const nameA = orderA.name?.toLowerCase() ?? '';
    const nameB = orderB.name?.toLowerCase() ?? '';

    return sortType === 'asc'
      ? nameA.localeCompare(nameB)
      : nameB.localeCompare(nameA);
  });

  return {
    ...sublist,
    orders: sortedOrders,
    sorting: {
      field: 'name',
      lastSortingType: sortType === 'asc' ? 'desc' : 'asc'
    }
  };
};

export const sortOrdersByNickname = ({ sublist, sortType }: SortingParamsType): SublistType => {
  const sortedOrders = sublist.orders.sort((orderA, orderB) => {
    const nicknameA = orderA.nickname?.toLowerCase();
    const nicknameB = orderB.nickname?.toLowerCase();

    return sortType === 'asc'
      ? nicknameA?.localeCompare(nicknameB)
      : nicknameB?.localeCompare(nicknameA);
  });

  return {
    ...sublist,
    orders: sortedOrders,
    sorting: {
      field: 'nickname',
      lastSortingType: sortType === 'asc' ? 'desc' : 'asc'
    }
  };
};

export const sortOrdersByNumber = ({ sublist, sortType }: SortingParamsType): SublistType => {
  const sortedOrders = sublist.orders.sort((orderA, orderB) => {
    // Extract the order numbers from the strings
    const orderNumberA = parseInt(orderA.number);
    const orderNumberB = parseInt(orderB.number);

    // Check if the parsed values are not NaN (indicating valid numbers)
    if (!isNaN(orderNumberA) && !isNaN(orderNumberB)) {
      return sortType === 'asc'
        ? orderNumberA - orderNumberB
        : orderNumberB - orderNumberA;
    }

    // If parsing fails, use localeCompare for string comparison
    return sortType === 'asc'
      ? orderA.number?.localeCompare(orderB.number)
      : orderB.number?.localeCompare(orderA.number);
  });

  return {
    ...sublist,
    orders: sortedOrders,
    sorting: {
      field: 'number',
      lastSortingType: sortType === 'asc' ? 'desc' : 'asc'
    }
  };
};

export const sortOrdersByTotalValue = ({ sublist, sortType }: SortingParamsType, importedModels: ClothingModelType[], Translate: TFunction): SublistType => {
  const sortedOrders = sublist.orders.sort((orderA, orderB) => {
    const totalValueOrderA = calculateOrderPrice({ sublist, order: orderA, importedModels, Translate });
    const totalValueOrderB = calculateOrderPrice({ sublist, order: orderB, importedModels, Translate });

    return sortType === 'asc'
      ? totalValueOrderA - totalValueOrderB
      : totalValueOrderB - totalValueOrderA;
  });

  return {
    ...sublist,
    orders: sortedOrders,
    sorting: {
      field: 'totalValue',
      lastSortingType: sortType === 'asc' ? 'desc' : 'asc'
    }
  };
};

export const sortOrdersByClothingSize = ({ sublist, sortType }: SortingParamsType, clotheIndex: number): SublistType => {
  const sortedOrders = sublist.orders.sort((a, b) => {
    // Compare by gender
    const genderOrder = { male: 0, female: 1, childish: 2 };
    const genderComparison = genderOrder[a.gender] - genderOrder[b.gender];
    if (genderComparison !== 0) {
      return sortType === 'asc' ? genderComparison : -genderComparison;
    }

    // If genders are the same, compare clothes by sizeIndex for the specified clotheIndex
    const clothesComparison = a.clothes[clotheIndex].sizeIndex - b.clothes[clotheIndex].sizeIndex;
    return sortType === 'asc' ? clothesComparison : -clothesComparison;
  });

  // Created the identifier for the last clothe used to sort (clothe-0, clothe-1, clothe-2 etc)
  const dynamicSortedFieldIdentifier = `clothe-${clotheIndex}` as SortableOrderField['field'];

  return {
    ...sublist,
    orders: sortedOrders,
    sorting: {
      field: dynamicSortedFieldIdentifier,
      lastSortingType: sortType === 'asc' ? 'desc' : 'asc'
    }
  };
};
