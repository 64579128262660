import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { STORAGE_DARK_MODE } from '../utils/storageItems';

type ITheme = {
  isDark: boolean;
  toggleTheme: () => void;
  adaptToScreenshot: () => void;
  removeScreenshotAdaptations: () => void;
};

type ThemeProps = {
  children: JSX.Element;
};

export const ThemeContext = createContext<ITheme>({} as ITheme);

export function ThemeProvider({ children }: ThemeProps) {
  const [darkMode, setDarkMode] = useState(() => {
    const stored = localStorage.getItem(STORAGE_DARK_MODE);

    if (!stored) {
      localStorage.setItem(STORAGE_DARK_MODE, JSON.stringify(false));
      return false;
    }

    const isDark = JSON.parse(stored);
    document.querySelector('body')?.setAttribute('data-theme', isDark ? 'dark' : 'light');

    return isDark;
  });

  const toggleTheme = useCallback(() => {
    document.querySelector('body')?.setAttribute('data-theme', darkMode ? 'light' : 'dark');
    setDarkMode(!darkMode);
  }, [darkMode]);

  const adaptToScreenshot = useCallback(() => {
    document.querySelector('body')?.setAttribute('data-screenshot', 'enabled');
  }, []);

  const removeScreenshotAdaptations = useCallback(() => {
    document.querySelector('body')?.setAttribute('data-screenshot', '');
  }, []);

  const contextValues = useMemo<ITheme>(
    () => ({
      isDark: darkMode,
      toggleTheme,
      adaptToScreenshot,
      removeScreenshotAdaptations
    }),
    [darkMode, toggleTheme]
  );

  useEffect(() => {
    localStorage.setItem(STORAGE_DARK_MODE, JSON.stringify(darkMode));
  }, [darkMode]);

  return <ThemeContext.Provider value={contextValues}>{children}</ThemeContext.Provider>;
}

export function useTheme(): ITheme {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}
