import React, { type SetStateAction, useMemo, useState, useCallback } from 'react';
import { ContextMenuProvider } from '../../../../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../../../components/ContextMenuViewer';
import { type SortableOrderField, type Order } from '../../../../../../types/OrderTypes';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { type SublistType } from '../../../../../../types/SublistTypes';
import FooterDetailsForPrintScreen from '../FooterDetailsForPrintScreen';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { toast } from 'react-hot-toast-promise';
import { type ProjectReportType } from '../../../../../../types/ProjectReportType';
import { deleteOrder } from '../../../services/orderService';
import { type UserPreferences } from '../../../../../../types/UserPreferences';
import { runSortOrder } from '../../services/orderSortingService';
import { type HandleSortOrderParams } from '../../../../../../types/HandleSortOrderParams';
import StickyMenuBar from '../../../../../../components/Menu/StickyMenuBar';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import { type SortByClothingSizeParams } from '../../../../../../types/SortByClothingSizeParams';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationModal';
import SublistPickerModal from '../../../../../../components/Modals/SublistPickerModal';
import { deleteMultipleOrders, moveSelectedOrdersToSublist } from '../../services/orderFormService';
import CollapsableCard from '../../../../../../components/Cards/CollapsableCard';
import { type FinalClientEditorSettings } from '../../../../../../types/FinalClientEditor/FinalClientEditorSettings';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { copyToClipboard } from '../../../../../../utils/helper';
import { type SublistCollapseControlType } from '../../../../../../types/SublistCollapseControlType';
import { generateSublistCsvFullUrl, generateSublistJsonFullUrl } from '../../../services/sublistService';
import Tabs from '../../../../../../components/Tabs';
import TableOrderEditor from './components/TableOrderEditor';
import ExclusivePricingSublistEditor, { type ModelPricingInformation } from './components/ExclusivePricingSublistEditor';
import { type UserType } from '../../../../../../types/UserType';
import SublistFinishingOptionsViewer from '../../../ProjectReport/components/SublistFinishingOptionsViewer';
import { WrapperList } from './style';
import { type KanbanCard } from '../../../../../../types/Kanban/KanbanCard';
import { DismemberInCard } from './components/DismemberInCard';

export type TableOrderManagerType = {
  user?: UserType;
  setOrder: React.Dispatch<SetStateAction<Order>>;
  isEditingOrder: boolean;
  sublists: SublistType[];
  setSublists: React.Dispatch<SetStateAction<SublistType[]>>;
  importedModels: ClothingModelType[];
  projectReport?: ProjectReportType;
  preferences?: UserPreferences;
  screenshotMode: boolean;
  isCompanyEditor: boolean;
  editorSettings?: FinalClientEditorSettings;
  isSynching: boolean;
  sublistsContainerRef: React.RefObject<HTMLDivElement>;
  hideExclusiveModelPricingTab?: boolean;
  onSublistCollapseChange?: (sublistCollapseEvent: SublistCollapseControlType) => void;
  card?: KanbanCard;
  startWithClosedPanel?: boolean;
};

export default function TableOrderManager({
  user,
  setOrder,
  isEditingOrder,
  sublists,
  setSublists,
  sublistsContainerRef,
  importedModels,
  projectReport,
  preferences,
  screenshotMode,
  isCompanyEditor,
  isSynching,
  editorSettings,
  hideExclusiveModelPricingTab,
  onSublistCollapseChange = () => { },
  card,
  startWithClosedPanel
}: TableOrderManagerType) {
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [isMovingMode, setIsMovingMode] = useState(false);
  const [showConfirmDeleteSelectedOrders, setShowConfirmDeleteSelectedOrders] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState<Order | null>(null);

  const { Translate } = useAppTranslation();

  const handleCopySublistUrl = useCallback(
    async (sublist: SublistType) => {
      try {
        if (!sublist.token || !sublist.project_id) return;

        const url = generateSublistJsonFullUrl(sublist.project_id, sublist.token);
        await copyToClipboard(url);
        toast.success(Translate('toast.link-copied'));
      } catch (err) {
        toast.error(Translate('error.failed-to-copy-link'));
      }
    },
    [Translate]
  );

  const handleCopySublistUrlCSV = useCallback(
    async (sublist: SublistType) => {
      try {
        if (!sublist.token || !sublist.project_id) return;

        const url = generateSublistCsvFullUrl(sublist.project_id, sublist.token);
        await copyToClipboard(url);
        toast.success(Translate('toast.link-copied'));
      } catch (err) {
        toast.error(Translate('error.failed-to-copy-link'));
      }
    },
    [Translate]
  );

  const handleExitSelectionMode = useCallback(() => {
    setSelectionMode(false);
    setSelectedOrders([]);
    toast.error(Translate('toast.selection-mode-disabled'));
  }, [Translate]);

  const handleSortByClothingSize = useCallback(
    ({ clotheIndex, sublist, forceSortingType }: SortByClothingSizeParams) => {
      const clotheSortingKey = `clothe-${clotheIndex}` as SortableOrderField['field'];

      const updatedSublists = runSortOrder({
        field: clotheSortingKey,
        sublists,
        sublist,
        importedModels,
        forceSortingType,
        Translate
      });

      setSublists(updatedSublists);
    },
    [sublists, importedModels, Translate, setSublists]
  );

  const handleSortOrder = useCallback(
    ({ field, sublists, sublist, importedModels, forceSortingType }: HandleSortOrderParams) => {
      if (editorSettings?.final_client_readonly) return;

      const updatedSublists = runSortOrder({
        field,
        sublists,
        sublist,
        importedModels,
        forceSortingType,
        Translate
      });

      setSublists(updatedSublists);
    },
    [editorSettings?.final_client_readonly, Translate, setSublists]
  );

  const handleDeleteMultipleOrders = useCallback(() => {
    const updatedSublists = deleteMultipleOrders({ sublists, selectedOrders });
    setSublists(updatedSublists);
    setSelectionMode(false);
    setShowConfirmDeleteSelectedOrders(false);
    toast.success(Translate('toast.selected-items-removed'));
  }, [Translate, selectedOrders, setSublists, sublists]);

  const handleMoveSelectedOrdersToSublist = useCallback(
    (sublistUUID: string) => {
      const updatedSublists = moveSelectedOrdersToSublist({ selectedOrders, sublistUUID, sublists });
      setSublists(updatedSublists);
      setSelectedOrders([]);
      setIsMovingMode(false);
      toast.success(Translate('toast.item-moved'));
    },
    [selectedOrders, sublists, setSublists, Translate]
  );

  const handleCancelMoveOrders = useCallback(() => {
    setIsMovingMode(false);
    setSelectedOrders([]);
  }, []);

  const handlePrepareOrderEdit = useCallback(
    (orderToEdit: Order) => {
      setOrder(orderToEdit);
    },
    [setOrder]
  );

  const handleDeleteOrder = useCallback(() => {
    if (orderToDelete === null) return;

    const updatedSublists = deleteOrder({ order: orderToDelete, sublists });
    setSublists(updatedSublists);
    setOrderToDelete(null);
  }, [orderToDelete, sublists, setSublists]);

  const handleUpdateCachedSublistModelPricingInformations = useCallback(
    (
      sublistToUpdate: SublistType,
      updatedModelPricingInformations: ModelPricingInformation[],
      useExclusiveModelPricing: boolean
    ) => {
      const updatedSublists = sublists.map(sublist => {
        if (sublist.id === sublistToUpdate.id) {
          return {
            ...sublist,
            exclusive_model_pricing: updatedModelPricingInformations,
            use_exclusive_model_pricing: useExclusiveModelPricing
          };
        }
        return sublist;
      });

      setSublists(updatedSublists);
    },
    [setSublists, sublists]
  );

  const screenshotStyleSheet = useMemo<React.CSSProperties>(
    () => ({ backgroundColor: '#fff', position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000, padding: '25px' }),
    []
  );

  const contextMenuOptionsForOrder = useMemo(
    (): ContextMenuItemType[] => [
      {
        name: Translate('actions.edit'),
        icon: IconsCatalog.pen,
        handleClick: selectedOrder => {
          handlePrepareOrderEdit(selectedOrder as Order);
        }
      },
      {
        name: Translate('actions.move'),
        icon: IconsCatalog.move,
        handleClick: selectedOrder => {
          setSelectedOrders([selectedOrder as Order]);
          setIsMovingMode(true);
        }
      },
      {
        name: Translate('actions.delete'),
        icon: IconsCatalog.trash,
        handleClick: selectedOrder => {
          setOrderToDelete(selectedOrder as Order);
        }
      },
      {
        name: Translate('actions.selection-mode'),
        icon: IconsCatalog.checkSquare,
        handleClick: () => {
          setSelectionMode(true);
        }
      }
    ],
    [Translate, handlePrepareOrderEdit]
  );

  return (
    <React.Fragment>
      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.confirm-remove-order')}
        style='danger'
        visible={orderToDelete !== null}
        handleClose={() => {
          setOrderToDelete(null);
        }}
        handleConfirm={handleDeleteOrder}
      />

      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.confirm-remove-selected-orders')}
        style='danger'
        visible={showConfirmDeleteSelectedOrders}
        handleClose={() => {
          setShowConfirmDeleteSelectedOrders(false);
        }}
        handleConfirm={handleDeleteMultipleOrders}
      />

      <SublistPickerModal
        title={Translate('actions.move')}
        message={Translate('description.select-destination-sublist-for-selected-orders')}
        style='primary'
        visible={isMovingMode}
        sublists={sublists}
        handleClose={handleCancelMoveOrders}
        handleConfirm={sublistId => {
          handleMoveSelectedOrdersToSublist(sublistId);
        }}
      />

      {selectionMode && importedModels.length > 0 && (
        <StickyMenuBar countSelectedItems={selectedOrders.length}>
          <SplitButton
            size='sm'
            icon={IconsCatalog.times}
            title={Translate('actions.cancel')}
            color='light'
            handleClick={handleExitSelectionMode}
          />

          <SplitButton
            size='sm'
            icon={IconsCatalog.move}
            title={Translate('actions.move-selected')}
            color='primary'
            marginLeft
            handleClick={() => {
              setIsMovingMode(true);
            }}
          />

          <SplitButton
            size='sm'
            icon={IconsCatalog.trash}
            title={Translate('actions.delete-selected')}
            color='danger'
            marginLeft
            handleClick={() => {
              setShowConfirmDeleteSelectedOrders(true);
            }}
          />
        </StickyMenuBar>
      )}

      <ContextMenuProvider>
        <ContextMenuViewer
          options={contextMenuOptionsForOrder}
          disabled={selectionMode || isEditingOrder || editorSettings?.final_client_readonly}
        />

        <div ref={sublistsContainerRef} style={screenshotMode ? screenshotStyleSheet : {}}>
          {sublists.map((sublist, sublistIndex) => (
            <CollapsableCard
              startWithClosedPanel={startWithClosedPanel}
              key={sublistIndex}
              header={`${sublist.title} (${projectReport?.sublists[sublistIndex]?.totalSublistClothes ?? 0})`}
              id={`sublist-${sublistIndex}`}
              onCollapseChange={collapsed => {
                onSublistCollapseChange({ id: sublist.id, title: sublist.title, collapsed });
              }}
            >
              {!!sublist.token && !screenshotMode && (
                <div className='row mb-3'>
                  <div className='col'>
                    <SplitButton
                      size='sm'
                      color='primary'
                      title={Translate('actions.copy-json-link')}
                      icon={IconsCatalog.copy}
                      handleClick={() => {
                        handleCopySublistUrl(sublist);
                      }}
                    />
                    <SplitButton
                      size='sm'
                      color='primary'
                      marginLeft
                      title={Translate('modal.csv-link')}
                      icon={IconsCatalog.copy}
                      handleClick={() => {
                        handleCopySublistUrlCSV(sublist);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='table-responsive' key={sublist.uuid}>
                {!screenshotMode && isCompanyEditor && (
                  <WrapperList className='mb-3'>
                    <small className='d-block p-1 border border-left-warning w-100'>
                      {Translate('description.order-left-yellow-border-meaning')}
                    </small>

                    {card ? <DismemberInCard card={card} sublist={sublist} /> : null}
                  </WrapperList>
                )}

                <Tabs
                  data={[
                    {
                      id: `tab-sublist-layout-${sublist.uuid}`,
                      label: 'Layout',
                      active: !!sublist.layout,
                      hidden: !sublist.layout,
                      icon: IconsCatalog.image,
                      content: (
                        <div className="row mx-0 px-0">
                          <div className="col text-center">
                            {sublist.layout && <img src={sublist.layout.file} className='img-fluid shadow-sm rounded my-3' />}
                          </div>
                        </div>
                      )
                    },
                    {
                      id: `tab-orders-${sublist.uuid}`,
                      label: Translate('labels.orders'),
                      active: !sublist.layout,
                      icon: IconsCatalog.list,
                      content: <TableOrderEditor
                        isSynching={isSynching}
                        sublist={sublist}
                        sublists={sublists}
                        sublistIndex={sublistIndex}
                        importedModels={importedModels}
                        screenshotMode={screenshotMode}
                        selectedOrders={selectedOrders}
                        setSelectedOrders={setSelectedOrders}
                        selectionMode={selectionMode}
                        editorSettings={editorSettings}
                        handleSortOrder={handleSortOrder}
                        handleSortByClothingSize={handleSortByClothingSize}
                        preferences={preferences}
                        projectReport={projectReport}
                      />
                    },
                    {
                      id: `tab-sublist-finishing-options-${sublist.uuid}`,
                      label: Translate('labels.finishing-options'),
                      hidden: sublist.selected_finishing_options.length === 0,
                      icon: IconsCatalog.cut,
                      content: <SublistFinishingOptionsViewer sublist={sublist} importedModels={importedModels} alignLeft />
                    },
                    {
                      id: `tab-exclusive-pricing-sublist-${sublist.uuid}`,
                      label: Translate('labels.exclusive-prices'),
                      hidden: hideExclusiveModelPricingTab,
                      icon: user?.can_use_exclusive_model_pricing === true ? IconsCatalog.dollar : IconsCatalog.lock,
                      content: <ExclusivePricingSublistEditor
                        sublistUUID={sublist.uuid}
                        isLockedToUser={!user?.can_use_exclusive_model_pricing}
                        importedModels={importedModels}
                        exclusiveModelsPricingInformations={sublist.exclusive_model_pricing}
                        usingExclusiveModelPricing={sublist.use_exclusive_model_pricing}
                        onSaveModelsPricingInformations={(updatedModelPricingInformations, useExclusiveModelPricing) => {
                          handleUpdateCachedSublistModelPricingInformations(sublist, updatedModelPricingInformations, useExclusiveModelPricing);
                        }}
                      />
                    },
                    {
                      id: `tab-sublist-3d-view-${sublist.uuid}`,
                      label: Translate('labels.3d-view'),
                      hidden: !sublist.view_3d_url,
                      icon: IconsCatalog.unity,
                      content: (
                        <iframe src={sublist.view_3d_url} className='w-100 border-none' height={700}></iframe>
                      )
                    }
                  ]}
                />
              </div>

              {screenshotMode && !!sublist.layout && (
                <div className='text-center'>
                  <img src={sublist.layout?.file} />
                </div>
              )}
            </CollapsableCard>
          ))}

          {screenshotMode && projectReport && preferences && (
            <FooterDetailsForPrintScreen
              projectTotalPrice={!preferences.screenshot_hide_prices ? projectReport.totalProjectPrice : null}
              projectTotalPriceWithDiscount={!preferences.report_hide_prices ? projectReport.totalProjectPriceWithDiscount : null}
              piecesTotalCount={projectReport.totalProjectClothes}
              preferences={preferences}
            />
          )}
        </div>
      </ContextMenuProvider>
    </React.Fragment>
  );
}
