import React, { useCallback } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';
import MainFormEditor, { type MainFormEditorType } from '../../modules/MainFormEditor';
import { generateInitialOrder } from '../../helper/orderHelper';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';

type MainFormEditorModalType = MainFormEditorType & Pick<ModalBaseType, 'visible'>;

export default function MainFormEditorModal({
  order,
  setOrder,
  sublists,
  setSublists,
  importedModels,
  targetSublistIndex,
  setTargetSublistIndex,
  renderAsStepper,
  visible
}: MainFormEditorModalType) {
  const { Translate } = useAppTranslation();

  const handleCancelEditing = useCallback(() => {
    setOrder(generateInitialOrder(importedModels));
  }, [importedModels, setOrder]);

  return (
    <ModalBase
      hideFooter
      isExtraLarge
      visible={visible}
      style='primary'
      title={Translate('actions.edit')}
      handleConfirm={() => { }}
      handleClose={handleCancelEditing}
      disableEscToClose
      disableClickOutsideToClose
    >
      <React.Fragment>
        <MainFormEditor
          order={order}
          setOrder={setOrder}
          sublists={sublists}
          setSublists={setSublists}
          importedModels={importedModels}
          targetSublistIndex={targetSublistIndex}
          setTargetSublistIndex={setTargetSublistIndex}
          renderAsStepper={renderAsStepper}
          isCompanyEditor
        />
      </React.Fragment>
    </ModalBase>

  );
};
