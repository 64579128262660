import React, { useMemo } from 'react';
import { Container } from './styles';

type ProgressBarType = {
  value: number;
  maxValue: number;
  useConstantColor?: boolean;
};

export default function ProgressBar({ value, maxValue, useConstantColor }: ProgressBarType) {
  const progress = useMemo<number>(() => (value / maxValue) * 100, [value, maxValue]);
  const selectedColor = useMemo<string>(() => {
    if (useConstantColor) return 'primary';
    if (progress <= 50) return 'success';
    if (progress > 50 && progress < 85) return 'warning';
    else return 'danger';
  }, [progress]);

  return (
    <div className='progress'>
      <Container className={`progress-bar bg-${selectedColor}`} progress={progress} />
    </div>
  );
}
