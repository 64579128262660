import { type AxiosInstance } from 'axios';

export type RemoveSublistFinishingOptionParams = {
  sublist_id: number;
  picked_finishing_option_id: number;
}

export const removeSublistFinishingOption = async (params: RemoveSublistFinishingOptionParams, httpConnection: AxiosInstance): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    httpConnection.post('/list/clothing-projects/sublist/remove-finishing-option', params)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
