import React, { useCallback, useEffect, useState } from 'react';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import BasicCard from '../../../../components/Cards/BasicCard';
import DatePickerInput from '../../../../components/Forms/DatePickerInput';
import SplitButton from '../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../components/IconsCatalog';
import { useHttpRequest } from '../../../../contexts/HttpRequestContext';
import Spinner from '../../../../components/Spinner';
import RenderSellsReportTable from './components/RenderSellsReportTable';
import ComboBox from '../../../../components/Forms/ComboBox';
import { type BasicUserIdentificationType } from '../../../../types/BasicUserIdentificationType';
import { getUsersInSameCompany } from '../../../../contexts/services/userService';
import toast from 'react-hot-toast-promise';
import { generateComboBoxOptionsFromBasicUseIdentificationList } from './services/comboBoxUserService';
import { format } from 'date-fns';
import getSellsReport from './api/getSellsReport';
import { type SellsReportType } from '../../../../types/Report/SellsReportType';
import { useSettings } from '../../../../contexts/SettingsContext';
import { displayFormattedDate } from '../../../../helpers/dateTime';
import { getServerErrorMessageFromResponse } from '../../../../utils/helper';

export default function SellsReport() {
  const { httpConnection } = useHttpRequest();
  const { Translate, dateFnsLocale } = useAppTranslation();
  const { preferences } = useSettings();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedUserId, setSelectedUserId] = useState<number>(-1);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [usersInSameCompany, setUsersInSameCompany] = useState<BasicUserIdentificationType[]>([]);
  const [sellsReport, setSellsReport] = useState<SellsReportType[]>([]);

  const [isRequesting, setIsRequesting] = useState(false);

  const handleGenerateSellsReport = useCallback(() => {
    const formattedStartDate = format(startDate, 'yyyy/MM/dd');
    const formattedEndDate = format(endDate, 'yyyy/MM/dd');

    setIsRequesting(true);

    const data = {
      selectedUserId,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    toast.promise(getSellsReport(httpConnection, data), {
      loading: Translate('progress.generating-report'),
      success: (sellsReport) => {
        setSellsReport(sellsReport);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsRequesting(false);
      }
    });
  }, [Translate, endDate, httpConnection, selectedUserId, startDate]);

  useEffect(() => {
    getUsersInSameCompany(httpConnection)
      .then(users => {
        setUsersInSameCompany(users);
      })
      .catch(err => {
        const { message } = err as Error;
        toast.error(message);
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }, [httpConnection]);

  return (
    <React.Fragment>
      <div className="row d-print-none">
        <div className="col">
          <h1 className='h3 mb-2 text-gray-800'>{Translate('labels.sells-report')}</h1>
          <p className='mb-4'>{Translate('description.sells-report-description')}</p>
        </div>
      </div>

      <BasicCard title={Translate('labels.parameteres')} hideOnPrint>
        <div className="row">
          <div className="col-2">
            <DatePickerInput
              label={Translate('labels.start-date')}
              startDate={startDate}
              handleDateChange={(selectedDate) => {
                setStartDate(selectedDate);
              }}
            />
          </div>

          <div className="col-2">
            <DatePickerInput
              label={Translate('labels.end-date')}
              startDate={endDate}
              handleDateChange={(selectedDate) => {
                setEndDate(selectedDate);
              }}
            />
          </div>

          <div className="col-2">
            {isLoadingUsers && (
              <div className='mt-4'>
                <Spinner />
              </div>
            )}

            {!isLoadingUsers && (
              <ComboBox
                id='combobox-pick-user-to-generate-report'
                header={Translate('labels.seller')}
                value={selectedUserId.toString()}
                disabled={isLoadingUsers}
                data={generateComboBoxOptionsFromBasicUseIdentificationList(usersInSameCompany, Translate)}
                handleChange={({ target }) => {
                  setSelectedUserId(parseInt(target.value));
                }}
              />
            )}
          </div>

          <div className="col-3">
            <SplitButton
              simulateLabelMarginTop
              disabled={isLoadingUsers || isRequesting}
              color='primary'
              icon={IconsCatalog.file}
              title={Translate('actions.generate-report')}
              handleClick={handleGenerateSellsReport}
            />
          </div>
        </div>
      </BasicCard>

      {isRequesting && <Spinner />}

      {
        !isRequesting && true && (
          <React.Fragment>
            <div className="row mb-3">
              <div className="col">
                <h6>{Translate('labels.projects-between-days')} {displayFormattedDate(startDate.toISOString(), dateFnsLocale)} ● {displayFormattedDate(endDate.toISOString(), dateFnsLocale)}</h6>
              </div>
              <div className="col text-right d-print-none">
                <SplitButton
                  size='sm'
                  color='primary'
                  icon={IconsCatalog.print}
                  title={Translate('actions.print')}
                  handleClick={() => {
                    window.print();
                  }}
                />
              </div>
            </div>

            <RenderSellsReportTable sellsReport={sellsReport} currency={preferences.currency} />
          </React.Fragment>
        )
      }

    </React.Fragment>
  );
};
