import React, { useMemo } from 'react';
import { type BootstrapColorStyle } from '../../../types/BootstrapColorStyle';

type MiniCardType = {
  color: BootstrapColorStyle;
  title: string;
  text: string;
  icon: string;
  onClick?: () => void;
};

export default function MiniCard({ color, title, text, icon, onClick }: MiniCardType) {
  const detectRole = useMemo(() => (!onClick ? 'none' : 'button'), [onClick]);

  return (
    <div className='col-xl-4 col-md-6 mb-4' role={detectRole} onClick={onClick}>
      <div className={`card border-left-${color} shadow h-100 py-2`}>
        <div className='card-body'>
          <div className='row no-gutters align-items-center'>
            <div className='col mr-2'>
              <div className={`text-xs font-weight-bold text-${color} text-uppercase mb-1`}>{title}</div>
              <div className='h6 mb-0 font-weight-bold text-gray-800'>{text}</div>
            </div>
            <div className='col-auto'>
              <i className={`${icon} text-gray-300`} style={{ fontSize: '25px' }}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
