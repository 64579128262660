import React, { useCallback, useMemo, useState } from 'react';
import BasicCard from '../../../components/Cards/BasicCard';
import { useNavigate } from 'react-router';
import { ContextMenuProvider } from '../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../components/ContextMenuViewer';
import CircleButton from '../../../components/Buttons/CircleButton';
import { IconsCatalog } from '../../../components/IconsCatalog';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import ColorableRow from '../../../components/Tables/ColorableRow';
import SplitButton from '../../../components/Buttons/SplitButton';
import TextInputModal from '../../../components/Modals/TextInputModal';
import { type KanbanWorkspace } from '../../../types/Kanban/KanbanWorkspace';
import { displayTimePassedSinceDate } from '../../../helpers/dateTime';
import StickyMenuBar from '../../../components/Menu/StickyMenuBar';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { useWorkspaces } from './hooks/useWorkspaces';
import _ from 'lodash';
import { useUser } from '../../../contexts/UserContext';

export default function KanbanWorkspaces() {
  const navigate = useNavigate();
  const { Translate, dateFnsLocale } = useAppTranslation();
  const { isCompanyAccount } = useUser();
  const { workspaces, createWorkspaceMutation, renameWorkspaceMutation, deleteWorkspaceMutation } = useWorkspaces();

  const [selectedWorkspaces, setSelectedWorkspaces] = useState<KanbanWorkspace[]>([]);
  const [showCreationForm, setShowCreationForm] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [workspaceToEdit, setWorkspaceToEdit] = useState<KanbanWorkspace | Record<string, unknown>>({});
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const gotoKanbanView = useCallback(
    (workspace: KanbanWorkspace) => {
      navigate('view', {
        state: { workspace }
      });
    },
    [navigate]
  );

  const handleCheckAllWorkspaces = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, workspaces: KanbanWorkspace[]) => {
      const { checked } = event.target;

      setSelectedWorkspaces(checked ? workspaces : []);
    },
    []
  );

  const handleCheckWorkspace = useCallback((event: React.ChangeEvent<HTMLInputElement>, workspace: KanbanWorkspace) => {
    const { checked } = event.target;

    setSelectedWorkspaces(prevSelected => {
      if (checked) {
        return [...prevSelected, workspace];
      }

      return prevSelected.filter(currentWorkspace => currentWorkspace.id !== workspace.id);
    });
  }, []);

  const handleExitSelectionMode = useCallback(() => {
    setSelectionMode(false);
    setSelectedWorkspaces([]);
  }, []);

  const contextMenuForWorkspaces = useMemo(
    (): ContextMenuItemType[] => [
      {
        name: Translate('actions.edit'),
        icon: IconsCatalog.pen,
        handleClick: selectedOption => {
          setWorkspaceToEdit(selectedOption as KanbanWorkspace);
        }
      },
      {
        name: Translate('actions.delete'),
        icon: IconsCatalog.trash,
        handleClick: selectedOption => {
          setSelectedWorkspaces([selectedOption as KanbanWorkspace]);
          setShowDeleteConfirmationModal(true);
        }
      },
      {
        name: Translate('actions.selection-mode'),
        icon: IconsCatalog.checkSquare,
        handleClick: () => {
          setSelectionMode(true);
        }
      }
    ],
    [Translate]
  );

  return (
    <React.Fragment>
      <TextInputModal
        style='primary'
        title='Novo Kanban'
        label='Qual o nome do novo espaço de trabalho?'
        visible={showCreationForm}
        currentValue=''
        handleClose={() => {
          setShowCreationForm(false);
        }}
        handleConfirm={(title: string) => {
          createWorkspaceMutation.mutate(title);
        }}
      />

      <TextInputModal
        style='primary'
        title='Renomear'
        label='Modifique o nome do espaço de trabalho?'
        visible={!_.isEmpty(workspaceToEdit)}
        currentValue={_.get(workspaceToEdit, 'title', '') as string}
        handleClose={() => {
          setWorkspaceToEdit({});
        }}
        handleConfirm={(title: string) => {
          renameWorkspaceMutation.mutate({ ...workspaceToEdit, title } as KanbanWorkspace);
        }}
      />

      <ConfirmationModal
        title={'Excluir espaço de trabalho?'}
        message={'Tem certeza que deseja excluir os espaços de trabalho selecionados?'}
        style='danger'
        visible={showDeleteConfirmationModal}
        handleClose={() => {
          setSelectedWorkspaces([]);
          setShowDeleteConfirmationModal(false);
        }}
        handleConfirm={() => {
          deleteWorkspaceMutation.mutate(selectedWorkspaces);
          setShowDeleteConfirmationModal(false);
          setSelectionMode(false);
        }}
      />

      <section>
        <h1 className='h3 mb-2 text-gray-800'>Kanban - Espaços de Trabalho</h1>
        <p className='mb-4'>Cada espaço de trabalho pode estar focado em projetos diferentes.</p>

        <div className='row'>
          <div className='col'>
            <section className='mb-3'>
              {isCompanyAccount ? (
                <SplitButton
                  title='Novo'
                  icon={IconsCatalog.plus}
                  color='primary'
                  handleClick={() => {
                    setShowCreationForm(true);
                  }}
                />
              ) : null}
            </section>

            {selectionMode && (
              <StickyMenuBar countSelectedItems={selectedWorkspaces.length}>
                <SplitButton
                  size='sm'
                  icon={IconsCatalog.times}
                  title={Translate('actions.cancel')}
                  color='light'
                  handleClick={handleExitSelectionMode}
                />

                <SplitButton
                  size='sm'
                  icon={IconsCatalog.trash}
                  title={Translate('actions.delete-selected')}
                  color='danger'
                  marginLeft
                  handleClick={() => {
                    setShowDeleteConfirmationModal(true);
                  }}
                />
              </StickyMenuBar>
            )}

            <BasicCard title='Espaços de Trabalho'>
              <ContextMenuProvider>
                {isCompanyAccount ? <ContextMenuViewer options={contextMenuForWorkspaces} disabled={selectionMode} /> : null}

                <table className='table table-bordered table-hover' width='100%'>
                  <thead>
                    <tr>
                      {selectionMode && (
                        <th className='text-center'>
                          <input
                            type='checkbox'
                            defaultChecked={false}
                            onChange={event => {
                              handleCheckAllWorkspaces(event, workspaces?.data ?? []);
                            }}
                          />
                        </th>
                      )}
                      <th>{Translate('labels.name')}</th>
                      <th className='text-center'>{Translate('labels.created')}</th>
                      <th className='text-center'>{Translate('labels.edited')}</th>
                      <th className='text-center th-min-w'>Abrir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(workspaces.data) ? (
                      <tr className='text-center'>
                        <td colSpan={99}>Nenhum espaço de trabalho.</td>
                      </tr>
                    ) : (
                      workspaces?.data?.map((workspace, index) => (
                        <ColorableRow key={index} data={workspace} isSelected={selectedWorkspaces.includes(workspace)}>
                          {selectionMode && (
                            <td width={50} className='text-center align-middle'>
                              <input
                                type='checkbox'
                                checked={selectedWorkspaces.includes(workspace)}
                                onChange={event => {
                                  handleCheckWorkspace(event, workspace);
                                }}
                              />
                            </td>
                          )}

                          <td className='align-middle'>{workspace.title}</td>
                          <td className='text-center align-middle'>
                            {displayTimePassedSinceDate(new Date(workspace.created_at), dateFnsLocale)}
                          </td>
                          <td className='text-center align-middle'>
                            {displayTimePassedSinceDate(new Date(workspace.updated_at), dateFnsLocale)}
                          </td>
                          <td className='text-center'>
                            <CircleButton
                              color='secondary'
                              icon={IconsCatalog.eye}
                              handleClick={() => {
                                gotoKanbanView(workspace);
                              }}
                            />
                          </td>
                        </ColorableRow>
                      ))
                    )}
                  </tbody>
                </table>
              </ContextMenuProvider>
            </BasicCard>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
