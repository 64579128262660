import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { DismemberButton, NavigateButton } from '../../style';
import { type KanbanCard } from '../../../../../../../../types/Kanban/KanbanCard';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import ModalBase from '../../../../../../../../components/Modals/ModalBase';
import { useDismemberInCard } from './useDismemberInCard';
import _ from 'lodash';
import { useWorkspaces } from '../../../../../../KanbanWorkspaces/hooks/useWorkspaces';
import { Select } from '../../../../../../../../components/CardModal/style';

type DisMemberInCardProps = {
  card: KanbanCard;
  sublist: SublistType;
};

export const DismemberInCard = ({ card, sublist }: DisMemberInCardProps) => {
  const navigate = useNavigate();
  const { workspaces } = useWorkspaces();
  const { createCardMutation } = useDismemberInCard();
  const [showCard, setShowCard] = useState<KanbanCard | null>(null);
  const [workspaceId, setWorkspaceId] = useState<string | null>(null);

  const handleVisibleSublist = useCallback((card: KanbanCard | null) => {
    setShowCard(card);
  }, []);

  const handleConfirm = useCallback(() => {
    createCardMutation.mutate({
      sublistTitle: sublist.title,
      workspaceId: workspaceId!,
      sublistId: sublist.id!
    });
  }, [createCardMutation, sublist.id, sublist.title, workspaceId]);

  const handleButton = useMemo(() => {
    if (sublist.card) {
      return (
        <NavigateButton
          type='button'
          onClick={() => {
            navigate('/kanban/workspaces/view', {
              state: {
                workspace: sublist?.card?.columns?.workspaces,
                card: sublist?.card
              }
            });
          }}
        >
          Acessar o card
        </NavigateButton>
      );
    }

    return (
      <DismemberButton
        type='button'
        disabled={createCardMutation.isLoading}
        onClick={() => {
          handleVisibleSublist(card);
        }}
      >
        Transformar em card
      </DismemberButton>
    );
  }, [card, createCardMutation.isLoading, handleVisibleSublist, navigate, sublist.card]);

  useEffect(() => {
    if (_.isEmpty(workspaces.data)) return;

    setWorkspaceId(_.first(workspaces.data)!.id.toString());
  }, [workspaces.data]);

  return (
    <>
      <ModalBase
        visible={!_.isEmpty(showCard)}
        handleConfirm={handleConfirm}
        handleClose={() => {
          handleVisibleSublist(null);
        }}
        title={`Transformar "${sublist.title}" em card`}
        style='primary'
        message='Selecione a área de trabalho que deseja adicionar o card'
      >
        <Select
          value={workspaceId?.toString()}
          onChange={e => {
            setWorkspaceId(e.target.value);
          }}
        >
          {workspaces?.data?.map(workspace => (
            <option key={workspace.id} value={workspace.id}>
              {workspace.title}
            </option>
          ))}
        </Select>
      </ModalBase>

      {handleButton}
    </>
  );
};
