import { type AxiosInstance } from 'axios';

export const saveListPastedInputContent = async (inputContent: string, httpConnection: AxiosInstance): Promise<void> => {
  await new Promise<void>((resolve) => {
    httpConnection.post('list/pasted-from-whatsapp', { user_input: inputContent })
      .finally(() => {
        resolve();
      });
  });
};
