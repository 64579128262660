import React, { type SetStateAction, useCallback, useState } from 'react';
import BasicCard from '../../../../../../components/Cards/BasicCard';
import TextInput from '../../../../../../components/Forms/TextInput';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import ComboBox from '../../../../../../components/Forms/ComboBox';
import ClothingIconViewer from '../../../../../../components/ClothingIconViewer';

import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { generateComboBoxOptionsForClothingInput, generateSublistOptions } from '../../services/orderFormService';

import { type SublistType } from '../../../../../../types/SublistTypes';
import { type ClothingGenderType, type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type SequentialListInputs } from '../../../../../../types/SequentialListInputs';

import {
  createOrdersFromSequence,
  generateDefaultListSequenceSettings,
  updateClothingProductionDataInSequenceSettings,
  validateSequenceSettings
} from './services/sequenceService';

import { addOrders } from '../../../services/orderService';
import { toast } from 'react-hot-toast-promise';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { getServerErrorMessageFromResponse } from '../../../../../../utils/helper';

type SequentialListType = {
  sublists: SublistType[];
  setSublists: React.Dispatch<SetStateAction<SublistType[]>>;
  importedModels: ClothingModelType[];
};

export default function SequentialList({ importedModels, sublists, setSublists }: SequentialListType) {
  const { Translate } = useAppTranslation();

  const [sequence, setSequence] = useState<SequentialListInputs>(generateDefaultListSequenceSettings(importedModels));

  const handleUpdateClothesSizeData = useCallback(
    (modelId: number, sizeIndex: number, clotheIndex: number) => {
      const updatedSequenceSettings = updateClothingProductionDataInSequenceSettings({
        sequence,
        modelId,
        sizeIndex,
        clotheIndex
      });

      setSequence(updatedSequenceSettings);
    },
    [sequence]
  );

  const handleClearForm = useCallback(() => {
    setSequence(generateDefaultListSequenceSettings(importedModels));
  }, [importedModels]);

  const handleProcessSequence = useCallback(() => {
    try {
      validateSequenceSettings(sequence, Translate);
      const createdOrders = createOrdersFromSequence(sequence, sublists[sequence.targetSublistIndex].uuid);
      const updatedSublists = addOrders(sublists, sequence.targetSublistIndex, createdOrders);

      setSublists(updatedSublists);
      handleClearForm();

      toast.success(Translate('toast.sequential-list-generated'));
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [sequence, Translate, sublists, setSublists, handleClearForm]);

  return (
    <BasicCard>
      <div className='row'>
        <div className='col-3'>
          <TextInput
            id='sequence-start-value'
            label={Translate('labels.start-number')}
            type='number'
            minValue={0}
            value={sequence.startValue.toString()}
            onChange={({ target }) => {
              setSequence({ ...sequence, startValue: parseInt(target.value) });
            }}
          />
        </div>

        <div className='col-3'>
          <TextInput
            id='sequence-end-value'
            label={Translate('labels.end-number')}
            type='number'
            minValue={0}
            value={sequence.endValue.toString()}
            onChange={({ target }) => {
              setSequence({ ...sequence, endValue: parseInt(target.value) });
            }}
          />
        </div>

        <div className='col-3'>
          <ComboBox
            id='sequence-gender'
            header={Translate('labels.gender')}
            value={sequence.gender.toString()}
            handleChange={({ target }) => {
              setSequence({ ...sequence, gender: target.value as keyof ClothingGenderType });
            }}
            data={[
              { label: Translate('labels.male'), value: 'male' },
              { label: Translate('labels.female'), value: 'female' },
              { label: Translate('labels.childish'), value: 'childish' }
            ]}
          />
        </div>
        <div className='col-3'>
          <ComboBox
            id='sequence-target-sublist'
            header={Translate('labels.sublists')}
            value={sequence.targetSublistIndex.toString()}
            handleChange={({ target }) => {
              setSequence({ ...sequence, targetSublistIndex: parseInt(target.value) });
            }}
            data={generateSublistOptions(sublists)}
          />
        </div>
      </div>

      <p>{Translate('description.setup-clothing-sizes')}</p>

      <div className='row'>
        {importedModels.map((model, index) => (
          <div className='col-2' key={index}>
            <ClothingIconViewer icon={model.icon.url} />
            <ComboBox
              id='combobox'
              header=''
              value={sequence.clothingProductionData[index].sizeIndex.toString()}
              handleChange={({ target }) => {
                handleUpdateClothesSizeData(model.id, parseInt(target.value), index);
              }}
              data={generateComboBoxOptionsForClothingInput(model, sequence.gender)}
            />
          </div>
        ))}
      </div>

      <hr />

      <div className='row'>
        <div className='col'>
          <SplitButton
            color='success'
            icon={IconsCatalog.play}
            title={Translate('actions.generate-list')}
            handleClick={handleProcessSequence}
          />
          <SplitButton
            marginLeft
            color='secondary'
            icon={IconsCatalog.undo}
            title={Translate('actions.undo')}
            handleClick={handleClearForm}
          />
        </div>
      </div>
    </BasicCard>
  );
}
