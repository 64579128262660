import React from 'react';
import { type BasicButtonType } from '../types/BasicButtonType';
import { BadgeLargeText } from './styles';

type BadgeButtonType = Pick<BasicButtonType, 'title' | 'color' | 'handleClick'> & {
  useLarge?: boolean;
}

export default function BadgeButton({ title, color, handleClick, useLarge }: BadgeButtonType) {
  return (
    <span className={`badge badge-${color} badge-lg`} role='button' onClick={handleClick}>
      {!useLarge && title}
      {useLarge && <BadgeLargeText>{title}</BadgeLargeText>}
    </span>
  );
};
