import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

type StyledCheckboxType = {
  checked: boolean;
}

export const StyledCheckbox = styled.div.withConfig({ shouldForwardProp: prop => prop !== 'checked' }) <StyledCheckboxType>`
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 1px solid var(--secondary);
  background-color: ${({ checked }) => (checked ? 'var(--success)' : '#fff')};
  border-radius: 3px;
  transition: background-color .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 1px 2px rgba(28, 200, 138, 0.3)
  }
`;

export const CheckIcon = styled.i`
  color: #fff;
`;

export const CheckboxLabel = styled.span`
  margin-left: 10px;
  font-size: 16px;
`;
