import React, { useCallback, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import ClothingIconViewer from '../../ClothingIconViewer';

import CatalogSquareBlock from '../../CatalogSquareBlock';
import ImagePickerBase64 from '../../ImagePickerBase64';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import SpinnerWithMessage from '../../SpinnerWithMessage';

type IconCreationModalType = Omit<ModalBaseType, 'children' | 'handleConfirm'> & {
  handleConfirm: (response: string) => Promise<void>;
};

export default function IconCreationModal({
  title,
  message,
  style,
  visible,
  handleClose,
  handleConfirm
}: IconCreationModalType) {
  const [isUploading, setIsUploading] = useState(false);
  const [croppedImage, setCroppedImage] = useState('');
  const { Translate } = useAppTranslation();

  const handleBeforeClose = useCallback(() => {
    setCroppedImage('');
    handleClose();
  }, [handleClose]);

  const handleBeforeConfirm = useCallback(async () => {
    setIsUploading(true);
    await handleConfirm(croppedImage);
    setIsUploading(false);
    handleBeforeClose();
  }, [croppedImage, handleBeforeClose, handleConfirm]);

  return (
    <ModalBase
      isLarge
      disableClickOutsideToClose
      visible={visible}
      style={style}
      title={title}
      message={message}
      confirmationButtonDisabled={croppedImage === ''}
      handleConfirm={handleBeforeConfirm}
      handleClose={handleBeforeClose}
    >
      <>
        {!isUploading && (
          <React.Fragment>
            <div className='row'>
              <div className='col-7' style={{ borderRight: '1px solid #ccc' }}>
                <ImagePickerBase64
                  useSquareAspectRatioCrop
                  maxImageHeightInEditor={250}
                  resizeSettings={{ width: 45, height: 45 }}
                  label={Translate('description.ideal-icon-style')}
                  onSelect={base64 => {
                    setCroppedImage(base64);
                  }}
                />
              </div>

              <div className='col d-flex flex-column justify-content-center align-items-center'>
                <React.Fragment>
                  <p>{Translate('labels.preview')}</p>
                  <div className='mb-2 d-flex'>
                    <CatalogSquareBlock imagePath={croppedImage} />
                    <ClothingIconViewer icon={croppedImage} marginLeft />
                  </div>
                </React.Fragment>
              </div>
            </div>
          </React.Fragment>
        )}

        {isUploading && <SpinnerWithMessage message={Translate('progress.sending')} />}
      </>
    </ModalBase>
  );
}
