import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Overlay } from '../styles';
import BasicButton from '../../Buttons/BasicButton';
import { useAppTranslation } from '../../../contexts/TranslationContext';

export type ModalBaseType = {
  children: JSX.Element;
  title: string;
  message?: string;
  visible: boolean;
  isLarge?: boolean;
  isExtraLarge?: boolean;
  hideFooter?: boolean;
  okOnly?: boolean;
  disableButtons?: boolean;
  disableClickOutsideToClose?: boolean;
  confirmationButtonDisabled?: boolean;
  zIndexIncrement?: number;
  disableEscToClose?: boolean;
  style: 'primary' | 'danger';
  handleClose: () => void;
  handleConfirm: () => void;
};

export default function ModalBase({
  children,
  title,
  message,
  style,
  visible,
  isLarge = false,
  isExtraLarge = false,
  hideFooter = false,
  disableButtons = false,
  okOnly = false,
  confirmationButtonDisabled = false,
  disableClickOutsideToClose = false,
  zIndexIncrement,
  disableEscToClose = false,
  handleClose,
  handleConfirm
}: ModalBaseType) {
  const confirmationButtonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const { Translate } = useAppTranslation();

  const handleCheckCloseAction = useCallback(
    (event: React.MouseEvent) => {
      if (event.target === modalRef.current && !disableClickOutsideToClose) {
        handleClose();
      }
    },
    [disableClickOutsideToClose, handleClose]
  );

  const show = useMemo(() => (visible ? 'show' : ''), [visible]);

  const handleCloseByKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (disableEscToClose) return;
      if (event.key === 'Escape') handleClose();
    },
    [disableEscToClose, handleClose]
  );

  useEffect(() => {
    if (visible) confirmationButtonRef.current?.focus();
  }, [visible]);

  useEffect(() => {
    document.addEventListener('keydown', handleCloseByKeyPress);

    return () => {
      document.removeEventListener('keydown', handleCloseByKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!visible) return <></>;

  return (
    <Overlay id='modal-overlay' className={`fade ${show}`} zIndexIncrement={zIndexIncrement}>
      {visible && (
        <div ref={modalRef} className='modal' style={{ display: 'block' }} onClick={handleCheckCloseAction}>
          <div className='modal-dialog modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title font-weight-bold' data-testid='modal-base-title'>
                  {title}
                </h5>
                <button type='button' className='close' onClick={handleClose}>
                  <span>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                {disableEscToClose && (
                  <div className='d-flex justify-content-end mb-3'>
                    <span className='badge badge-danger'>{Translate('labels.disabled-esc-key-to-close')}</span>
                  </div>
                )}

                {!!message && <p>{message}</p>}

                {children}
              </div>
              {!hideFooter && (
                <div className='modal-footer'>
                  {!okOnly && (
                    <BasicButton
                      testId='modal-base-close-button'
                      title={Translate('actions.close')}
                      color='light'
                      handleClick={handleClose}
                    />
                  )}

                  <BasicButton
                    testId='modal-base-confirmation-button'
                    title={okOnly ? 'OK' : Translate('actions.confirm')}
                    color={style}
                    disabled={confirmationButtonDisabled || disableButtons}
                    buttonRef={confirmationButtonRef}
                    handleClick={handleConfirm}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Overlay>
  );
}
