import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { type UserType } from '../types/UserType';
import { useHttpRequest } from './HttpRequestContext';
import { convertBase64ImageToBlob } from '../components/ImagePickerBase64/services/imageManipulation';
import { type RawAxiosRequestHeaders } from 'axios';
import { type LogoAddedBackendResponse } from '../types/LogoAddedBackendResponse';
import { useAppTranslation } from './TranslationContext';

type UserContextType = {
  user: UserType | null;
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  isCompanyAccount: boolean;
  isEmployeeAccount: boolean;
  addLogoToUser: (imageData: string) => Promise<void>;
  removeLogoFromUser: () => Promise<void>;
};

type UserProviderType = {
  children: JSX.Element;
};

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: UserProviderType) => {
  const [user, setUser] = useState<UserType | null>(null);

  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const addLogoToUser = useCallback(
    async (imageData: string): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        const binaryImage = convertBase64ImageToBlob(imageData, Translate);

        const formData = new FormData();
        formData.append('image', binaryImage, 'image.png');

        const defaultHeaders: RawAxiosRequestHeaders = httpConnection.defaults.headers;

        const fileUploadHeaders = {
          headers: {
            ...defaultHeaders,
            'Content-Type': 'multipart/form-data'
          }
        };

        httpConnection
          .post<LogoAddedBackendResponse>('user/logo', formData, fileUploadHeaders)
          .then(response => {
            setUser({ ...user!, logo: response.data.logo });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [Translate, httpConnection, user]
  );

  const removeLogoFromUser = useCallback(async (): Promise<void> => {
    await new Promise<void>((resolve, reject) => {
      httpConnection
        .delete('user/logo')
        .then(() => {
          setUser({ ...user!, logo: null });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }, [httpConnection, user]);

  const isCompanyAccount = useMemo(() => {
    if (!!user && user.user_type === 'company' && user.company_id === null) return true;
    return false;
  }, [user]);

  const isEmployeeAccount = useMemo(() => {
    if (!!user && user.user_type === 'employee' && user.company_id !== null) return true;
    return false;
  }, [user]);

  const contextValues = useMemo(
    () => ({ user, setUser, isCompanyAccount, isEmployeeAccount, addLogoToUser, removeLogoFromUser }),
    [user, setUser, isCompanyAccount, isEmployeeAccount, addLogoToUser, removeLogoFromUser]
  );

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context) throw new Error('useUser must be used within a UserProvider');

  return context;
};
