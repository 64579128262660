import React, { useCallback, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../../components/Modals/ModalBase';
import TextInput from '../../../../../../../components/Forms/TextInput';
import CurrencyInput from '../../../../../../../components/Forms/CurrencyInput';
import SplitButton from '../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../components/IconsCatalog';
import { type FinishingOption } from '../../../../../../../types/ClothingFinishingOptions/FinishingOption';
import { type FinishingOptionItem } from '../../../../../../../types/ClothingFinishingOptions/FinishingOptionItem';
import { addFinishingOptionItemToFinishingOptionGroup, deleteFinishingOptionItemFromFinishingOptionGroup, editFinishingOptionItemInFinishingOptionGroup, generateEmptyOptionItemEditData } from './services/finishingOptionItemService';
import { toast } from 'react-hot-toast-promise';
import addFinishingOptionItem from './api/addFinishingOptionItem';
import { useHttpRequest } from '../../../../../../../contexts/HttpRequestContext';
import { useAppTranslation } from '../../../../../../../contexts/TranslationContext';
import editFinishingOptionItem from './api/editFinishingOptionItem';
import ConfirmationModal from '../../../../../../../components/Modals/ConfirmationModal';
import deleteFinishingOptionItem from './api/deleteFinishingOptionItem';
import { getServerErrorMessageFromResponse } from '../../../../../../../utils/helper';

type FinishingOptionItemsManagerType = Pick<ModalBaseType, 'visible' | 'style' | 'title' | 'message' | 'handleClose' | 'handleConfirm' | 'disableClickOutsideToClose' | 'hideFooter'> & {
  finishingOption: FinishingOption;
  onUpdateFinishingOption: (updatedFinishingOption: FinishingOption) => void;
}

type FinishingOptionItemEditType = Pick<FinishingOptionItem, 'item' | 'price'> & {
  id?: number;
}

export default function FinishingOptionItemsManager({ finishingOption, onUpdateFinishingOption, ...props }: FinishingOptionItemsManagerType) {
  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const [isRequesting, setIsRequesting] = useState(false);
  const [newFinishingOptionItem, setNewFinishingOptionItem] = useState<FinishingOptionItemEditType>(() => generateEmptyOptionItemEditData());
  const [finishingOptionItemToDelete, setFinishingOptionItemToDelete] = useState<FinishingOptionItem | null>(null);

  const validateFinishingOptionItem = useCallback(() => {
    if (newFinishingOptionItem.item === '') throw new Error(Translate('error.finishing-option-item-empty-title'));

    const duplicated = finishingOption.option_items.find((optionItem) => optionItem.item.toUpperCase() === newFinishingOptionItem.item.toUpperCase());

    if (duplicated) throw new Error(Translate('error.finishing-option-item-duplicated-title'));
  }, [Translate, finishingOption.option_items, newFinishingOptionItem.item]);

  const handleAddFinishingOptionItem = useCallback(() => {
    try {
      validateFinishingOptionItem();

      const { item, price } = newFinishingOptionItem;
      const task = addFinishingOptionItem({ httpConnection, item, price, finishingOptionId: finishingOption.id });

      setIsRequesting(true);

      toast.promise(task, {
        loading: Translate('progress.loading'),
        success: (addedFinishingOptionItem) => {
          const updatedFinishingOption: FinishingOption = addFinishingOptionItemToFinishingOptionGroup(finishingOption, addedFinishingOptionItem);
          onUpdateFinishingOption(updatedFinishingOption);
          setNewFinishingOptionItem(generateEmptyOptionItemEditData());
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, finishingOption, httpConnection, newFinishingOptionItem, onUpdateFinishingOption, validateFinishingOptionItem]);

  const handleEditFinishingOptionItem = useCallback(() => {
    try {
      if (!newFinishingOptionItem.id) return;

      validateFinishingOptionItem();

      const { item, price } = newFinishingOptionItem;

      const task = editFinishingOptionItem({
        httpConnection,
        item,
        price,
        finishingOptionId: finishingOption.id,
        finishingOptionItemId: newFinishingOptionItem.id
      });

      setIsRequesting(true);

      toast.promise(task, {
        loading: Translate('progress.loading'),
        success: (editedFinishingOptionItem) => {
          const updatedFinishingOption: FinishingOption = editFinishingOptionItemInFinishingOptionGroup(finishingOption, editedFinishingOptionItem);
          onUpdateFinishingOption(updatedFinishingOption);
          setNewFinishingOptionItem(generateEmptyOptionItemEditData());
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, finishingOption, httpConnection, newFinishingOptionItem, onUpdateFinishingOption, validateFinishingOptionItem]);

  const handleDeleteFinishingOptionItem = useCallback(() => {
    try {
      if (!finishingOptionItemToDelete) return;

      const task = deleteFinishingOptionItem({
        httpConnection,
        finishingOptionItemId: finishingOptionItemToDelete.id
      });

      setIsRequesting(true);

      toast.promise(task, {
        loading: Translate('progress.loading'),
        success: () => {
          const updatedFinishingOption: FinishingOption = deleteFinishingOptionItemFromFinishingOptionGroup(finishingOption, finishingOptionItemToDelete);
          onUpdateFinishingOption(updatedFinishingOption);
          setNewFinishingOptionItem(generateEmptyOptionItemEditData());
          setFinishingOptionItemToDelete(null);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, finishingOption, finishingOptionItemToDelete, httpConnection, onUpdateFinishingOption]);

  const handleSubmit = useCallback(() => {
    if (newFinishingOptionItem.id) handleEditFinishingOptionItem();
    else handleAddFinishingOptionItem();
  }, [handleAddFinishingOptionItem, handleEditFinishingOptionItem, newFinishingOptionItem.id]);

  return (
    <ModalBase {...props}>
      <React.Fragment>
        <ConfirmationModal
          title={Translate('modal.delete-finishing-option-variation')}
          message={Translate('description.delete-finishing-option-confirmation-message')}
          visible={!!finishingOptionItemToDelete}
          disableButtons={isRequesting}
          style='danger'
          handleClose={() => {
            setFinishingOptionItemToDelete(null);
          }}
          handleConfirm={() => {
            handleDeleteFinishingOptionItem();
          }}
        />

        <div className="row mb-3">
          <div className="col">
            <h6>
              <span>{Translate('labels.finishing-option')}: </span>
              <strong>{finishingOption.title}</strong>
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextInput
              disabled={isRequesting}
              id='finishing-option-item-title'
              label={!newFinishingOptionItem.id ? Translate('labels.new-variation') : Translate('labels.edit-variation')}
              value={newFinishingOptionItem.item}
              autofocus
              onChange={({ target }) => {
                setNewFinishingOptionItem({ ...newFinishingOptionItem, item: target.value });
              }}
            />
          </div>
          <div className="col pl-0">
            <CurrencyInput
              disabled={isRequesting}
              id='finishing-option-item-price'
              label={Translate('labels.price')}
              value={newFinishingOptionItem.price}
              onChange={(value) => {
                setNewFinishingOptionItem({ ...newFinishingOptionItem, price: value });
              }}
            />
          </div>

          <div className="col-auto pl-0">
            {newFinishingOptionItem.id && (
              <SplitButton
                disabled={isRequesting}
                color='danger'
                simulateLabelMarginTop
                icon={IconsCatalog.times}
                handleClick={() => {
                  setNewFinishingOptionItem(generateEmptyOptionItemEditData());
                }}
              />
            )}

            <SplitButton
              disabled={isRequesting}
              color='primary'
              simulateLabelMarginTop
              marginLeft={!!newFinishingOptionItem.id}
              icon={newFinishingOptionItem.id ? IconsCatalog.pen : IconsCatalog.plus}
              handleClick={handleSubmit}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <table className='table table-sm table-bordered'>
              <thead>
                <tr>
                  <th>{Translate('labels.finishing-option-type')}</th>
                  <th colSpan={2} className='text-center'>-</th>
                </tr>
              </thead>

              <tbody>
                {
                  finishingOption.option_items.map((optionItem, index) => (
                    <tr key={index}>
                      <td className='align-middle'>
                        {optionItem.item}
                      </td>

                      <td className='table-column-fit'>
                        <SplitButton
                          disabled={isRequesting}
                          size='sm'
                          color='primary'
                          icon={IconsCatalog.pen}
                          handleClick={() => {
                            setNewFinishingOptionItem(optionItem);
                          }}
                        />
                      </td>

                      <td className='table-column-fit'>
                        <SplitButton
                          disabled={isRequesting}
                          size='sm'
                          color='danger'
                          icon={IconsCatalog.trash}
                          handleClick={() => {
                            setFinishingOptionItemToDelete(optionItem);
                          }}
                        />
                      </td>
                    </tr>

                  ))
                }

                {finishingOption.option_items.length === 0 && (
                  <tr>
                    <td colSpan={999}>{Translate('status.no-finishing-option-items')}</td>
                  </tr>
                )}
              </tbody>

            </table>
          </div>
        </div>
      </React.Fragment>
    </ModalBase >
  );
};
