import SplitButton from '../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../components/IconsCatalog';
import { type KanbanColumn } from '../../../../../../../types/Kanban/KanbanColumn';
import { useKanbanWorkspaces } from '../../../../../../../contexts/KanbanWorkspacesContext';
import { Container } from './styles';

type CreateCardButtonProps = {
  column: KanbanColumn;
};

export function CreateCardButton({ column }: CreateCardButtonProps) {
  const { handleShowCreateModal } = useKanbanWorkspaces();

  return (
    <Container
      type='button'
      onClick={() => {
        handleShowCreateModal(column);
      }}
    >
      <span>
        <i className='fa fa-plus'></i>
        Adicionar card
      </span>
    </Container>
  );
}
