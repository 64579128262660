import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 20px;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
