import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, type SetStateAction, useMemo } from 'react';
import { generateInitialOrder } from '../../helper/orderHelper';
import { type Order } from '../../../../../../types/OrderTypes';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { toast } from 'react-hot-toast-promise';

import {
  changeGenderAndResetClotheSizes
} from '../../services/orderFormService';

import {
  changeClothingData,
  clearClothingData,
  type ChangeClothingDataType,
  validateOrderBeforeAdd,
  addOrder,
  editOrder
} from '../../../services/orderService';
import { findSublistIndexBySublistUUID } from '../../../services/sublistService';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import DefaultListEditor from './components/DefaultListEditor';
import FinalClientStepperListEditor from './components/FinalClientStepperListEditor';
import { getServerErrorMessageFromResponse } from '../../../../../../utils/helper';

export type MainFormEditorType = {
  order: Order;
  sublists: SublistType[];
  setSublists: React.Dispatch<SetStateAction<SublistType[]>>;
  setOrder: React.Dispatch<SetStateAction<Order>>;
  importedModels: ClothingModelType[];
  targetSublistIndex: number;
  isCompanyEditor: boolean;
  setTargetSublistIndex: React.Dispatch<SetStateAction<number>>;
  renderAsStepper?: boolean;
};

export default function MainFormEditor({
  order,
  setOrder,
  sublists,
  setSublists,
  importedModels,
  targetSublistIndex,
  setTargetSublistIndex,
  isCompanyEditor,
  renderAsStepper
}: MainFormEditorType) {
  const { Translate } = useAppTranslation();

  const handleChangeGender = useCallback(
    (gender: Order['gender']) => {
      const updatedOrder = changeGenderAndResetClotheSizes({ order, gender });
      setOrder(updatedOrder);
    },
    [order, setOrder]
  );

  const handleChangeClothingData = useCallback(
    ({ value, field, clothingIndex }: ChangeClothingDataType) => {
      const updatedSublists = changeClothingData({ value, field, clothingIndex }, order);
      setOrder(updatedSublists);
    },
    [order, setOrder]
  );

  const handleClearClothingData = useCallback(
    (clothingIndex: number) => {
      const updatedSublists = clearClothingData(clothingIndex, order);
      setOrder(updatedSublists);
    },
    [order, setOrder]
  );

  const handleClearForm = useCallback(() => {
    setOrder(generateInitialOrder(importedModels));
  }, [importedModels, setOrder]);

  const handleAddOrder = useCallback(() => {
    try {
      validateOrderBeforeAdd(order, Translate);

      const newOrder: Order = {
        sublistUUID: sublists[targetSublistIndex].uuid,
        id: uuidv4(),
        ...order
      };

      const updatedSublists = addOrder(sublists, targetSublistIndex, newOrder);
      setSublists(updatedSublists);
      handleClearForm();

      toast.success(Translate('toast.order-added'));
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [order, Translate, sublists, targetSublistIndex, setSublists, handleClearForm]);

  const handleEditOrder = useCallback(() => {
    if (!order.id) return;

    const updatedSublists = editOrder({ order, sublists });

    setSublists(updatedSublists);
    setOrder(generateInitialOrder(importedModels));
    toast.success(Translate('toast.editing-saved'));
  }, [Translate, importedModels, order, setOrder, setSublists, sublists]);

  const handleCancelEdit = useCallback(() => {
    setOrder(generateInitialOrder(importedModels));
  }, [importedModels, setOrder]);

  const handleFindSublistIndex = useCallback((sublistUUID: string | undefined): number => {
    return findSublistIndexBySublistUUID(sublists, sublistUUID ?? '');
  }, [sublists]);

  /**
   * if resolution width is less them 1600
   * the ClothingInputData will have no enough space to display correctly in company editor
   * this code adjusts and put side by side if has space
   * or one input by line in lower resolutions
   */
  const dynamicLargeClass = useMemo(() => {
    if (isCompanyEditor && window.screen.width < 1600) return 'col-lg-6';
    return 'col-lg-12 col-xl-6';
  }, [isCompanyEditor]);

  return (
    <React.Fragment>
      {
        !renderAsStepper && (
          <DefaultListEditor
            dynamicLargeClass={dynamicLargeClass}
            handleAddOrder={handleAddOrder}
            handleCancelEdit={handleCancelEdit}
            handleChangeClothingData={handleChangeClothingData}
            handleChangeGender={handleChangeGender}
            handleClearClothingData={handleClearClothingData}
            handleClearForm={handleClearForm}
            handleEditOrder={handleEditOrder}
            handleFindSublistIndex={handleFindSublistIndex}
            importedModels={importedModels}
            isCompanyEditor={isCompanyEditor}
            order={order}
            setOrder={setOrder}
            setTargetSublistIndex={setTargetSublistIndex}
            sublists={sublists}
            targetSublistIndex={targetSublistIndex}
          />
        )
      }

      {
        renderAsStepper && (
          <FinalClientStepperListEditor
            handleAddOrder={handleAddOrder}
            handleCancelEdit={handleCancelEdit}
            handleChangeClothingData={handleChangeClothingData}
            handleChangeGender={handleChangeGender}
            handleClearClothingData={handleClearClothingData}
            handleClearForm={handleClearForm}
            handleEditOrder={handleEditOrder}
            handleFindSublistIndex={handleFindSublistIndex}
            importedModels={importedModels}
            order={order}
            setOrder={setOrder}
            setTargetSublistIndex={setTargetSublistIndex}
            sublists={sublists}
            targetSublistIndex={targetSublistIndex}
          />
        )
      }
    </React.Fragment>
  );
}
