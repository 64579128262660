import { type TFunction } from 'i18next';
import { type ClothingGenderType, type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type ClothingQuantitiesDetailed } from '../../../../../types/ClothingQuantitiesDetailed';
import { type SublistReportType, type ProjectReportType } from '../../../../../types/ProjectReportType';
import { type SublistType } from '../../../../../types/SublistTypes';
import { sumArrayOfNumbers } from '../../../../../utils/helper';
import { calculateOrderLengthInMeters, calculateOrderPrice, calculateOrderProductionTimeInSeconds, calculateOrderWeight } from './orderFormService';

export const generateProjectReport = (sublists: SublistType[], importedModels: ClothingModelType[], Translate: TFunction): ProjectReportType => {
  const projectReport: ProjectReportType = {
    ready: false,
    sublists: [],
    totalProjectPrice: 0,
    totalProjectPriceWithDiscount: 0,
    totalProjectWeight: 0,
    totalProjectClothes: 0,
    totalProjectProductionTimeInSeconds: 0,
    totalProjectMaterialLengthInMeters: 0,
    projectClothingQuantitiesDetailed: {}
  };

  sublists.forEach(sublist => {
    const eachOrderTotalPrice: number[] = [];
    const eachOrderTotalWeight: number[] = [];
    const eachOrderTotalProductionTimeInSeconds: number[] = [];
    const eachOrderMaterialLengthInMeters: number[] = [];

    sublist.orders.forEach(order => {
      const orderTotalPrice = calculateOrderPrice({ sublist, order, importedModels, Translate });
      eachOrderTotalPrice.push(orderTotalPrice);

      const orderTotalWeight = calculateOrderWeight(order, importedModels, Translate);
      eachOrderTotalWeight.push(orderTotalWeight);

      const orderTotalProductionTimeInSeconds = calculateOrderProductionTimeInSeconds(order, importedModels, Translate);
      eachOrderTotalProductionTimeInSeconds.push(orderTotalProductionTimeInSeconds);

      const orderTotalLengthInMeters = calculateOrderLengthInMeters(order, importedModels, Translate);
      eachOrderMaterialLengthInMeters.push(orderTotalLengthInMeters);
    });

    const eachModelQuantityInSublist: number[] = [];

    importedModels.forEach(model => {
      const modelCount = countTotalSublistClothesByModelId(sublist, model.id);
      eachModelQuantityInSublist.push(modelCount);
    });

    const tempSublistDiscount = sublist?.discount ?? 0;
    const tempTotalSublistPrice = sumArrayOfNumbers(eachOrderTotalPrice);
    const tempSublistDiscountPrice = tempTotalSublistPrice - (tempTotalSublistPrice * (tempSublistDiscount / 100));

    const tempSublistReport: SublistReportType = {
      ordersTotalPrices: eachOrderTotalPrice,
      sublistDiscount: tempSublistDiscount,
      eachModelQuantity: eachModelQuantityInSublist,
      totalSublistClothes: sumArrayOfNumbers(eachModelQuantityInSublist),
      totalSublistPrice: tempTotalSublistPrice,
      totalSublistPriceWithDiscount: tempSublistDiscountPrice,
      totalSublistWeight: sumArrayOfNumbers(eachOrderTotalWeight),
      totalSublistProductionTimeInSeconds: sumArrayOfNumbers(eachOrderTotalProductionTimeInSeconds),
      totalSublistMaterialLengthInMeters: sumArrayOfNumbers(eachOrderMaterialLengthInMeters),
      sublistClothingQuantitiesDetailed: calculateClothingQuantitiesForSublist(sublist)
    };

    projectReport.sublists.push(tempSublistReport);
  });

  let totalProjectPrice = 0;
  let totalProjectWeight = 0;
  let totalProjectClothes = 0;
  let totalProjectProductionTimeInSeconds = 0;
  let totalProjectMaterialLengthInMeters = 0;

  projectReport.sublists.forEach(sublistReport => {
    totalProjectClothes += sublistReport.totalSublistClothes;
    totalProjectPrice += sublistReport.totalSublistPrice;
    totalProjectWeight += sublistReport.totalSublistWeight;
    totalProjectProductionTimeInSeconds += sublistReport.totalSublistProductionTimeInSeconds;
    totalProjectMaterialLengthInMeters += sublistReport.totalSublistMaterialLengthInMeters;
  });

  projectReport.totalProjectPrice = totalProjectPrice;
  projectReport.totalProjectPriceWithDiscount = calculateTotalProjectPriceWithDiscount(projectReport.sublists);
  projectReport.totalProjectWeight = totalProjectWeight;
  projectReport.totalProjectClothes = totalProjectClothes;
  projectReport.totalProjectProductionTimeInSeconds = totalProjectProductionTimeInSeconds;
  projectReport.totalProjectMaterialLengthInMeters = totalProjectMaterialLengthInMeters;
  projectReport.projectClothingQuantitiesDetailed = calculateClothingQuantitiesForProject(sublists);
  projectReport.ready = true;

  return projectReport;
};

export const countTotalSublistClothesByModelId = (sublist: SublistType, modelId: number): number => {
  let accumulator = 0;

  sublist.orders.forEach(currentOrder => {
    currentOrder.clothes.forEach(currentClothe => {
      if (currentClothe.modelId === modelId) {
        accumulator += currentClothe.quantity;
      }
    });
  });

  return accumulator;
};

export const calculateClothingQuantitiesForProject = (sublists: SublistType[]): ClothingQuantitiesDetailed => {
  const totalClothingQuantities: ClothingQuantitiesDetailed = {};

  sublists.forEach((sublist) => {
    const sublistQuantities = calculateClothingQuantitiesForSublist(sublist);

    // Merge the quantities from the current sublist into the total quantities
    Object.entries(sublistQuantities).forEach(([gender, genderQuantities]) => {
      if (!totalClothingQuantities[gender]) {
        totalClothingQuantities[gender] = {};
      }

      Object.entries(genderQuantities).forEach(([modelId, modelQuantities]) => {
        if (!totalClothingQuantities[gender][modelId]) {
          totalClothingQuantities[gender][modelId] = {};
        }

        Object.entries(modelQuantities).forEach(([sizeKey, quantity]) => {
          if (!totalClothingQuantities[gender][modelId][sizeKey]) {
            totalClothingQuantities[gender][modelId][sizeKey] = 0;
          }

          totalClothingQuantities[gender][modelId][sizeKey] += quantity;
        });
      });
    });
  });

  return totalClothingQuantities;
};

export const calculateClothingQuantitiesForSublist = (sublist: SublistType): ClothingQuantitiesDetailed => {
  const clothingQuantities: ClothingQuantitiesDetailed = {};

  sublist.orders.forEach((order) => {
    const gender: keyof ClothingGenderType = order.gender;

    order.clothes.forEach((clothing) => {
      const { modelId, sizeIndex, quantity } = clothing;

      if (!clothingQuantities[gender]) {
        clothingQuantities[gender] = {};
      }

      if (!clothingQuantities[gender][modelId]) {
        clothingQuantities[gender][modelId] = {};
      }

      const sizeKey = `size${sizeIndex}`;
      if (!clothingQuantities[gender][modelId][sizeKey]) {
        clothingQuantities[gender][modelId][sizeKey] = 0;
      }

      clothingQuantities[gender][modelId][sizeKey] += quantity;
    });
  });

  return clothingQuantities;
};

export const calculateTotalProjectPriceWithDiscount = (sublists: SublistReportType[]): number => {
  return sublists.reduce((accumulator, currentSublsit) => accumulator + currentSublsit.totalSublistPriceWithDiscount, 0);
};
