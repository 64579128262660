import React, { useCallback, useMemo } from 'react';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';

type SublistFinishingOptionsViewerType = {
  importedModels: ClothingModelType[];
  sublist: SublistType;
  alignLeft?: boolean;
}

export default function SublistFinishingOptionsViewer({ sublist, importedModels, alignLeft }: SublistFinishingOptionsViewerType) {
  const { Translate } = useAppTranslation();

  const isEmptyFinishingOptionsForModelId = useCallback((modelId: number) => {
    const selectedFinishingOptions = sublist.selected_finishing_options.filter(finishingOption => finishingOption.model_id === modelId);
    return selectedFinishingOptions.length === 0;
  }, [sublist.selected_finishing_options]);

  const getFinishingOptionsAsConcatenatedStringForModelId = useCallback((modelId: number) => {
    const selectedFinishingOptions = sublist.selected_finishing_options.filter(finishingOption => finishingOption.model_id === modelId);

    const finishingOptionsList: string[] = [];

    selectedFinishingOptions.forEach(finishingOption => {
      const finishingOptionName = finishingOption.option.title;
      const finishingOptionItemName = finishingOption.option_picked.item;
      const optionAndItemName = `${finishingOptionName} ${finishingOptionItemName}`;

      finishingOptionsList.push(optionAndItemName);
    });

    return finishingOptionsList.map((option, index) => (
      <p className='m-0' key={index}>{option}</p>
    ));
  }, [sublist.selected_finishing_options]);

  const horizontalAlignment = useMemo(() => alignLeft ? '' : 'mx-auto', [alignLeft]);

  if (sublist.selected_finishing_options.length === 0) return <></>;

  return (
    <table className={`table table-sm table-bordered w-auto ${horizontalAlignment} my-4`}>
      <thead>
        <tr>
          <th>{Translate('labels.model')}</th>
          <th>{Translate('labels.finishing-option')}</th>
        </tr>
      </thead>

      <tbody>
        {
          importedModels.map((model, modelIndex) => {
            if (isEmptyFinishingOptionsForModelId(model.id)) return <></>;

            return (
              <tr key={modelIndex}>
                <td className='text-center'>{model.name}</td>
                <td>{getFinishingOptionsAsConcatenatedStringForModelId(model.id)}</td>
              </tr>

            );
          })
        }
      </tbody>
    </table>
  );
};
