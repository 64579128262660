import React, { useCallback } from 'react';
import { type StepperListEditorItemType } from '../..';
import BadgeButton from '../../../../../../../../../../components/Buttons/BadgeButton';

type StepperHeaderButtonsType = {
  steps: StepperListEditorItemType[];
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export default function StepperHeaderButtons({ steps, currentStep, setCurrentStep }: StepperHeaderButtonsType) {
  const generateBadgeTextWithStepNumber = useCallback((stepIndex: number, title: string) => {
    return `${stepIndex + 1}. ${title}`;
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-6">
        <div className='d-flex justify-content-between border-bottom pb-3 mb-3'>
          {
            steps.map((step, stepIndex) => (
              <BadgeButton
                key={stepIndex}
                useLarge
                title={generateBadgeTextWithStepNumber(stepIndex, step.title)}
                color={currentStep === stepIndex ? 'primary' : 'secondary'}
                handleClick={() => { setCurrentStep(stepIndex); }}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};
