import React from 'react';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { type SublistReportType } from '../../../../../../types/ProjectReportType';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import DetailedClothingReport from '../DetailedClothingReport';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';

type ProjectReportSublistHeaderType = {
  sublist: SublistType;
  sublistReport: SublistReportType;
  importedModels: ClothingModelType[];
  useSeparatedLinesToShowDetailedReport: boolean;
};

export default function ProjectReportSublistHeader({
  sublist,
  sublistReport,
  importedModels,
  useSeparatedLinesToShowDetailedReport
}: ProjectReportSublistHeaderType) {
  const { Translate } = useAppTranslation();

  return (
    <table className='table table-sm table-bordered border-top-thick'>
      <tbody>
        <tr>
          <td colSpan={999}>
            <div className='row'>
              <div className='col-auto font-weight-bold'>{sublist.title}</div>
              <div className='col-auto border-left'>
                {Translate('labels.clothes-count').replace('{count}', sublistReport.totalSublistClothes.toString())}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td colSpan={999}>{Translate('labels.consolidated-piece-gender-size')}</td>
        </tr>

        <tr>
          <td colSpan={999}>
            <DetailedClothingReport
              importedModels={importedModels}
              quantitiesDetailedReport={sublistReport.sublistClothingQuantitiesDetailed}
              useSeparatedLinesToShowDetailedReport={useSeparatedLinesToShowDetailedReport}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
