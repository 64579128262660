import ModalBase, { type ModalBaseType } from '../ModalBase';

type ConfirmationModalType = Omit<ModalBaseType, 'children'>;

export default function ConfirmationModal({
  title,
  message,
  visible,
  style,
  disableButtons,
  zIndexIncrement,
  handleClose,
  handleConfirm
}: ConfirmationModalType) {
  return (
    <ModalBase
      visible={visible}
      style={style}
      title={title}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      zIndexIncrement={zIndexIncrement}
      disableButtons={disableButtons}
    >
      <p>{message}</p>
    </ModalBase>
  );
}
