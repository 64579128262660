type Colors = Record<number, string>;

export const COLORS: Colors = {
  2: 'var(--danger) !important',
  1: 'var(--orange) !important',
  0: 'var(--light-v2) !important'
};

export const BACKGROUND_COLORS: Colors = {
  2: 'var(--danger) !important',
  1: 'var(--orange) !important',
  0: 'var(--background-card) !important'
};
