import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-hot-toast-promise';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { type EventContentArg } from '@fullcalendar/core';
import BasicCard from '../../../components/Cards/BasicCard';
import SplitButton from '../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../components/IconsCatalog';
import { useProjectsDeliveryCalendar } from '../../../contexts/ProjectsDeliveryCalendar';
import { displayTimePassedSinceDate } from '../../../helpers/dateTime';
import { useHttpRequest } from '../../../contexts/HttpRequestContext';
import {
  getUpdatedProjectsDeliveryCalendar,
  parseLanguageCodeToFullCalendarLocale
} from './services/DeliveryCalendarService';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { type SupportedLanguages } from '../../../types/SupportedLanguages';
import { type AxiosResponse } from 'axios';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';

export default function Dashboard() {
  const [isUpdatingCalendar, setIsUpdatingCalendar] = useState(false);

  const navigate = useNavigate();
  const { deliveryCalendar, setUpdatedDeliveryCalendar, lastFetchTime } = useProjectsDeliveryCalendar();
  const { httpConnection } = useHttpRequest();
  const { Translate, dateFnsLocale, selectedLanguage } = useAppTranslation();

  const handleUpdateCalendarData = useCallback(async () => {
    try {
      setIsUpdatingCalendar(true);
      const updatedCalendarData = await getUpdatedProjectsDeliveryCalendar(httpConnection);
      setUpdatedDeliveryCalendar(updatedCalendarData);
    } finally {
      setIsUpdatingCalendar(false);
    }
  }, [httpConnection, setUpdatedDeliveryCalendar]);

  const runHandleUpdateCalendarDataWithToastPromise = useCallback(() => {
    toast.promise(handleUpdateCalendarData(), {
      loading: Translate('progress.getting-recent-info'),
      success: Translate('toast.done'),
      error: getServerErrorMessageFromResponse
    });
  }, [Translate, handleUpdateCalendarData]);

  const openProject = useCallback(
    (projectId: number) => {
      navigate('/orderlist/projects/view', {
        state: { isNew: false, projectId }
      });
    },
    [navigate]
  );

  const renderEventContent = useCallback(
    (eventInfo: EventContentArg) => {
      const projectId = eventInfo.event.extendedProps.project_id;

      return (
        <span
          role='button'
          className='badge badge-primary d-block text-left'
          onClick={() => {
            openProject(projectId);
          }}
        >
          {eventInfo.event.title}
        </span>
      );
    },
    [openProject]
  );

  if (lastFetchTime === null) return <></>;

  return (
    <React.Fragment>
      <div data-testid='dashboard-container'>
        <div className='d-flex align-items-start'>
          <h1 className='h3 mb-4 text-gray-800 mr-2' data-testid='dashboard-title'>
            {Translate('labels.calendar')}
          </h1>

          {!isUpdatingCalendar && (
            <SplitButton
              icon={IconsCatalog.sync}
              color='primary'
              size='sm'
              handleClick={runHandleUpdateCalendarDataWithToastPromise}
            />
          )}
        </div>
      </div>

      <BasicCard
        title={Translate('labels.delivery-calendar')}
        description={`${Translate('labels.calendar-data-was-get')} ${displayTimePassedSinceDate(
          lastFetchTime,
          dateFnsLocale
        )}.`}
      >
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          events={deliveryCalendar}
          locale={parseLanguageCodeToFullCalendarLocale(selectedLanguage as SupportedLanguages)}
          eventContent={renderEventContent}
        />
      </BasicCard>
    </React.Fragment>
  );
}
