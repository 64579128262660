import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { type ClothingModelStoreUpdateRequestType, type ClothingModelType } from '../types/ClothingModelType';
import { useHttpRequest } from './HttpRequestContext';
import { toast } from 'react-hot-toast-promise';
import { type BulkDeleteReportType } from '../types/BulkDeleteReportType';
import { type BulkDeleteModelReportType } from '../types/BulkDeleteModelReportType';
import { useAppTranslation } from './TranslationContext';
import { getServerErrorMessageFromResponse } from '../utils/helper';
import { type AxiosResponse } from 'axios';

type ClothingModelsProviderProps = {
  children: JSX.Element;
};

type ClothingModelsContextType = {
  models: ClothingModelType[];
  setModels: React.Dispatch<React.SetStateAction<ClothingModelType[]>>;
  getModelsById: (modelIDs: number[]) => ClothingModelType[];
  addModel: (model: ClothingModelStoreUpdateRequestType) => Promise<void>;
  editModel: (model: ClothingModelStoreUpdateRequestType, modelId: number) => Promise<void>;
  deleteModels: (selectedModels: ClothingModelType[]) => Promise<BulkDeleteReportType<BulkDeleteModelReportType>>;
};

export const ClothingModelsContext = createContext<ClothingModelsContextType>({} as ClothingModelsContextType);

export const ClothingModelsProvider = ({ children }: ClothingModelsProviderProps) => {
  const [models, setModels] = useState<ClothingModelType[]>([]);

  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const getModelsById = useCallback(
    (modelIDs: number[]): ClothingModelType[] => {
      // Create a mapping of model ID to their respective models for quick access
      const modelMap = new Map(models.map(model => [model.id, model]));

      // Use the map to retrieve models in the desired order and filter out undefined values
      const foundModels = modelIDs.map(id => modelMap.get(id)).filter((model): model is ClothingModelType => !!model);

      return foundModels;
    },
    [models]
  );

  const addModel = useCallback(
    async (model: ClothingModelStoreUpdateRequestType): Promise<void> => {
      try {
        const response = await httpConnection.post<ClothingModelType>('/list/clothing-models', model);
        setModels([response.data, ...models]);
        toast.success(Translate('toast.model-add-success'));
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
      }
    },
    [Translate, httpConnection, models]
  );

  const editModel = useCallback(
    async (model: ClothingModelStoreUpdateRequestType, modelId: number): Promise<void> => {
      try {
        const response = await httpConnection.put<ClothingModelType>(`/list/clothing-models/${modelId}`, model);

        const updatedModelsList = models.map(currentModel => {
          if (currentModel.id === modelId) return response.data;
          return currentModel;
        });

        setModels(updatedModelsList);
        toast.success(Translate('toast.changes-saved'));
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
      }
    },
    [Translate, httpConnection, models]
  );

  const deleteModels = useCallback(
    async (selectedModels: ClothingModelType[]): Promise<BulkDeleteReportType<BulkDeleteModelReportType>> => {
      return await new Promise<BulkDeleteReportType<BulkDeleteModelReportType>>((resolve, reject) => {
        const selectedIDs = selectedModels.map(selectedModel => selectedModel.id);
        const url = '/list/clothing-models/bulk-delete';

        httpConnection
          .post<BulkDeleteReportType<BulkDeleteModelReportType>>(url, { selected_ids: selectedIDs })
          .then(response => {
            const { success } = response.data;

            if (success) {
              const filtered = models.filter(currentModel => !selectedModels.includes(currentModel));
              setModels(filtered);
              resolve(response.data);
            }

            reject(response.data);
          })
          .catch(err => {
            const error = err as AxiosResponse;
            reject(error.data);
          });
      });
    },
    [models]
  );

  const contextValues = useMemo(
    () => ({
      models,
      setModels,
      addModel,
      editModel,
      deleteModels,
      getModelsById
    }),
    [models, setModels, addModel, editModel, deleteModels, getModelsById]
  );

  return <ClothingModelsContext.Provider value={contextValues}>{children}</ClothingModelsContext.Provider>;
};

export const useClothingModels = (): ClothingModelsContextType => {
  const context = useContext(ClothingModelsContext);

  if (!context) {
    throw new Error('useClothingModels must be used within a ClothingModelsProvider');
  }

  return context;
};
