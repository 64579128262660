import { type AxiosResponse, type AxiosInstance } from 'axios';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { HttpResponse } from '../server/apiHelper';
import { createConfiguredAxiosInstance, loadAuthToken } from './services/httpRequestContextService';
import { STORAGE_AUTH_TOKEN } from '../utils/storageItems';
import { toast } from 'react-hot-toast-promise';
import type BackendErrorMessageType from '../types/BackendErrorMessage';
import { useAppTranslation } from './TranslationContext';

type HttpRequestProviderProps = {
  children: JSX.Element;
};

type HttpRequestType = {
  bearerToken: string;
  setBearerToken: React.Dispatch<React.SetStateAction<string>>;
  httpConnection: AxiosInstance;
};

const HttpRequestContext = createContext<HttpRequestType>({} as HttpRequestType);

export const HttpRequestProvider = ({ children }: HttpRequestProviderProps) => {
  const [bearerToken, setBearerToken] = useState(loadAuthToken());
  const { Translate, selectedLanguage } = useAppTranslation();

  const handleResponseError = useCallback(
    (response: AxiosResponse) => {
      if (response.status === HttpResponse.UNAUTHORIZED) {
        toast.error(Translate('toast.session-disconnected-error'), { duration: 7000 });
        setBearerToken('');
      }

      return response.data;
    },
    [Translate]
  );

  const [httpConnection, setHttpConnection] = useState<AxiosInstance>(() => {
    return createConfiguredAxiosInstance({ bearerToken, selectedLanguage, handleResponseError, Translate });
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_AUTH_TOKEN, bearerToken);
  }, [bearerToken]);

  useEffect(() => {
    setHttpConnection(() => {
      return createConfiguredAxiosInstance({ bearerToken, selectedLanguage, handleResponseError, Translate });
    });
  }, [Translate, bearerToken, handleResponseError, selectedLanguage]);

  const contextValues = useMemo<HttpRequestType>(
    () => ({ bearerToken, setBearerToken, httpConnection }),
    [bearerToken, httpConnection, setBearerToken]
  );

  return <HttpRequestContext.Provider value={contextValues}>{children}</HttpRequestContext.Provider>;
};

export const useHttpRequest = (): HttpRequestType => {
  const context = useContext(HttpRequestContext);

  if (!context) {
    throw new Error('useHttpRequest must be used within a HttpRequestProvider');
  }

  return context;
};
