import React, { useCallback, useMemo, useState } from 'react';
import ClothingIconViewer from '../../../../../../components/ClothingIconViewer';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { type SublistReportType } from '../../../../../../types/ProjectReportType';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type Order } from '../../../../../../types/OrderTypes';
import { getSizeNameFromModelId } from '../../../ViewProject/services/modelService';
import { getColorByGender } from '../../../../../../helpers/orders';
import { ColoredReportRow } from './styles';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { displayAsCurrency, displayFriendlyWeight } from '../../../../../../utils/helper';
import { sublistHaveEmptyColumn } from '../../../services/sublistService';
import { LayoutContainer, LayoutImage } from '../LayoutGallery/styles';
import InputRange from '../../../../../../components/Forms/InputRange';
import { type UserPreferences } from '../../../../../../types/UserPreferences';
import SublistFinishingOptionsViewer from '../SublistFinishingOptionsViewer';

type ProjectReportSublistContentType = {
  sublist: SublistType;
  sublistReport: SublistReportType;
  importedModels: ClothingModelType[];
  showGenderColors: boolean;
  hidePricesInReport: boolean;
  showLayoutImagesBelowLists: boolean;
  preferences: UserPreferences;
};

export default function ProjectReportSublistContent({
  sublist,
  sublistReport,
  importedModels,
  showGenderColors,
  hidePricesInReport,
  showLayoutImagesBelowLists,
  preferences
}: ProjectReportSublistContentType) {
  const { Translate } = useAppTranslation();
  const [layoutScaleFactor, setLayoutScaleFactor] = useState(1);
  const layoutScalePercent = useMemo(() => Math.round(layoutScaleFactor * 100), [layoutScaleFactor]);

  const renderClothingModelsColumnsHeader = useCallback(() => {
    return importedModels.map((model, index) => (
      <th className='text-center' key={index}>
        <ClothingIconViewer useInlineIcon quantity={sublistReport.eachModelQuantity[index]} icon={model.icon.url} />
      </th>
    ));
  }, [importedModels, sublistReport]);

  const renderClothingModelsColumnsContent = useCallback(
    (order: Order) => {
      return order.clothes.map((clothe, index) => {
        const { modelId, sizeIndex, quantity } = clothe;
        const sizeName = getSizeNameFromModelId({
          gender: order.gender,
          models: importedModels,
          modelId,
          sizeIndex,
          Translate
        });

        return (
          <td className='text-center' key={index}>
            {quantity > 0 ? `${sizeName}-${quantity}` : ''}
          </td>
        );
      });
    },
    [Translate, importedModels]
  );

  const numberColumnVisibility = useMemo(() => (sublistHaveEmptyColumn(sublist, 'number') ? 'd-none' : ''), [sublist]);

  const nicknameColumnVisibility = useMemo(() => (sublistHaveEmptyColumn(sublist, 'nickname') ? 'd-none' : ''), [sublist]);

  const bloodTypeColumnVisibility = useMemo(() => (sublistHaveEmptyColumn(sublist, 'bloodType') ? 'd-none' : ''), [sublist]);

  const hidePrices = useMemo(() => hidePricesInReport ? 'd-none' : '', [hidePricesInReport]);

  return (
    <React.Fragment>

      <table className='table table-sm table-bordered'>
        <thead>
          <tr>
            <th>{Translate('labels.name')}</th>
            <th className={`text-center ${numberColumnVisibility}`}>Num</th>
            <th className={`text-center ${nicknameColumnVisibility}`}>{Translate('labels.nickname')}</th>
            <th className={`text-center ${bloodTypeColumnVisibility}`}>{Translate('labels.blood-type')}</th>
            <th className='text-center'>{Translate('labels.model')}</th>
            {renderClothingModelsColumnsHeader()}
            <th className={`text-center ${hidePrices}`}>Total</th>
          </tr>
        </thead>
        <tbody>
          {sublist.orders.map((order, orderIndex) => (
            <ColoredReportRow key={orderIndex} color={getColorByGender(order.gender)} showColor={showGenderColors}>
              <td>{order.name}</td>
              <td className={`text-center ${numberColumnVisibility}`}>{order.number}</td>
              <td className={`text-center ${nicknameColumnVisibility}`}>{order.nickname}</td>
              <td className={`text-center ${bloodTypeColumnVisibility}`}>{order.bloodType}</td>
              <td className='text-center'>{Translate('labels.' + order.gender)}</td>
              {renderClothingModelsColumnsContent(order)}
              <td className={`text-right ${hidePrices}`}>
                <span className='mr-2'>{displayAsCurrency(sublistReport.ordersTotalPrices[orderIndex], preferences.currency)}</span>
              </td>
            </ColoredReportRow>
          ))}

          <tr>
            <td colSpan={999}>
              <div className='row'>
                <div className='col'>
                  <strong className='mr-2'>
                    <span>{Translate('labels.estimated-weight')}: </span>
                    <span>{displayFriendlyWeight(sublistReport.totalSublistWeight)}</span>
                  </strong>
                </div>
                <div className='col text-right'>
                  <strong className={`mr-2 ${hidePrices}`}>
                    <span>{Translate('labels.total-price')}: </span>
                    <span>{displayAsCurrency(sublistReport.totalSublistPrice, preferences.currency)}</span>
                  </strong>
                </div>
                {
                  sublistReport.sublistDiscount > 0 && (
                    <div className='col-auto text-right border-left'>
                      <strong className={`mr-2 ${hidePrices}`}>
                        <span>{Translate('labels.total-price-with-discount')}: </span>
                        <span>{displayAsCurrency(sublistReport.totalSublistPriceWithDiscount ?? 0, preferences.currency)}</span>
                        <span className='badge badge-default'>(-{sublistReport.sublistDiscount}%)</span>
                      </strong>
                    </div>
                  )
                }
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      {
        sublist.layout?.file && showLayoutImagesBelowLists && (
          <div>

            <section className='d-print-none mb-2'>
              <InputRange
                min={50} max={100} initialValue={100}
                label={`${Translate('labels.scale')}: ${layoutScalePercent}%`}
                onChangeValue={(updatedValue => {
                  setLayoutScaleFactor(updatedValue / 100);
                })} />
            </section>

            <div className='d-flex justify-content-between align-items-start'>
              <section className='mr-2'>
                <LayoutContainer scaleFactor={layoutScaleFactor}>
                  <LayoutImage src={sublist.layout.file} className='img-fluid shadow-sm rounded my-2' alt={sublist.title} />
                </LayoutContainer>

                <h5 className='m-0 text-wrap text-center mt-3' style={{ fontFamily: 'inherit' }}>
                  {sublist.details}
                </h5>
              </section>

              <SublistFinishingOptionsViewer sublist={sublist} importedModels={importedModels} />
            </div>

          </div>
        )
      }
    </React.Fragment>
  );
}
