import React, { type MouseEvent, useCallback, useState } from 'react';
import { Container } from './styles';
import { useContextMenu } from '../../../../../../contexts/ContextMenuContext';
import { type SublistType } from '../../../../../../types/SublistTypes';
import SublistLayoutModal from '../../../../../../components/Modals/SublistLayoutModal';
import { toast } from 'react-hot-toast-promise';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import TextAreaInputModal from '../../../../../../components/Modals/TextAreaInputModal';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import SublistCsvLinkManager from '../../../../../../components/Modals/SublistCsvLinkManager';
import { copyToClipboard } from '../../../../../../utils/helper';
import { generateSublistCsvFullUrl, generateSublistJsonFullUrl } from '../../../services/sublistService';
import DropdownButton from '../../../../../../components/Buttons/DropdownButton';
import Spinner from '../../../../../../components/Spinner';
import SublistDiscountManager from './components/SublistDiscountManager';
import SublistJsonLinkManager from '../../../../../../components/Modals/SublistJsonLinkManager';
import FinishingOptionsManagerForSublist from './components/FinishingOptionsManagerForSublist';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type SublistFinishingOption } from '../../../../../../types/ClothingFinishingOptions/SublistFinishingOption';
import TextInputModal from '../../../../../../components/Modals/TextInputModal';

export type SublistChangedType = {
  updatedSublist: SublistType;
  isSynched: boolean;
  forceAutoSync: boolean;
};

type SublistListType = {
  items: SublistType[];
  importedModels: ClothingModelType[];
  onSublistChange: (sublistChanged: SublistChangedType) => void;
};

export default function SublistList({ items, importedModels, onSublistChange }: SublistListType) {
  const [selectedSublist, setSelectedSublist] = useState<SublistType | null>(null);
  const [sublistToApplyDiscount, setSublistToApplyDiscount] = useState<SublistType | null>(null);
  const [showLayoutModal, setShowLayoutModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [show3dViewUrlManager, setShow3dViewUrlManager] = useState(false);
  const [showFinishingOptionsModal, setShowFinishingOptionsModal] = useState(false);
  const [sublistToManageCsvLink, setSublistToManageCsvLink] = useState<SublistType | null>(null);
  const [sublistToManageJsonLink, setSublistToManageJsonLink] = useState<SublistType | null>(null);

  const { handleShow } = useContextMenu();
  const { Translate } = useAppTranslation();

  const handleOpenLayoutManager = useCallback((sublist: SublistType) => {
    if (!sublist.id) {
      toast.error(Translate('error.sublist-not-sync-with-server'), {
        duration: 4000
      });

      return;
    }

    setSelectedSublist(sublist);
    setShowLayoutModal(true);
  }, [Translate]);

  const handleOpenDetailsEditor = useCallback((sublist: SublistType) => {
    setSelectedSublist(sublist);
    setShowDetailsModal(true);
  }, []);

  const handleshowContextMenu = useCallback((event: MouseEvent<HTMLLIElement>, sublistItem: SublistType) => {
    event.preventDefault();
    handleShow({ x: event.clientX, y: event.clientY }, sublistItem);
  }, [handleShow]);

  const updateCachedSublistWithNewAddedFinishingOption = useCallback((data: SublistFinishingOption) => {
    if (!selectedSublist) return;

    const updatedSublist: SublistType = {
      ...selectedSublist,
      selected_finishing_options: [...selectedSublist.selected_finishing_options, { ...data }]
    };

    onSublistChange({ updatedSublist, isSynched: true, forceAutoSync: false });
    setSelectedSublist(updatedSublist);
  }, [onSublistChange, selectedSublist]);

  const updateSelectedSublistWithRemovedFinishingOption = useCallback((removedPickedOptionId: number) => {
    if (!selectedSublist) return;

    const updatedSublist: SublistType = {
      ...selectedSublist,
      selected_finishing_options: selectedSublist.selected_finishing_options.filter((selectedFinishingOption) => selectedFinishingOption.id !== removedPickedOptionId)
    };

    onSublistChange({ updatedSublist, isSynched: true, forceAutoSync: false });
    setSelectedSublist(updatedSublist);
  }, [onSublistChange, selectedSublist]);

  return (
    <React.Fragment>
      <SublistDiscountManager
        visible={!!sublistToApplyDiscount}
        style='primary'
        title={Translate('labels.discount')}
        message={Translate('description.discount-modal-description')}
        sublist={sublistToApplyDiscount!}
        handleClose={() => {
          setSublistToApplyDiscount(null);
        }}
        handleConfirm={(sublistWithDiscount) => {
          onSublistChange({
            updatedSublist: sublistWithDiscount,
            isSynched: false,
            forceAutoSync: false
          });
        }}
      />

      {!!selectedSublist && (
        <FinishingOptionsManagerForSublist
          visible={showFinishingOptionsModal}
          sublist_id={selectedSublist.id}
          sublistFinishingOptions={selectedSublist.selected_finishing_options}
          style='primary'
          title={Translate('labels.finishing-options')}
          message={Translate('description.choose-finishing-options-description')}
          importedModels={importedModels}
          handleConfirm={() => {
            setShowFinishingOptionsModal(false);
            setSelectedSublist(null);
          }}
          handleClose={() => {
            setShowFinishingOptionsModal(false);
            setSelectedSublist(null);
          }}
          onAddSublistFinishingOption={updateCachedSublistWithNewAddedFinishingOption}
          onRemoveSublistFinishingOption={updateSelectedSublistWithRemovedFinishingOption}
        />
      )}

      {!!sublistToManageCsvLink && (
        <SublistCsvLinkManager
          visible
          sublist={sublistToManageCsvLink}
          handleClose={() => {
            setSublistToManageCsvLink(null);
          }}
          handleConfirm={async updatedSublist => {
            const { project_id, token } = updatedSublist;

            if (token && project_id) {
              const url = generateSublistCsvFullUrl(project_id, token);
              await copyToClipboard(url);
              toast.success(Translate('toast.link-copied'));
            }

            onSublistChange({ updatedSublist, isSynched: false, forceAutoSync: !!token });
          }}
        />
      )}

      {!!sublistToManageJsonLink && (
        <SublistJsonLinkManager
          visible
          sublist={sublistToManageJsonLink}
          handleClose={() => {
            setSublistToManageJsonLink(null);
          }}
          handleConfirm={async updatedSublist => {
            const { project_id, token } = updatedSublist;

            if (token && project_id) {
              const url = generateSublistJsonFullUrl(project_id, token);
              await copyToClipboard(url);
              toast.success(Translate('toast.link-copied'));
            }

            onSublistChange({ updatedSublist, isSynched: false, forceAutoSync: !!token });
          }}
        />
      )}

      {!!selectedSublist && showLayoutModal && (
        <SublistLayoutModal
          sublist={selectedSublist}
          onLayoutCreated={createdLayout => {
            onSublistChange({
              isSynched: true,
              updatedSublist: {
                ...selectedSublist,
                layout: createdLayout
              },
              forceAutoSync: false
            });
            toast.success(Translate('toast.layout-add-success'));
          }}
          onLayoutRemoved={() => {
            const updatedSublist: SublistType = { ...selectedSublist, layout: null };
            setSelectedSublist(updatedSublist); // update data inside modal
            onSublistChange({ isSynched: true, updatedSublist, forceAutoSync: false }); // update sublist in project editor
          }}
          handleClose={() => {
            setSelectedSublist(null);
            setShowLayoutModal(false);
          }}
        />
      )}

      {!!selectedSublist && showDetailsModal && (
        <TextAreaInputModal
          visible
          title={Translate('labels.sublist')}
          message={Translate('description.sublist-annotations-description')}
          label={Translate('labels.enter-details')}
          style='primary'
          currentValue={selectedSublist?.details ?? ''}
          handleClose={() => {
            setShowDetailsModal(false);
            setSelectedSublist(null);
          }}
          handleConfirm={details => {
            const updatedSublist: SublistType = { ...selectedSublist, details: details === '' ? null : details };
            onSublistChange({ isSynched: false, updatedSublist, forceAutoSync: false });

            setShowDetailsModal(false);
            setSelectedSublist(null);
          }}
        />
      )}

      {!!selectedSublist && show3dViewUrlManager && (
        <TextInputModal
          visible
          currentValue={selectedSublist.view_3d_url ?? ''}
          label={Translate('description.3d-view-description')}
          style='primary'
          title={`${Translate('labels.3d-view')} - ${selectedSublist.title}`}
          handleClose={() => {
            setShow3dViewUrlManager(false);
          }}
          handleConfirm={(url) => {
            const updatedSublist: SublistType = { ...selectedSublist, view_3d_url: url };
            onSublistChange({ isSynched: false, updatedSublist, forceAutoSync: false });
            setShow3dViewUrlManager(false);
            setSelectedSublist(null);
          }}
        />
      )}

      <Container>
        <ul className='list-group list-group-flush'>
          {items.map((sublist, index) => (
            <li
              key={index}
              className='list-group-item'
              onContextMenu={event => {
                handleshowContextMenu(event, sublist);
              }}
            >
              <div className='row'>
                <div className='col'>
                  <div className='d-flex align-items-center'>
                    <span>{sublist.title}</span>
                  </div>
                </div>

                <div className='col-auto'>
                  {!sublist.id && <Spinner />}

                  {
                    sublist.id && (
                      <DropdownButton id='sublist-dropdown-menu' color='light' size='sm' noMarginBottom options={[
                        {
                          name: Translate('labels.link-csv'),
                          icon: IconsCatalog.link,
                          handleClick: () => {
                            setSublistToManageCsvLink(sublist);
                          }
                        },
                        {
                          name: Translate('labels.link-json'),
                          icon: IconsCatalog.link,
                          handleClick: () => {
                            setSublistToManageJsonLink(sublist);
                          }
                        },
                        {
                          name: Translate('labels.manage-layout'),
                          icon: IconsCatalog.image,
                          handleClick: () => {
                            handleOpenLayoutManager(sublist);
                          }
                        },
                        {
                          name: Translate('labels.3d-view'),
                          icon: IconsCatalog.unity,
                          handleClick: () => {
                            setShow3dViewUrlManager(true);
                            setSelectedSublist(sublist);
                          }
                        },
                        {
                          name: Translate('labels.annotations'),
                          icon: IconsCatalog.comment,
                          handleClick: () => {
                            handleOpenDetailsEditor(sublist);
                          }
                        },
                        {
                          name: Translate('labels.discount'),
                          icon: IconsCatalog.commentsDollar,
                          handleClick: () => {
                            setSublistToApplyDiscount(sublist);
                          }
                        },
                        {
                          name: Translate('labels.finishing-options'),
                          icon: IconsCatalog.cut,
                          handleClick: () => {
                            setShowFinishingOptionsModal(true);
                            setSelectedSublist(sublist);
                          }
                        }
                      ]} />
                    )
                  }

                </div>
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </React.Fragment>
  );
}
