import { useMemo } from 'react';
import { type BasicButtonType } from '../types/BasicButtonType';

export default function BasicButton({
  testId,
  title,
  size,
  color,
  marginLeft = false,
  disabled = false,
  tabIndex,
  buttonRef,
  handleClick
}: BasicButtonType) {
  const buttonSize = useMemo(() => (size !== undefined ? `btn-${size}` : ''), [size]);
  const useMarginLeft = useMemo(() => (marginLeft ? 'ml-2' : ''), [marginLeft]);

  return (
    <button
      data-testid={testId}
      ref={buttonRef}
      onClick={handleClick}
      type='button'
      className={`btn btn-${color} ${buttonSize} ${useMarginLeft}`}
      tabIndex={tabIndex}
      disabled={disabled}
    >
      {title}
    </button>
  );
}
