import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';

type SublistFormType = {
  sublistToEdit: SublistType | null;
  disabled: boolean;
  handleCreateSublist: (name: string) => void;
  handleEditSublist: (name: string, id: string) => void;
  onEditCanceled: () => void;
};

export default function SublistForm({
  sublistToEdit,
  disabled,
  handleCreateSublist,
  handleEditSublist,
  onEditCanceled
}: SublistFormType) {
  const { Translate } = useAppTranslation();

  const [name, setName] = useState('');
  const nameInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!sublistToEdit) handleCreateSublist(name);
      else handleEditSublist(name, sublistToEdit.uuid);

      setName('');
    },
    [handleCreateSublist, handleEditSublist, name, sublistToEdit]
  );

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      onEditCanceled();
      setName('');
    }
  }, [onEditCanceled]);

  /** Sets the current sublist title to be edited */
  useEffect(() => {
    if (sublistToEdit) {
      setName(sublistToEdit.title);
      nameInputRef.current?.focus();
    }
  }, [sublistToEdit]);

  const buttonClassName = useMemo(() => (!sublistToEdit ? 'btn-success' : 'btn-primary'), [sublistToEdit]);
  const buttonIcon = useMemo(() => (!sublistToEdit ? IconsCatalog.plus : IconsCatalog.pen), [sublistToEdit]);

  return (
    <form onSubmit={handleSubmit} className='mb-3'>
      <div className='form-group'>
        <label htmlFor='sublist-name'>{Translate('labels.name')}</label>
        <div className='input-group'>
          <input
            id='sublist-name'
            ref={nameInputRef}
            type='text'
            className='form-control'
            placeholder={Translate('labels.new-sublist')}
            value={name}
            disabled={disabled}
            onChange={({ target }) => {
              setName(target.value);
            }}
            onKeyDown={handleKeyDown}
          />

          <div className='input-group-append'>
            <button className={`btn ${buttonClassName}`} type='submit' disabled={disabled}>
              <i className={buttonIcon} />
            </button>
          </div>
        </div>
      </div>
      {sublistToEdit && (
        <small className='form-text text-muted'>{Translate('description.press-esc-to-cancel-editing')}</small>
      )}
    </form>
  );
}
