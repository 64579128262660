import React from 'react';
import { CheckIcon, CheckboxContainer, CheckboxLabel, HiddenCheckbox, StyledCheckbox } from './styles';

type CheckboxType = {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  tabIndex?: number;
  handleChange: (checked: boolean) => void;
};

export default function Checkbox({ id, name, label, checked, tabIndex, handleChange }: CheckboxType) {
  const handleCheckboxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(target.checked);
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={handleCheckboxChange} id={id} name={name} tabIndex={tabIndex} />
      <StyledCheckbox checked={checked}>
        {checked && <CheckIcon className="fas fa-check fa-fw fa-sm" />}
      </StyledCheckbox>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
}
